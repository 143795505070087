import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import axios from 'axios';
import * as API from '../../constants/api';
import * as ROUTES from '../../constants/routes';
import { processError, DialogError, DialogSuccess, withRouter } from '../Util';
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../../styles/main.css';


const INITIAL_STATE = {
  email: '',
  id: '',
  confirmed: false,
  error: false,
  message: '',
  loading: false,
};

class EndTrialNowDialog extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.handleUpdateSub = this.handleUpdateSub.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  static propTypes = {
    open: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  };

  componentDidUpdate(prevProps) {
    if ( this.props.open !== prevProps.open && this.props.open === true ) {
      if ( this.props.id !== "" && this.props.email !== "" ) {
        this.setState({ email: this.props.email, id: this.props.id, confirmed:false, error:false, message:'', loading:false });
      } else {
        this.props.onClose(false);
      }
    }
  }

  handleUpdateSub() {
    this.props.router.navigate(ROUTES.SUB_UPDATE, { state: {
      email: this.state.email,
      id: this.state.id,
      interval: API.FREE_TRIAL_INTERVAL,
      quantity: API.FREE_TRIAL_CONNECTOR_QUANTITY,
      endTrialNow: API.FREE_TRIAL_END_NOW,
    }});
  };

  handleClose() {
    this.props.onClose(true);
  };

  handleCancel() {
    this.props.onClose(false);
  };

  async handleConfirm() {
    const { email, id } = this.state;
    if ( email !== "" && id !== "" ) {
      this.setState({ loading:true });
      await axios
      .post(`${API.FMI_API}/sub/update/`, {
        email,
        sub_id: id,
        end_trial_now: API.FREE_TRIAL_END_NOW,
      }, API.HEADERS)
      .then( response => {
        this.setState({ confirmed:true, error:false, message:'', loading:false });
      })
      .catch( error => {
        const { message } = processError(error);
        this.setState({ confirmed:false, error:true, message, loading:false });
      });
    }
  };

  renderQuestion() {
    const { loading } = this.state;
    return (
      <Fragment>
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>
              Go Premium
            </Grid>
            <Grid item>
              <IconButton onClick={this.handleCancel} size="small">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          Would you like to become a premium member now?
          <br/>
          Your first billing period will start immediately.
        </DialogContent>
        <DialogActions>
          { loading === true ?
            <CircularProgress />
            :
            <Grid container justifyContent="space-between">
              <Grid item>
                <span className="dialog-btn">
                  <Button autoFocus onClick={this.handleCancel} color="primary">
                    No
                  </Button>
                </span>
              </Grid>
              <Grid item>
                <span className="dialog-btn">
                  <Button onClick={this.handleUpdateSub} color="primary" variant="contained">
                    <span className={"txt-white txt-bold"}>Yes and Update My Subscription</span>
                  </Button>
                </span>
                <span className="dialog-btn">
                  <Button onClick={this.handleConfirm} color="primary" variant="contained">
                    <span className={"txt-white txt-bold"}>Yes</span>
                  </Button>
                </span>
              </Grid>
            </Grid>
          }
        </DialogActions>
      </Fragment>
    )
  }

  render() {
    const { confirmed, error, message } = this.state;
    return (
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        aria-labelledby="confirm-cancel-sub"
        open={this.props.open}
        className="sb-dialog"
      >
        { error === false && confirmed === false && this.renderQuestion() }
        { error === false && confirmed === true &&
          <DialogSuccess
            onClick={this.handleClose}
            title="Congratulations! Your first billing period starts now."
            content={`Thank you. Please wait a moment and refresh the "My Subscription" or "My Connectors" page to see your subscription details.`}
          />
        }
        { error === true &&
          <DialogError
            message={message}
            onClick={this.handleClose}
          /> }
      </Dialog>
    );
  }
}

export default withRouter(EndTrialNowDialog);
