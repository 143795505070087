import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updatePassword,
  updateProfile,
} from "firebase/auth";
import { initializeApp } from "firebase/app";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    const app = initializeApp(config);
    this.auth = getAuth(app);
  }
  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) => createUserWithEmailAndPassword(this.auth, email, password);
  doSignInWithEmailAndPassword = (email, password) => signInWithEmailAndPassword(this.auth, email, password);
  doSignOut = () => signOut(this.auth);
  doPasswordReset = email => sendPasswordResetEmail(this.auth, email);
  doPasswordUpdate = password => updatePassword(this.auth.currentUser, password);
  doProfileUpdate = profile => updateProfile(this.auth.currentUser, profile);
}

export default Firebase;
