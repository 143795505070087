import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import axios from 'axios';
import * as API from '../../constants/api';
import { calculateTotalAmount, processError, DialogError } from '../Util';
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../../styles/main.css';


const INITIAL_STATE = {
  email: '',
  id: '',
  interval: '',
  quantity: 0,
  endTrialNow: '',
  proration: 0,
  confirmedProration: false,
  confirmed: false,
  error: false,
  message: '',
  loading: false,
};

class UpdateSubDialog extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  static propTypes = {
    open: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    interval: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    endTrialNow: PropTypes.string.isRequired,
  };

  componentDidUpdate(prevProps) {
    if ( this.props.open !== prevProps.open && this.props.open === true ) {
      if ( this.props.id !== "" && this.props.email !== "" && this.props.interval !== "" && this.props.quantity !== 0 ) {
        this.setState({
          email: this.props.email,
          id: this.props.id,
          interval: this.props.interval,
          quantity: this.props.quantity,
          endTrialNow: this.props.endTrialNow,
          proration: 0,
          confirmedProration: false,
          confirmed:false,
          error:false,
          message: '',
          loading:false
        });
      } else {
        this.props.onClose(false);
      }
    }
  }

  handleClose = () => {
    this.props.onClose(true);
  };

  handleCancel = () => {
    this.props.onClose(false);
  };

  handleConfirm = async () => {
    const { email, id, interval, quantity, endTrialNow } = this.state;
    if ( email !== "" && id !== "" ) {
      this.setState({ loading:true });
      await axios
      .post(`${API.FMI_API}/sub/update/`, {
        email,
        sub_id: id,
        interval,
        quantity,
        end_trial_now: endTrialNow,
      }, API.HEADERS)
      .then( response => {
        this.setState({ confirmed:true, error:false, message:'', loading:false });
      })
      .catch( error => {
        const { message } = processError(error);
        this.setState({ confirmed:false, error:true, message, loading:false });
      });
    }
  };

  handlePreviewProration = async () => {
    const { email, id, interval, quantity, endTrialNow } = this.state;
    if ( email !== "" && id !== "" ) {
      if ( endTrialNow === API.FREE_TRIAL_END_NOW ) {
        const proration = calculateTotalAmount(interval, quantity);
        this.setState({ confirmedProration:true, confirmed:false, error:false, message:'', loading:false, proration });
      } else {
        this.setState({ loading:true });
        await axios
        .post(`${API.FMI_API}/sub/preview/`, {
          email,
          sub_id: id,
          interval,
          quantity
        }, API.HEADERS)
        .then( response => {
          const proration = response.data.proration / 100;
          this.setState({ confirmedProration:true, confirmed:false, error:false, message:'', loading:false, proration });
        })
        .catch( error => {
          const { message } = processError(error);
          this.setState({ confirmedProration:false, confirmed:false, error:true, message, loading:false });
        });
      }
    }
  };

  renderSuccess() {
    const { interval, quantity, endTrialNow } = this.state;
    const intervalFormatted = interval === API.SUB_INTERVAL_YEAR ? API.SUB_INTERVAL_YEAR_TEXT_LOWERCASE : API.SUB_INTERVAL_MONTH_TEXT_LOWERCASE;
    return (
      <Fragment>
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>
              { endTrialNow===API.FREE_TRIAL_END_NOW ?
                "Thank you!"
                : "Your subscription has been updated."
              }
            </Grid>
            <Grid item>
              <IconButton onClick={this.handleClose} size="small">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          { endTrialNow===API.FREE_TRIAL_END_NOW ?
            `Your premium subscription has started. Refresh "My Subscription" or "My Connectors" page to show details.`
            : `Your subscription has been updated to ${intervalFormatted} with ${quantity} Connector${quantity>1?"s":""}.`
          }
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={this.handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Fragment>
    )
  }

  renderQuestion() {
    const { loading, proration, endTrialNow } = this.state;
    return (
      <Fragment>
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>
              { endTrialNow===API.FREE_TRIAL_END_NOW ?
                "Go Premium and Update Your Subscription"
                : "Update Your Subscription"
              }
            </Grid>
            <Grid item>
              <IconButton onClick={this.handleCancel} size="small">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          { endTrialNow===API.FREE_TRIAL_END_NOW ?
            `Your ${API.FREE_TRIAL_DAYS} day introductory trial is about to complete. You will be charged ${proration} USD for your first billing period. Would you like to proceed?` :
            `You will be charged or credited a pro rata difference of ${proration} USD for the remainder of current billing period now. Would you like to update?`
          }
          <br/>
          <br/>
          { endTrialNow!==API.FREE_TRIAL_END_NOW &&
            <Typography variant="body2" component="span" className="txt-note">
              Note: Proration is calculated based on the amount charged at the beginning of your current billing period.
            </Typography>
          }
        </DialogContent>
        <DialogActions>
          { loading === true ?
            <CircularProgress />
            :
            <Grid container justifyContent="space-between">
              <Grid item>
                <span className="dialog-btn">
                  <Button onClick={this.handleCancel} color="primary">
                    No
                  </Button>
                </span>
              </Grid>
              <Grid item>
                <span className="dialog-btn">
                  <Button autoFocus onClick={this.handleConfirm} color="primary" variant="contained">
                    <span className={"txt-white txt-bold"}>Yes</span>
                  </Button>
                </span>
              </Grid>
            </Grid>
          }
        </DialogActions>
      </Fragment>
    )
  }

  renderPreviewProration() {
    const { loading, interval, quantity, endTrialNow } = this.state;
    const intervalFormatted = interval === API.SUB_INTERVAL_YEAR ? API.SUB_INTERVAL_YEAR_TEXT_LOWERCASE : API.SUB_INTERVAL_MONTH_TEXT_LOWERCASE;
    return (
      <Fragment>
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>
              { endTrialNow===API.FREE_TRIAL_END_NOW ?
                "Go Premium and Update Your Subscription"
                : "Update Your Subscription"
              }
            </Grid>
            <Grid item>
              <IconButton onClick={this.handleCancel} size="small">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
           You have selected {intervalFormatted} subscription with {quantity} Connector{quantity>1&&"s"}. Would you like to proceed?
        </DialogContent>
        <DialogActions>
          { loading === true ?
            <CircularProgress />
            :
            <Grid container justifyContent="space-between">
              <Grid item>
                <span className="dialog-btn">
                  <Button autoFocus onClick={this.handleCancel} color="primary">
                    No
                  </Button>
                </span>
              </Grid>
              <Grid item>
                <span className="dialog-btn">
                  <Button onClick={this.handlePreviewProration} color="primary" variant="contained">
                    <span className={"txt-white txt-bold"}>Yes</span>
                  </Button>
                </span>
              </Grid>
            </Grid>
          }
        </DialogActions>
      </Fragment>
    )
  }

  render() {
    const { confirmed, confirmedProration, error, message } = this.state;
    return (
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        aria-labelledby="confirm-cancel-sub"
        open={this.props.open}
        className="sb-dialog"
      >
        { error === false && confirmedProration === false && this.renderPreviewProration() }
        { error === false && confirmedProration === true && confirmed === false && this.renderQuestion() }
        { error === false && confirmedProration === true && confirmed === true && this.renderSuccess() }
        { error === true &&
          <DialogError
            message={message}
            onClick={this.handleClose}
          /> }
      </Dialog>
    );
  }
}

export default UpdateSubDialog;
