import React, { Fragment } from 'react';
import PricingCalculator from './PricingCalculator';
import { Grid, Typography, Paper, Table, TableContainer, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import '../../styles/main.css';

const PricingPage = () => (
  <Grid container justifyContent="center">
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <h1 className="grid-row one lg-ft">Pricing</h1>
    </Grid>
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <div className="grid-row two">
        <PricingTable/>
      </div>
    </Grid>
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <div className="grid-row four">
        <PricingCalculator/>
      </div>
    </Grid>
  </Grid>
)

const PricingTable = () => (
  <div className="pc-tb">
    <Typography variant="h6" component="h6">Subscription</Typography>
    <TableContainer component={Paper} elevation={0}>
      <Table aria-label="pricing">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell align="left">Graduated Tier</TableCell>
            <TableCell align="right">Price Per Unit* (USD)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Fragment>
            <TableRow>
              <TableCell rowSpan={4}>Monthly</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">First Connector</TableCell>
              <TableCell align="right">$9.00</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">2nd to 5th Connector</TableCell>
              <TableCell align="right">$7.00</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">6th and more</TableCell>
              <TableCell align="right">$5.00</TableCell>
            </TableRow>
          </Fragment>
          <Fragment>
            <TableRow>
              <TableCell rowSpan={4}>Annual</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">First Connector</TableCell>
              <TableCell align="right">$88.00</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">2nd to 5th Connector</TableCell>
              <TableCell align="right">$68.00</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">6th and more</TableCell>
              <TableCell align="right">$50.00</TableCell>
            </TableRow>
          </Fragment>
        </TableBody>
      </Table>
    </TableContainer>
    <div className="pc-note">
      <Typography variant="caption" component="span" align="left">
        *Note: for instance, each unit gives you access to all data of one ActiveCampaign account if you choose an ActiveCampaign Connector. If you have two ActiveCampaign accounts, you will need two units. If you have one Facebook Ads account and one ActiveCampaign account, you will need two units.
      </Typography>
    </div>
  </div>
)

export default PricingPage;

export { PricingTable };
