import React, { Component } from "react";
import { CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import axios from 'axios';
import * as API from '../../constants/api';
import * as STRIPE from '../../constants/stripe';
import { processError, FormSuccess, ConsentCheckbox, handleStripeElementChange } from '../Util';
import { Loading } from '../Loading';
import { Paper, Button, Typography } from '@mui/material';
import '../../styles/main.css';


const INITIAL_STATE = {
  email: '',
  interval: '',
  quantity: 0,
  totalAmount: 0,
  title: '',
  message: '',
  clientSecret: null,
  showForm: true,
  success: false,
  loading: false,
  numValid: false,
  expValid: false,
  cvcValid: false,
  checked: false,
  enableBtn: true,
};

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidUpdate(prevProps) {
    if ( prevProps.authUserEmail !== this.props.authUserEmail ) {
      if ( prevProps.interval !== this.props.interval &&
        prevProps.quantity !== this.props.quantity &&
        prevProps.totalAmount !== this.props.totalAmount &&
        this.props.authUserEmail && this.props.authUserEmail !== '' &&
        this.props.interval && this.props.interval !== '' &&
        this.props.quantity && this.props.quantity > 0 &&
        this.props.totalAmount && this.props.totalAmount > 0 ) {
        const intervalFormatted = this.props.interval===API.SUB_INTERVAL_MONTH ? API.SUB_INTERVAL_MONTH_TEXT_LOWERCASE : API.SUB_INTERVAL_YEAR_TEXT_LOWERCASE;
        this.setState({
          email: this.props.authUserEmail,
          interval: this.props.interval,
          quantity: this.props.quantity,
          totalAmount: this.props.totalAmount,
          title: `You've chosen the ${intervalFormatted} subscription with ${this.props.quantity} Connector${this.props.quantity>1?"s":""}. Please enter card details below to proceed.`,
        });
      }
    }
  }

  handleChangeCheckbox = (event) => {
    if (event.target.checked) {
      this.setState({ checked:true });
    } else {
      this.setState({ checked:false });
    }
  }

  handleChange = (event) => {
    handleStripeElementChange(this.setState.bind(this), event.complete, event.elementType, event.error, event.empty);
  }

  handleSubmit = async (event) => {
    this.setState({ loading:true, enableBtn: false, message:"" });
    event.preventDefault();
    const { stripe, elements } = this.props;
    if ( !stripe || !elements ) {
      this.setState({ showForm:true, success:false, loading:false, enableBtn:true, message:"Failed to load Stripe. Please try to refresh the page." });
      return
    }
    const cardElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email: this.state.email
      },
    });
    if (paymentMethod) {
      this.createCustomer(paymentMethod.id);
    } else if (error && error.message) {
      this.setState({
        showForm: true,
        success: false,
        loading: false,
        enableBtn: true,
        message: error.message,
      });
    } else {
      this.setState({
        showForm: true,
        success: false,
        loading: false,
        enableBtn: true,
        message: "Unexpected error. Please refresh the page and try again.",
      });
    }
  }

  createCustomer = async (id) => {
    const { email, interval, quantity } = this.state;
    await axios
    .post(`${API.FMI_API}/customer/createcustomerandsub/`, {
      email,
      payment_method_id: id,
      interval,
      quantity,
    }, API.HEADERS)
    .then( res => {
      if (res.data && res.data.message && res.data.message === API.MESSAGE_SUCCESS) {
        if (res.data.status === API.SUB_STATUS_ACTIVE) {
          this.setState({ showForm:false, success:true, loading:false, enableBtn: false, message:"", title: `Your payment was succeessful. Please go to "My Connectors" page and select your first Connector. Or go to "My Subscription" page to review details.` });
        } else if (res.data.payment_intent_status === API.PAYMENTINTENT_STATUS_REQUIRES_ACTION) {
          this.setState({ showForm:true, success:false, loading:true, enableBtn: false, message:"Your payment was incomplete. Action required." });
          this.props.stripe.confirmCardPayment(res.data.client_secret)
          .then( result => {
            if ( result.paymentIntent ) {
              this.setState({
                showForm:false,
                success:true,
                loading:false,
                enableBtn: false,
                message:"",
                title: `Your payment was succeessful. Please go to "My Connectors" page and select your first Connector. Or go to "My Subscription" page to review details.`,
              });
            } else if (result.error && result.error.message) {
              this.setState({
                showForm:true,
                success:false,
                loading:false,
                enableBtn: true,
                message:result.error.message,
              });
            } else {
              this.setState({
                showForm:true,
                success:false,
                loading:false,
                enableBtn: true,
                message:`Unexpected error. Please return to "My Subscription" page and try again.`,
              });
            }
          }).catch( error => {
            this.setState({
              showForm:true,
              success:false,
              loading:false,
              enableBtn: true,
              message:"Unexpected error confirming card. Please try again.",
            });
          });
        } else if (res.data.payment_intent_status === API.PAYMENTINTENT_STATUS_REQUIRES_PAYMENT_METHOD) {
          this.setState({
            showForm:true,
            success:false,
            loading:false,
            enableBtn: true,
            message:"This card was declined. Please try another card.",
          });
        } else {
          this.setState({
            showForm:true,
            success:false,
            loading:false,
            enableBtn: true,
            message:"Unexpected error. Please go to 'My Subscription' page and try again.",
          });
        }
      } else {
        this.setState({
          showForm:true,
          success:false,
          loading:false,
          enableBtn: true,
          message:"Your payment failed. Please try again.",
        });
      }
    }).catch( error => {
      const { message } = processError(error);
      this.setState({ showForm:true, success:false, loading:false, enableBtn: true, message });
    });
  }

  renderForm() {
    const { totalAmount, title, numValid, expValid, cvcValid, checked, enableBtn } = this.state;
    const valid = numValid === true && expValid === true && cvcValid === true && checked === true && enableBtn === true;
    const style = valid === false ? {backgroundColor: '#e0e0e0'} : {backgroundColor: '#e4262c'};
    return (
      <div className="ck-fm-div">
        <Typography variant="subtitle1">
          {title}
        </Typography>
        <Paper variant="outlined" elevation={0} className="ck-fm-paper">
          <div className="ck-fm-label">
            <Typography variant="body1" component="span">Total Amount: {totalAmount} USD</Typography>
          </div>
          <div className="ck-fm-label">
            <Typography variant="body1" component="span">Card Number</Typography>
          </div>
          <div className="ck-fm-card">
            <CardNumberElement className="ck-fm-card-element" onChange={this.handleChange} options={STRIPE.ELEMENT_OPTIONS}/>
          </div>
          <div className="ck-fm-label">
            <Typography variant="body1" component="span">Expiration Date</Typography>
          </div>
          <div className="ck-fm-card">
            <CardExpiryElement className="ck-fm-card-element" onChange={this.handleChange} options={STRIPE.ELEMENT_OPTIONS}/>
          </div>
          <div className="ck-fm-label">
            <Typography variant="body1" component="span">CVC</Typography>
          </div>
          <div className="ck-fm-card">
            <CardCvcElement className="ck-fm-card-element" onChange={this.handleChange} options={STRIPE.ELEMENT_OPTIONS}/>
          </div>
        </Paper>
        <Typography variant="caption" component="div">Payments handled by Stripe. We will not save your card details. Ever</Typography>
        <br/>
        <ConsentCheckbox checked={checked} onChange={this.handleChangeCheckbox}/>
        <div className="od-btn-div">
          <Button onClick={this.handleSubmit} variant="contained" style={style} size="large" fullWidth={true} disabled={valid === false}>
            <span className="pch-btn-txt">Confirm</span>
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { showForm, success, message, loading, title } = this.state;
    return (
      <div className="checkout-form">
        <Loading loading={loading}/>
        {showForm === true && this.renderForm()}
        {success === true && <FormSuccess title={title}/>}
        <Typography variant="subtitle1" component="div" align="center" color="error">{message}</Typography>
      </div>
    );
  }
}

export default CheckoutForm;
