import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { Typography, Button} from '@mui/material';
import '../../styles/main.css';


const FormSuccess = (props) => (
  <div className="ck-fm-div">
    <Typography variant="subtitle1">
      {props.title}
    </Typography>
    <div className="cfg-btn-div">
      <Button variant="contained" color="secondary" size="medium" fullWidth={true}
        component={ Link } to={ROUTES.MY_PRODUCTS}>
        <span className="pch-btn-txt">Take me to My Connectors</span>
      </Button>
    </div>
    <div className="cfg-btn-div">
      <Typography variant="subtitle1" style={{textAlign: 'center'}}>
        - or -
      </Typography>
    </div>
    <div className="cfg-btn-div">
      <Button variant="contained" color="secondary" size="medium" fullWidth={true}
        component={ Link } to={ROUTES.MY_SUB}>
        <span className="pch-btn-txt">Take me to My Subscription</span>
      </Button>
    </div>
  </div>
)

export default FormSuccess;
