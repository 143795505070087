export const FMI_API = process.env.REACT_APP_API;
export const SOURCE = "memberportal";

export const MESSAGE_NOT_FOUND = "Not found";
export const MESSAGE_SUCCESS = "Success";

export const SUB_INTERVAL_YEAR = "year";
export const SUB_INTERVAL_MONTH = "month";

export const SUB_INTERVAL_YEAR_TEXT = "Annual";
export const SUB_INTERVAL_YEAR_TEXT_LOWERCASE = SUB_INTERVAL_YEAR_TEXT.toLowerCase();
export const SUB_INTERVAL_MONTH_TEXT = "Monthly";
export const SUB_INTERVAL_MONTH_TEXT_LOWERCASE = SUB_INTERVAL_MONTH_TEXT.toLowerCase();

export const CONNECTOR_QUANTITY_MAX = 200;

export const FREE_TRIAL_DAYS = 14;
export const FREE_TRIAL_INTERVAL = SUB_INTERVAL_MONTH;
export const FREE_TRIAL_INTERVAL_TEXT = FREE_TRIAL_INTERVAL === SUB_INTERVAL_YEAR ? SUB_INTERVAL_YEAR_TEXT : SUB_INTERVAL_MONTH_TEXT;
export const FREE_TRIAL_INTERVAL_TEXT_LOWERCASE = FREE_TRIAL_INTERVAL_TEXT.toLowerCase();
export const FREE_TRIAL_CONNECTOR_QUANTITY = 1;
export const FREE_TRIAL = process.env.REACT_APP_FREE_TRIAL;
export const FREE_TRIAL_ON = "on";
export const FREE_TRIAL_END_NOW = "true";

export const PRICE_NICKNAME_NCT = process.env.REACT_APP_PRICE_NICKNAME_NCT;

export const SUB_STATUS_TRIALING = "trialing";
export const SUB_STATUS_ACTIVE = "active";
export const SUB_STATUS_CANCELED = "canceled";
export const SUB_STATUS_PAST_DUE = "past_due";
export const SUB_STATUS_UNPAID = "unpaid";

export const SETUPINTENT_STATUS_REQUIRES_ACTION = "requires_action";
export const SETUPINTENT_STATUS_SUCCEEDED = "succeeded";

export const PAYMENTINTENT_STATUS_REQUIRES_ACTION = "requires_action";
export const PAYMENTINTENT_STATUS_REQUIRES_PAYMENT_METHOD = "requires_payment_method";

export const INVOICE_STATUS_OPEN = "open";
export const INVOICE_STATUS_PAID = "paid";

export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;

export const ACTIVECAMPAIGN = 'active_campaign';
export const ACTIVECAMPAIGN_FORMATTED = 'ActiveCampaign';

export const FACEBOOK_ADS = 'facebook_ads';
export const FACEBOOK_ADS_FORMATTED = 'Facebook Ads Insight';
export const FACEBOOK_API_SCOPE = 'ads_read';
export const FACEBOOK_OAUTH_URL = 'https://www.facebook.com/dialog/oauth';
export const FACEBOOK_CLIENT_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const FACEBOOK_TOKEN_DOC_URL = 'https://developers.facebook.com/docs/facebook-login/access-tokens/refreshing/';

export const STRIPE = 'stripe';
export const STRIPE_FORMATTED = 'Stripe';
export const STRIPE_WHERE_TO_FIND_API_KEYS = "https://dashboard.stripe.com/apikeys";
export const STRIPE_API_KEYS_DOC_URL = "https://stripe.com/docs/keys";

export const FACEBOOK_PAGES = 'facebook_pages';
export const FACEBOOK_PAGES_FORMATTED = 'Facebook Pages Insight';
export const FACEBOOK_PAGE_API_SCOPES = [
  'pages_read_engagement',
  'read_insights',
  'pages_show_list',
];
// If member wants Instagram insight, they must grant access to the associated page as well
export const INSTAGRAM_API_SCOPES = [
  'instagram_basic',
  'instagram_manage_insights',
];
export const FACEBOOK_PAGES_OAUTH_URL = 'https://www.facebook.com/dialog/oauth';
export const FACEBOOK_PAGES_CLIENT_ID = process.env.REACT_APP_FACEBOOK_PAGES_APP_ID;

export const CANCEL_REASONS = [
  "I couldn't build the metrics I wanted",
  "I have cancelled my ActiveCampaign subscription",
  "I have cancelled my Facebook Ads subscription",
  "I no longer use Google Data Studio",
  "I cannot connect my account",
  "It's too difficult to use",
];

export const HEADERS = {
  headers: {"Content-Type": "application/json"}
};
