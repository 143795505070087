import * as API from '../../constants/api';
import ActionButton from './ActionButton';
import DialogError from './DialogError';
import DialogSuccess from './DialogSuccess';
import FormSuccess from './FormSuccess';
import ConsentCheckbox from './ConsentCheckbox';
import ConfigSuccess from './ConfigSuccess';
import handleStripeElementChange from './handleStripeElementChange';
import withRouter from './withRouter';

export { ActionButton, DialogError, DialogSuccess, FormSuccess, ConsentCheckbox, ConfigSuccess };

export { handleStripeElementChange, withRouter };

export const formatConnector = (connector) => {
  let connectorFormatted = '';
  if ( connector === API.ACTIVECAMPAIGN ) {
    connectorFormatted = API.ACTIVECAMPAIGN_FORMATTED;
  } else {
    const splited = connector.split('_');
    const splitedCapped = [];
    splited.forEach( (it, idx) => {
      splitedCapped[idx] = it.charAt(0).toUpperCase() + it.slice(1).toLowerCase();
    });
    connectorFormatted = splitedCapped.join(" ")
  }
  return connectorFormatted
}

// Input: "2021-03-03T19:27:32Z"
export const formatTime = (s) => {
  if (!s) {
    return ""
  } else {
    const d = new Date(s);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return d.toLocaleDateString(options);
  }
}

export const calculateTotalAmount = (interval, quantity) => {
  let totalAmount = 0.0;
  if ( interval === API.SUB_INTERVAL_YEAR ) {
    totalAmount = ((quantity>5) ? (quantity-5) : 0)*5000 + ((quantity>5) ? 4 : (quantity-1))*6800 + ((quantity>0) ? 1 : 0)*8800
  } else if ( interval === API.SUB_INTERVAL_MONTH ) {
    totalAmount = ((quantity>5) ? (quantity-5) : 0)*500 + ((quantity>5) ? 4 : (quantity-1))*700 + ((quantity>0) ? 1 : 0)*900
  }
  return totalAmount/100
}

export const isValidQuantity = (value) => {
  const quantity = Number(value);
  return quantity && Number.isInteger(quantity) && quantity > 0 && quantity <= API.CONNECTOR_QUANTITY_MAX
}

export const processError = (error) => {
  const unknownError = "Unknown error";
  let message = "";
  let content = "";
  if ( !error.hasOwnProperty("response") ) {
    return {
      message: unknownError,
      content: "",
    }
  }
  if (!error.response) {
    return {
      // E.g. "Network Error"
      message: error.message || unknownError,
      content: "",
    }
  }
  if ( !error.response.hasOwnProperty("status") ) {
    return {
      message: unknownError,
      content: "",
    }
  }
  if ( !error.response.hasOwnProperty("data") ) {
    return {
      message: unknownError,
      content: "",
    }
  }
  const { data } = error.response;
  switch (error.response.status) {
  // Bad request
  case 400:
    if ( !data.hasOwnProperty("error") ) {
      if ( data.hasOwnProperty("status") && data.status !== "" ) {
        return {
          message: data.status,
          content: "",
        }
      }
      return {
        message: unknownError,
        content: "",
      }
    }
    message = data.error;
    break;
  // Not found
  case 404:
    message = "Not found";
    break;
  // Member or sub error
  case 460:
    if ( !data.hasOwnProperty("error") ) {
      if ( data.hasOwnProperty("status") && data.status !== "" ) {
        return {
          message: data.status,
          content: "",
        }
      }
      return {
        message: unknownError,
        content: "",
      }
    }
    message = data.error;
    if ( data.hasOwnProperty("content") && data.content !== "" ) {
      content = data.content;
    }
    break;
  // Internal error
  case 470:
  // Internal db error
  case 471:
  // Internal render error
  case 472:
  // Internal unexpected error
  case 473:
    message = "Internal error";
    break;
  // External error
  case 480:
  // Stripe error
  case 481:
  // Non-stripe error
  case 482:
  // Facebook error
  case 483:
    if ( !data.hasOwnProperty("error") ) {
      if ( data.hasOwnProperty("status") && data.status !== "" ) {
        return {
          message: data.status,
          content: "",
        }
      }
      return {
        message: unknownError,
        content: "",
      }
    }
    message = data.error;
    break;
  default:
    message = unknownError;
  }

  return {
    message,
    content,
  }
}
