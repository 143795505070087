import React, { Component } from 'react';
import { AuthUserContext, withAuthorization } from '../Session';
import { PurchaseButton } from '../Purchase';
import { Grid, Typography } from '@mui/material';
import '../../styles/main.css';

class CancelPage extends Component {
  render () {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <Grid container justifyContent="center">
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className="grid-row five"></div>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className="grid-row">
                <Typography variant="h6" component="h6">Order failed. Please try again.</Typography>
                <PurchaseButton />
              </div>
            </Grid>
          </Grid>
        )}
      </AuthUserContext.Consumer>
    )
  }
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(CancelPage);
