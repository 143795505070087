import React, { Component } from 'react';
import { AuthUserContext } from '../Session';
import SignUpPage from '../SignUp';
import Welcome from './Welcome';

class LandingPage extends Component {
  render() {
    return (
      <AuthUserContext.Consumer>
        { authUser => (
          authUser ? <Welcome /> : <SignUpPage />
        )}
      </AuthUserContext.Consumer>
    );
  }
};

export default LandingPage;
