import React, { Component, Fragment } from 'react';
import { Loading } from '../Loading';
import axios from 'axios';
import * as API from '../../constants/api';
import { processError, ConfigSuccess, formatConnector } from '../Util';
import { Alert, Button, Typography, TextField, Card, CardHeader, CardMedia, CardContent, Link as MuiLink } from '@mui/material';
import '../../styles/main.css';
import spHelpImage from '../../logo/sp_where_to_find_api_keys.png';
import spRestrictedKeyImage from '../../logo/sp_restricted_api_key.png';


const INITIAL_STATE = {
  email: '',
  connector: '',
  connectorFormatted: '',
  apiKey: '',
  isApiKeyValid: false,
  nickname: '',
  isNicknameValid: false,
  error: false,
  message: '',
  showForm: true,
  loading: false,
};

class ConfigStripe extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.onSubmit = this.onSubmit.bind(this);
    this.saveConfig = this.saveConfig.bind(this);
  }

  componentDidMount() {
    this.setState({
      email: this.props.authUserEmail,
      connector: API.STRIPE,
      connectorId: this.props.connectorId,
      connectorFormatted: formatConnector(API.STRIPE)
    });
  }

  onChangeApiKey = (event) => {
    const apiKey = event.target.value.replace(/ /g, "");
    let message = "";
    // Key: sk_live_xxx or rk_live_xxx digits and constters
    if ( apiKey === "" ) {
      message = "Please enter your API key.";
    } else if ( !apiKey.startsWith('sk_live_') && !apiKey.startsWith('rk_live_') ) {
      message = "API key has prefix sk_live_ or rk_live_.";
    } else if ( apiKey.length < 20 ) {
      message = "API key is a long string.";
    } else if ( !/^[a-zA-Z0-9]+$/.test(apiKey.replace('sk_live_', '').replace('rk_live_', '')) ) {
      message = "API key (substring after prefix) contains only digits and letters.";
    }
    this.setState({ [event.target.name]: apiKey, isApiKeyValid: message==="", error: message!=="", message });
  }

  onChangeNickname = (event) => {
    const nickname = event.target.value.replace(/ /g, "");
    let message = "";
    // Letters only
    if ( nickname === "" ) {
      message = "Please enter nickname.";
    } else if ( !/^[a-zA-Z]+$/.test(nickname) ) {
      message = "Please only enter letters for nickname.";
    }
    this.setState({ [event.target.name]: nickname, isNicknameValid: message==="", error: message!=="", message });
  }

  onSubmit(event) {
    event.preventDefault();
    this.saveConfig();
  }

  async saveConfig() {
    this.setState({ loading:true });
    const { email, connector, connectorId, apiKey, nickname } = this.state;
    await axios
    .post(`${API.FMI_API}/config/update/${API.STRIPE}/`, {
      email,
      connector_type: connector,
      connector_id: connectorId,
      account_name: nickname,
      account_token: apiKey
    }, API.HEADERS)
    .then( response => {
      if ( response.data.message !== API.MESSAGE_SUCCESS ) {
        this.setState({ error:true, showForm:true, loading:false,
          message:'Failed to update configuration. Please refresh the page and try again.',
        });
        return
      }
      this.setState({ error:false, message:'Configuration updated.', showForm:false, loading:false });
    })
    .catch( error => {
      const { message } = processError(error);
      this.setState({ error:true, message, showForm:true, loading:false });
    });
  }

  renderForm() {
    const { connectorFormatted, apiKey, nickname, isApiKeyValid, isNicknameValid } = this.state;
    const isValid = isApiKeyValid && isNicknameValid;
    return (
      <Fragment>
        <Typography variant="h6" component="h6">
          Please enter values for your {connectorFormatted} Connector
        </Typography>
        <br/>
        <form onSubmit={this.onSubmit}>
          <div className="txt-input">
            <TextField
              required
              label="API Key"
              id="api-key-input"
              variant="outlined"
              name="apiKey"
              value={apiKey}
              onChange={this.onChangeApiKey}
              fullWidth={true}
            />
          </div>
          <div className="txt-input">
            <Typography variant="subtitle2" component="div" align="left">
              Note: it has the prefix <Typography variant="subtitle2" color="error" component="span">sk_live_</Typography> or <Typography variant="subtitle2" color="error" component="span">rk_live_</Typography>. The substring after prefix contains only digits and letters.
            </Typography>
          </div>
          <div className="txt-input">
            <Typography variant="subtitle2" component="div" align="left">
              <MuiLink href={API.STRIPE_WHERE_TO_FIND_API_KEYS} target="_blank" rel="noopener" variant="body2" color="primary">
                Where to find your API key
              </MuiLink>
            </Typography>
          </div>
          <div className="txt-input">
            <Typography variant="subtitle2" component="div" align="left">
              <MuiLink href={API.STRIPE_API_KEYS_DOC_URL} target="_blank" rel="noopener" variant="body2" color="primary">
                Standard key vs restricted key
              </MuiLink>
            </Typography>
          </div>
          <br/>
          <div className="txt-input">
            <TextField
              required
              id="nickname-input"
              label="Nickname"
              variant="outlined"
              name="nickname"
              value={nickname}
              onChange={this.onChangeNickname}
              fullWidth={true}
            />
          </div>
          <div className="txt-input">
            <Typography variant="subtitle2" component="div" align="left">
              Note: <Typography variant="subtitle2" color="error" component="span">letters only</Typography>, useful when you have more than one {API.STRIPE_FORMATTED} account.
            </Typography>
          </div>
          <div className="txt-input-btn">
            <Button variant="contained" disabled={isValid===false} component="button" color="secondary" size="large" type="submit" fullWidth={true}>
              <Typography variant="h6" component="span" className="txt-white">Save</Typography>
            </Button>
          </div>
        </form>
      </Fragment>
    )
  }

  render() {
    const { connectorFormatted, showForm, loading, error, message } = this.state;
    return (
      <Fragment>
        <Loading loading={loading}/>
        { showForm ? this.renderForm() : <ConfigSuccess connectorFormatted={connectorFormatted}/> }
        { error === true && <Alert severity="error" className="card-error">{message}</Alert> }
        { showForm === true &&
          <Fragment>
            <Card className="card-sp">
              <CardContent>
                <CardHeader title="Where to find the API keys?" titleTypographyProps={{variant: "subtitle2"}}/>
                <CardMedia
                  style={{height: '100%', paddingTop: '70%'}}
                  image={spHelpImage}
                  title="Stripe"
                />
              </CardContent>
            </Card>
            <br/>
            <Card className="card-sp">
              <CardContent>
                <CardHeader title="How to create restricted key?" titleTypographyProps={{variant: "subtitle2"}}/>
                <CardMedia
                  style={{height: '100%', paddingTop: '60%'}}
                  image={spRestrictedKeyImage}
                  title="Restricted Key"
                />
              </CardContent>
            </Card>
          </Fragment>
        }
      </Fragment>
    )
  }
}

export default ConfigStripe;
