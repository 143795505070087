import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { Alert, AlertTitle, Typography, Button, Link as MuiLink, List, ListItem, ListItemText } from '@mui/material';
import '../../styles/main.css';

const ConfigSuccess = (props) => {
  return (
    <Fragment>
      <Typography variant="h5" component="h5">
        Thank you! Your {props.connectorFormatted} Connector is ready.
      </Typography>
      <br/>
      <div className="txt-wide">
        <List component="nav" aria-label="config success">
          <ListItem>
            <ListItemText primary="1. Please go back to My Connectors page and copy the master token and keep it safe." />
          </ListItem>
          <ListItem>
            <ListItemText primary="2. You will use your email and the master token to create data source in Data Studio." />
          </ListItem>
          <ListItem>
            <ListItemText primary="3. Make sure that you build a simple report to test everything works fine before starting to build any reports you want." />
          </ListItem>
          <ListItem>
            <ListItemText primary="4. If the new account hasn't shown up at the config dropdown when creating a new data source, please wait for a moment, or try to revoke and re-authenticate the Connector." />
          </ListItem>
        </List>
      </div>
      <div className="cfg-btn-div">
        <Button variant="contained" color="secondary" size="medium" fullWidth={true}
          component={ Link } to={ROUTES.MY_PRODUCTS}>
          <Typography variant="h6" component="span" className="txt-white">Take me to My Connectors</Typography>
        </Button>
      </div>
      <div className="txt-alert">
        <Alert severity="success">
          <AlertTitle>Tip</AlertTitle>
          Your email and the master token are the credentials.
        </Alert>
      </div>
      <br/>
      <div className="txt-alert">
        <Alert severity="success">
          <AlertTitle>More Tips and Tricks</AlertTitle>
          Checkout our&nbsp;
          <MuiLink href={ROUTES.YOUTUBE_CHANNEL} target="_blank" rel="noopener" variant="subtitle1" color="primary">
            Youtube Channel
          </MuiLink>.
        </Alert>
      </div>
    </Fragment>
  )
}

export default ConfigSuccess;
