import React, { Component, Fragment } from 'react';
import { Loading } from '../Loading';
import qs from 'qs';
import axios from 'axios';
import * as API from '../../constants/api';
import * as ROUTES from '../../constants/routes';
import { processError, ConfigSuccess, formatConnector, withRouter } from '../Util';
import { Alert, Button, Typography, TextField } from '@mui/material';
import '../../styles/main.css';


const INITIAL_STATE = {
  email: '',
  connector: '',
  connectorFormatted: '',
  error: false,
  message: '',
  showAuthButton: true,
  showForm: false,
  showSuccess: false,
  loading: false,
  oauthUrl: '',
  stateString: '',
  code: '',
  grantedScopes: '',
  nickname: '',
};

class ConfigFacebookPages extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    const scopes = `${API.FACEBOOK_PAGE_API_SCOPES.join(",")},${API.INSTAGRAM_API_SCOPES.join(",")}`;
    const redirectUrl = `${ROUTES.BASE_URL}${ROUTES.CONFIG}/${API.FACEBOOK_PAGES}/`;
    const oauthUrl = `${API.FACEBOOK_PAGES_OAUTH_URL}?client_id=${API.FACEBOOK_PAGES_CLIENT_ID}&redirect_uri=${redirectUrl}&state=${this.props.connectorId}&response_type=code,granted_scopes&scope=${scopes}`;
    this.setState({
      email: this.props.authUserEmail,
      connector: API.FACEBOOK_PAGES,
      connectorId: this.props.connectorId,
      connectorFormatted: formatConnector(API.FACEBOOK_PAGES),
      oauthUrl,
      stateString: this.props.connectorId,
    });
    if ( this.props.router.location && this.props.router.location.search ) {
      const urlParam = qs.parse(this.props.router.location.search, { ignoreQueryPrefix: true });
      if ( urlParam && urlParam.hasOwnProperty("code") && urlParam.hasOwnProperty("state") ) {
        const { code, state } = urlParam;
        if ( urlParam.hasOwnProperty("granted_scopes") ) {
          const grantedScopes = decodeURI(urlParam.granted_scopes);
          if ( grantedScopes === "" ) {
            this.setState({
              error: true,
              message: "Please grant access (select at least one Page) in order to use the Connector.",
            });
          }
          // If member wants Instagram insight, they must grant access to the associated page as well
          // If page access not granted, the associated Instagram user will be ignored
          const requiredScopes = API.FACEBOOK_PAGE_API_SCOPES.join(",");
          for (let i=0; i<requiredScopes.length; i++) {
            if ( grantedScopes.indexOf(requiredScopes[i]) === -1 ) {
              this.setState({
                error: true,
                message: "Please grant access (select at least one Page) in order to use the Connector.",
              });
              return;
            }
          }
          // All scopes granted
          this.setState({
            code,
            grantedScopes,
            connectorId: state,
            loading:true,
            error:false,
            message:"",
          }, () => {
            this.onAuthorize(this.props.authUserEmail, API.FACEBOOK_PAGES, state, code, grantedScopes);
          });
        }
      }
    }
  }

  onAuthorize = async (email, connector, connectorId, code, scope) => {
    await axios
    .post(`${API.FMI_API}/config/update/${API.FACEBOOK_PAGES}/`, {
      email,
      connector_type: connector,
      connector_id: connectorId,
      code,
      scope,
    }, API.HEADERS)
    .then( response => {
      if ( response.data.message !== API.MESSAGE_SUCCESS ) {
        this.setState({
          error:true,
          message:'Failed to update configuration. Please refresh the page and try again.',
          showAuthButton:true,
          showForm:false,
          showSuccess:false,
          loading:false,
        });
        return
      }
      this.setState({
        error:false,
        message:'Configuration (authorization) updated.',
        showAuthButton:false,
        showForm:true,
        showSuccess:false,
        loading:false,
      });
    })
    .catch( error => {
      const { message } = processError(error);
      this.setState({
        error:true,
        message,
        showAuthButton:true,
        showForm:false,
        showSuccess:false,
        loading:false,
      });
    });
  }

  onSkip = (event) => {
    this.setState({
      error:false,
      message:'',
      showAuthButton:false,
      showForm:true,
      showSuccess:false,
    });
  }

  onSkipEnter = (event) => {
    this.setState({
      error:false,
      message:'',
      showAuthButton:false,
      showForm:false,
      showSuccess:true,
    });
  }

  onChangeNickname = (event) => {
    const nickname = event.target.value.replace(/ /g, "");
    let message = "";
    if (!/^[a-zA-Z]+$/.test(nickname)) {
      message = "Please only enter letters for nickname";
    }
    this.setState({ [event.target.name]: nickname, error:message!=="", message });
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.saveConfig();
  }

  saveConfig = async () => {
    await axios
    .post(`${API.FMI_API}/config/update/${API.FACEBOOK_PAGES}/`, {
      email: this.state.email,
      connector_type: this.state.connector,
      connector_id: this.state.connectorId,
      nickname: this.state.nickname,
    }, API.HEADERS)
    .then( response => {
      if ( response.data.message !== API.MESSAGE_SUCCESS ) {
        this.setState({
          error:true,
          message:'Failed to update configuration. Please refresh the page and try again.',
          showAuthButton:false,
          showForm:true,
          showSuccess:false,
          loading:false,
        });
        return
      }
      this.setState({
        error:false,
        message:'Configuration (nickname) updated.',
        showAuthButton:false,
        showForm:false,
        showSuccess:true,
        loading:false,
      });
    })
    .catch( error => {
      const { message } = processError(error);
      this.setState({
        error:true,
        message,
        showAuthButton:false,
        showForm:true,
        showSuccess:false,
        loading:false,
      });
    });
  }

  renderAuthButton() {
    const {
      connectorFormatted,
      oauthUrl,
    } = this.state;
    return (
      <Fragment>
        <Typography variant="h6" component="h6">
          Please authorize access to your {connectorFormatted} data for your Connector.
        </Typography>
        <br/>
        <div className="txt-input-btn">
          <Button variant="contained" component="a" color="secondary" size="large" fullWidth={true} href={oauthUrl}>
            <Typography variant="h6" component="span" className="txt-white">Authorize</Typography>
          </Button>
        </div>
        <div className="txt-input">
          <Typography variant="subtitle2" component="div" align="left">
            Note: if you select an Instagram business account, make sure to grant access to the associated Page as well.
            &nbsp;If the Page is not selected, the associated Instagram account will be ignored.
          </Typography>
        </div>
        <br/>
        <br/>
        <Typography variant="body1" component="div">
          - or -
        </Typography>
        <br/>
        <Typography variant="body1" component="div">
          Skip if you've authorized before and only want to update the account id.
        </Typography>
        <br/>
        <div className="txt-input-btn-sm">
          <Button variant="contained" component="button" color="secondary" size="small" fullWidth={true} onClick={this.onSkip}>
            <Typography variant="body1" component="span" className="txt-white">Skip</Typography>
          </Button>
        </div>
      </Fragment>
    )
  }

  renderForm() {
    const { connectorFormatted, nickname } = this.state;
    const isInvalid = nickname === '' || !(/^[a-zA-Z]+$/.test(nickname));
    return (
      <Fragment>
        <Typography variant="h6" component="h6">
          Please enter nickname for your {connectorFormatted} Connector.
        </Typography>
        <br/>
        <form onSubmit={this.onSubmit}>
          <div className="txt-input">
            <TextField
              required
              label="Nickname"
              id="nickname-input"
              variant="outlined"
              name="nickname"
              value={nickname}
              onChange={this.onChangeNickname}
              fullWidth={true}
            />
          </div>
          <div className="txt-input">
            <Typography variant="subtitle2" component="div" align="left">
              Note: <Typography variant="subtitle2" color="error" component="span">letters only</Typography>, useful when you have more than one Facebook account.
            </Typography>
          </div>
          <div className="txt-input-btn">
            <Button variant="contained" disabled={isInvalid===true} component="button" color="secondary" size="large" type="submit" fullWidth={true}>
              <Typography variant="h6" component="span" className="txt-white">Save</Typography>
            </Button>
          </div>
        </form>
        <br/>
        <Typography variant="body1" component="div">
          - or -
        </Typography>
        <br/>
        <Typography variant="body1" component="div">
          Skip if you've entered before.
        </Typography>
        <br/>
        <div className="txt-input-btn-sm">
          <Button variant="contained" component="button" color="secondary" size="small" fullWidth={true} onClick={this.onSkipEnter}>
            <Typography variant="body1" component="span" className="txt-white">Skip</Typography>
          </Button>
        </div>
      </Fragment>
    )
  }

  render() {
    const {
      connectorFormatted,
      showAuthButton,
      showForm,
      showSuccess,
      loading,
      error,
      message,
    } = this.state;
    return (
      <Fragment>
        <Loading loading={loading}/>
        { showAuthButton === true && this.renderAuthButton() }
        { showForm === true && this.renderForm() }
        { showSuccess === true && <ConfigSuccess connectorFormatted={connectorFormatted}/> }
        { error === true && <Alert severity="error" className="card-error">{message}</Alert> }
      </Fragment>
    )
  }
}

export default withRouter(ConfigFacebookPages);
