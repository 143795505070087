import React, { Component, Fragment } from 'react';
import { withFirebase } from '../Firebase';
import { Loading } from '../Loading';
import { Button, TextField, Typography } from '@mui/material';
import '../../styles/main.css';

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: false,
  message: '',
  loading: false,
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    this.setState({ loading:true });
    const { passwordOne } = this.state;
    this.props.firebase
    .doPasswordUpdate(passwordOne)
    .then(() => {
      this.setState({
        passwordOne: '',
        passwordTwo: '',
        error: false,
        message:"Your password has been updated successfully.",
        loading: false,
      });
    })
    .catch(error => {
      this.setState({ passwordOne: '', passwordTwo: '', error:true, message:error.message || "Unknown error", loading:false });
    });
    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value, error: false, message: '' });
  };

  render() {
    const { passwordOne, passwordTwo, error, message, loading } = this.state;
    const notMatch = passwordOne !== passwordTwo && passwordOne !== '' && passwordTwo !== '';
    const isInvalid = passwordOne !== passwordTwo || passwordOne === '';

    return (
      <Fragment>
        <Loading loading={loading}/>
        <form onSubmit={this.onSubmit}>
          <div className="txt-input">
            <TextField
              required
              label="New Password"
              type="password"
              variant="outlined"
              name="passwordOne"
              autoComplete="new-password"
              value={passwordOne}
              onChange={this.onChange}
              fullWidth={true}
            />
          </div>
          <div className="txt-input">
            <TextField
              required
              label="Confirm New Password"
              type="password"
              variant="outlined"
              name="passwordTwo"
              autoComplete="new-password"
              value={passwordTwo}
              onChange={this.onChange}
              fullWidth={true}
            />
          </div>
          <div className="txt-input-btn">
            <Button variant="contained" disabled={isInvalid===true} component="button" color="primary" size="large" type="submit" fullWidth={true}>
              <Typography variant="h6" component="span" className="txt-white">Change</Typography>
            </Button>
          </div>
          { notMatch === true &&
            <Typography variant="body1" component="div" color="error">
              Password doesn't match
            </Typography>
          }
          { error === true ?
            <Typography variant="body1" component="span" color="error">{message}</Typography>
            : <Typography variant="body1" component="span" color="primary">{message}</Typography>
          }
        </form>
      </Fragment>
    );
  }
}

export default withFirebase(PasswordChangeForm);
