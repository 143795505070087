import React from 'react';
import { Button } from '@mui/material';
import '../../styles/main.css';

const ActionButton = (props) => (
  <div className="pd-list-act-btn">
    <Button variant={props.variant || "outlined"} fullWidth={true} color="primary" size="small" {...props}>
      <span className={props.textclassname || "txt-bold"}>{props.text}</span>
    </Button>
  </div>
)

export default ActionButton;
