import React, { Component, Fragment } from 'react';
import { Loading } from '../Loading';
import axios from 'axios';
import * as API from '../../constants/api';
import { processError, ConfigSuccess, formatConnector } from '../Util';
import { Alert, Button, Typography, TextField, Card, CardHeader, CardMedia, CardContent } from '@mui/material';
import '../../styles/main.css';
import acHelpImage from'../../logo/ac_account_name_and_key.png';


const INITIAL_STATE = {
  email: '',
  connector: '',
  connectorFormatted: '',
  accountName: '',
  isAccountNameValid: false,
  accountToken: '',
  isAccountTokenValid: false,
  error: false,
  message: '',
  showForm: true,
  loading: false,
};

class ConfigActiveCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.onSubmit = this.onSubmit.bind(this);
    this.saveConfig = this.saveConfig.bind(this);
  }

  componentDidMount() {
    this.setState({
      email: this.props.authUserEmail,
      connector: API.ACTIVECAMPAIGN,
      connectorId: this.props.connectorId,
      connectorFormatted: formatConnector(API.ACTIVECAMPAIGN)
    });
  }

  onChangeAccountName = (event) => {
    const accountName = event.target.value.replace(/ /g, "");
    let message = "";
    // Digits and letters and -
    if ( accountName === "" ) {
      message = "Please enter account name.";
    } else if ( accountName.indexOf('.com') > -1 ) {
      message = "If your URL is https://abc.activehosted.com, please enter abc for the account name.";
    } else if ( accountName.indexOf('http' ) > -1 ) {
      message = "If your URL is https://abc.activehosted.com, please enter abc for the account name.";
    } else if ( accountName.indexOf('activehosted') > -1 ) {
      message = "If your URL is https://abc.activehosted.com, please enter abc for the account name.";
    } else if ( accountName.indexOf('12all') > -1 ) {
      message = "If your URL is https://abc.12all.com, please enter abc for the account name.";
    } else if ( accountName.indexOf('api-us1') > -1 ) {
      message = "If your URL is https://abc.api-us1.com, please enter abc for the account name.";
    } else if ( !/^[a-zA-Z0-9-]+$/.test(accountName) ) {
      message = "Please enter only digits letters and - for account name.";
    }
    this.setState({ [event.target.name]: accountName, isAccountNameValid: message==="", error: message!=="", message });
  }

  onChangeAccountToken = (event) => {
    const accountToken = event.target.value.replace(/ /g, "");
    let message = "";
    // Digits and lowercase letters
    if ( accountToken === "" ) {
      message = "Please enter developer key.";
    } else if ( accountToken.length < 30 ) {
      message = "Please enter valid developer key.";
    } else if ( !/^[a-z0-9]+$/.test(accountToken) ) {
      message = "Invalid developer key, digits and lowercase letters only";
    }
    this.setState({ [event.target.name]: accountToken, isAccountTokenValid: message==="", error: message!=="", message });
  }

  onSubmit(event) {
    event.preventDefault();
    this.saveConfig();
  }

  async saveConfig() {
    this.setState({ loading:true });
    const { email, connector, connectorId, accountName, accountToken } = this.state;
    await axios
    .post(`${API.FMI_API}/config/update/${API.ACTIVECAMPAIGN}/`, {
      email,
      connector_type: connector,
      connector_id: connectorId,
      account_name: accountName.toLowerCase(),
      account_token: accountToken
    }, API.HEADERS)
    .then( response => {
      if ( response.data.message !== API.MESSAGE_SUCCESS ) {
        this.setState({ error:true, message:'Configuration failed. Please refresh the page and try again.', showForm:true, loading:false });
        return
      }
      this.setState({ error:false, message:'Configuration updated.', showForm:false, loading:false });
    })
    .catch( error => {
      const { message } = processError(error);
      this.setState({ error:true, message, showForm:true, loading:false });
    });
  }

  renderForm() {
    const { connectorFormatted, accountName, accountToken, isAccountNameValid, isAccountTokenValid } = this.state;
    const isValid = isAccountNameValid && isAccountTokenValid;
    return (
      <Fragment>
        <Typography variant="h6" component="h6">
          Please enter values for your {connectorFormatted} Connector
        </Typography>
        <br/>
        <form onSubmit={this.onSubmit}>
          <div className="txt-input">
            <TextField
              required
              label="Account Name"
              id="account-name-input"
              variant="outlined"
              name="accountName"
              value={accountName}
              onChange={this.onChangeAccountName}
              fullWidth={true}
            />
          </div>
          <div className="txt-input">
            <Typography variant="subtitle2" component="div" align="left">
              Note: for instance <Typography variant="subtitle2" color="error" component="span">findmyinsight</Typography>, not the URL.
            </Typography>
          </div>
          <br/>
          <div className="txt-input">
            <TextField
              required
              id="account-token-input"
              label="Developer Key"
              variant="outlined"
              name="accountToken"
              value={accountToken}
              onChange={this.onChangeAccountToken}
              fullWidth={true}
            />
          </div>
          <div className="txt-input">
            <Typography variant="subtitle2" component="div" align="left">
              Note: a long string of <Typography variant="subtitle2" color="error" component="span">only digits and lowercase letters</Typography>.
            </Typography>
          </div>
          <div className="txt-input-btn">
            <Button variant="contained" disabled={isValid===false} component="button" color="secondary" size="large" type="submit" fullWidth={true}>
              <Typography variant="h6" component="span" className="txt-white">Save</Typography>
            </Button>
          </div>
        </form>
      </Fragment>
    )
  }

  render() {
    const { connectorFormatted, showForm, loading, error, message } = this.state;
    return (
      <Fragment>
        <Loading loading={loading}/>
        { showForm ? this.renderForm() : <ConfigSuccess connectorFormatted={connectorFormatted}/> }
        { error === true && <Alert severity="error" className="card-error">{message}</Alert> }
        { showForm === true &&
          <Card className="card-ac">
            <CardContent>
              <CardHeader title="Where to find these values?" titleTypographyProps={{variant: "subtitle2"}}/>
              <CardMedia
                style={{height: '100%', paddingTop: '50%'}}
                image={acHelpImage}
                title="ActiveCampaign"
              />
            </CardContent>
          </Card>
        }
      </Fragment>
    )
  }
}

export default ConfigActiveCampaign;
