import React, { Fragment } from 'react';
import * as ROUTES from '../../constants/routes';
import { Link, FormControlLabel, Checkbox, Typography  } from '@mui/material';
import '../../styles/main.css';

const ConsentCheckbox = (props) => (
  <Fragment>
    <Typography variant="body2" component="div">By ticking this box below, you agree to our&nbsp;
      <Link target="_blank" rel="noopener" href={ROUTES.TERMS_AND_CONDITIONS} variant="body2" color="inherit" underline="always">
        Terms of Service
      </Link>
      &nbsp;and&nbsp;
      <Link target="_blank" rel="noopener" href={ROUTES.PRIVACY_POLICY} variant="body2" color="inherit" underline="always">
        Privacy Policy
      </Link>.
    </Typography>
    <FormControlLabel
      control={<Checkbox checked={props.checked} onChange={props.onChange} color="primary"/>}
      label="I agree."
    />
  </Fragment>
)

export default ConsentCheckbox;
