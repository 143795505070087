import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import axios from 'axios';
import * as API from '../../constants/api';
import { processError, DialogError, DialogSuccess } from '../Util';
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../../styles/main.css';


const INITIAL_STATE = {
  email: '',
  customerId: '',
  id: '',
  status: '',
  interval: '',
  promotionCode: '',
  confirmed: false,
  error: false,
  message: '',
  content: '',
  loading: false,
};

class DiscountSubDialog extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  static propTypes = {
    open: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    customerId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    interval: PropTypes.string.isRequired,
  };

  componentDidUpdate(prevProps) {
    if ( this.props.open !== prevProps.open && this.props.open === true ) {
      if ( this.props.email !== "" && this.props.customerId !== "" && this.props.id !== "" && this.props.status !== "" && this.props.interval !== "" ) {
        this.setState({
          email: this.props.email,
          customerId: this.props.customerId,
          id: this.props.id,
          status: this.props.status,
          interval: this.props.interval,
          promotionCode: '',
          confirmed: false,
          error: false,
          message: '',
          loading: false,
        });
      } else {
        this.props.onClose(false);
      }
    }
  }

  handleClose = () => {
    this.setState({
      promotionCode: '',
    });
    this.props.onClose(true);
  };

  handleCancel = () => {
    this.setState({
      promotionCode: '',
    });
    this.props.onClose(false);
  };

  handleDiscountSub = async () => {
    this.setState({ loading:true });
    const { email, customerId, id, status, interval, promotionCode } = this.state;
    if ( email !== "" && id !== "" ) {
      await axios
      .post(`${API.FMI_API}/sub/discount/`, {
        email,
        customer_id: customerId,
        sub_id: id,
        status,
        interval,
        promotion_code: promotionCode,
      }, API.HEADERS)
      .then( response => {
        this.setState({ confirmed:true, error:false, message:'', loading:false });
      })
      .catch( error => {
        const { message, content } = processError(error);
        this.setState({ error:true, message, content, loading:false });
      });
    }
  };

  handleChange = (event) => {
    this.setState({ promotionCode:event.target.value });
  };

  renderQuestion() {
    const { loading, promotionCode } = this.state;
    const invalid = promotionCode === "";
    return (
      <Fragment>
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>
              Enter Your Promotion Code
            </Grid>
            <Grid item>
              <IconButton onClick={this.handleCancel} size="small">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <div className="sb-text">
            <TextField
              label="Enter your code here"
              variant="outlined"
              value={promotionCode}
              onChange={this.handleChange}
            />
          </div>
        </DialogContent>
        <DialogActions>
          { loading === true ?
            <CircularProgress />
            :
            <Grid container justifyContent="flex-end">
              <Grid item>
                <span className="dialog-btn">
                  <Button onClick={this.handleDiscountSub} color="primary" variant="outlined" disabled={invalid}>
                    Confirm
                  </Button>
                </span>
              </Grid>
            </Grid>
          }
        </DialogActions>
      </Fragment>
    )
  }

  render() {
    const { confirmed, error, message, content } = this.state;
    return (
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        aria-labelledby="confirm-discount-sub"
        open={this.props.open}
        className="sb-dialog"
      >
        { error === false && confirmed === false && this.renderQuestion() }
        { error === false && confirmed === true &&
          <DialogSuccess
            onClick={this.handleClose}
            title={"Thank you, your subscription has been updated."}
            content={`Please wait while we add your ${API.PRICE_NICKNAME_NCT}. If it is not showing please refresh your 'My Subscriptions' page.`}
          />
        }
        { error === true &&
          <DialogError
            onClick={this.handleClose}
            message={message}
            content={content}
          />
        }
      </Dialog>
    );
  }
}

export default DiscountSubDialog;
