import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import * as API from '../../constants/api';
import * as ROUTES from '../../constants/routes';
import { withRouter, formatTime, processError, ActionButton } from '../Util';
import { Loading } from '../Loading';
import CancelSubDialog from './CancelSubDialog';
import CancelSubItemDialog from './CancelSubItemDialog';
import EndTrialNowDialog from './EndTrialNowDialog';
import DiscountSubDialog from './DiscountSubDialog';
import ActivateSubItemDialog from './ActivateSubItemDialog';
import { Paper, TableContainer, Table, TableCell, TableRow, TableHead, TableBody, Typography, Link as MuiLink } from '@mui/material';
import '../../styles/main.css';

const INITIAL_STATE = {
  subItemList: [],
  loading: false,
  error: false,
  message: '',
  openCancelSub: false,
  openCancelSubItem: false,
  openEndTrialNow: false,
  openDiscountSub: false,
  openActivateSubItem: false,
  email: '',
  customerId: '',
  id: '',
  subItemId: '',
  status: '',
  priceNickname: '',
  quantity: 0,
  interval: '',
  cancelTrial: false,
  periodEnd: '',
};

class SubList extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    const email = this.props.authUser.email;
    const customerId = this.props.authUser.customerId || "";
    this.setState({ email, customerId, loading:true });
    this.loadSub(email, customerId);
  }

  loadSub = async (email, customerId) => {
    await axios
    .post(`${API.FMI_API}/sub/list/`, {
      email,
      customerId,
    }, API.HEADERS)
    .then( response => {
      if ( response.data.message === API.MESSAGE_SUCCESS && response.data.subscriptions ) {
        const subItemList = this.formatSubItemList(response.data.subscriptions[0]);
        this.setState({ subItemList, loading:false });
        return
      }
      if ( response.data.message === API.MESSAGE_NOT_FOUND ) {
        this.setState({ loading:false });
        if ( response.data.hasOwnProperty("eligible_for_free_trial") && response.data.eligible_for_free_trial === true ) {
          this.props.onShowPurchaseButton(true, true);
          return
        }
        this.props.onShowPurchaseButton(true, false);
        return
      }
      // Unexpected response
      this.setState({ error:true, loading:false });
    })
    .catch( error => {
      const { message, content } = processError(error);
      this.setState({ error:true, message, content, loading:false });
    });
  }

  formatSubItemList = (sub) => {
    if ( sub.hasOwnProperty("status") && sub.status !== "" ) {
      sub.status_formatted = sub.status.charAt(0).toUpperCase() + sub.status.slice(1).toLowerCase().replace("_", " ");
    }
    if ( sub.hasOwnProperty("interval") && sub.interval !== "" ) {
      sub.interval_formatted = sub.interval.charAt(0).toUpperCase() + sub.interval.slice(1).toLowerCase();
    }
    if ( sub.hasOwnProperty("current_period_start") && sub.current_period_start !== "" ) {
      sub.current_period_start_formatted = formatTime(sub.current_period_start);
    }
    if ( sub.hasOwnProperty("current_period_end") && sub.current_period_end !== "" ) {
      sub.current_period_end_formatted = formatTime(sub.current_period_end);
    }
    if ( sub.hasOwnProperty("previous_period_start") && sub.previous_period_start !== "" ) {
      sub.previous_period_start_formatted = formatTime(sub.previous_period_start);
    }
    if ( sub.hasOwnProperty("previous_period_end") && sub.previous_period_end !== "" ) {
      sub.previous_period_end_formatted = formatTime(sub.previous_period_end);
    }
    if ( sub.hasOwnProperty("cancel_at") && sub.cancel_at !== "" ) {
      sub.cancel_at_formatted = formatTime(sub.cancel_at);
    }

    // Format each sub item
    let subItemList = [];
    if ( sub.hasOwnProperty("items") ) {
      subItemList = sub.items;
      subItemList.forEach( item => {
        // Customer Id: item.customer_id = sub.customer_id;
        if ( item.hasOwnProperty("status") && item.status !== "" ) {
          if ( item.hasOwnProperty("price_nickname") && item.price_nickname.startsWith(API.PRICE_NICKNAME_NCT) ) {
            item.status_formatted = `Trialing`;
          } else {
            item.status_formatted = item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase().replace("_", " ");
          }
        }
        if ( item.hasOwnProperty("interval") && item.interval !== "" ) {
          item.interval_formatted = item.interval.charAt(0).toUpperCase() + item.interval.slice(1).toLowerCase();
        }
        if ( item.hasOwnProperty("period_start") && item.period_start !== "" ) {
          item.current_period_start = item.period_start;
          item.current_period_start_formatted = formatTime(item.period_start);
        } else {
          item.current_period_start = sub.current_period_start;
          item.current_period_start_formatted = sub.current_period_start_formatted;
        }
        if ( item.hasOwnProperty("period_end") && item.period_end !== "" ) {
          item.current_period_end = item.period_end;
          item.current_period_end_formatted = formatTime(item.period_end);
        } else {
          item.current_period_end = sub.current_period_end;
          item.current_period_end_formatted = sub.current_period_end_formatted;
        }
        if ( sub.hasOwnProperty("cancel_at") && sub.cancel_at !== "" ) {
          item.cancel_at = sub.cancel_at;
          item.cancel_at_formatted = sub.cancel_at_formatted;
        }
      });
      return subItemList;
    }

    return [sub];
  }

  updateSub = (event, subItemId, priceNickname, quantity, interval) => {
    event.preventDefault();
    const id = event.currentTarget.value;
    let hasNCT = false;
    const { subItemList } = this.state;
    subItemList.forEach( item => {
      if ( item.price_nickname.startsWith(API.PRICE_NICKNAME_NCT) ) {
        hasNCT = true;
        if ( item.sub_item_id === subItemId ) {
          this.setState({ error:true, message: "You cannot update this item." });
        }
      }
    })
    this.props.router.navigate(ROUTES.SUB_UPDATE, { state: {
      email: this.state.email,
      id,
      subItemId,
      nickname: priceNickname,
      quantity,
      interval,
      hasNCT,
    }});
  }

  cancelSub = (event) => {
    event.preventDefault();
    const id = event.currentTarget.value;
    if ( id === "" ) {
      this.setState({ error:true, message: "Something wrong. Please try again. If it fails again, raise a ticket on the help desk." });
      return
    }
    let cancelTrial = false;
    const { subItemList } = this.state;
    let periodEnd = "";
    for (let i = 0; i < subItemList.length; i++) {
      if (subItemList[i].sub_id === id) {
        if (subItemList[i].status === API.SUB_STATUS_TRIALING) {
          cancelTrial = true;
          break;
        }
        if ( !subItemList[i].price_nickname.startsWith(API.PRICE_NICKNAME_NCT) ) {
          periodEnd = subItemList[i].current_period_end_formatted;
        }
      }
    }
    if ( cancelTrial === false ) {
      this.setState({ openCancelSub:true, id, cancelTrial:false, periodEnd });
    } else if (cancelTrial === true) {
      this.setState({ openCancelSub:true, id, cancelTrial:true });
    }
  }

  cancelSubItem = (event, customerId, subItemId, priceNickname) => {
    event.preventDefault();
    const id = event.currentTarget.value;
    if ( id === "" ) {
      this.setState({
        error: true,
        message: "Something wrong. Please try again. If it fails again, raise a ticket on the help desk.",
      });
      return
    }
    this.setState({
      openCancelSubItem: true,
      customerId,
      id,
      subItemId,
      priceNickname,
    });
  }

  endTrialNow = (event) => {
    event.preventDefault();
    const id = event.currentTarget.value;
    if ( id === "" ) {
      this.setState({ error:true, message: "Something wrong. Please try again. If it fails again, raise a ticket on the help desk." });
      return
    }
    this.setState({ openEndTrialNow:true, id });
  }

  discountSub = (event, customerId, status, interval) => {
    event.preventDefault();
    const id = event.currentTarget.value;
    if ( !id || !customerId || !status || !interval ) {
      this.setState({
        error:true,
        message: "Something wrong. Please try again. If it fails again, raise a ticket on the help desk.",
      });
      return
    }
    this.setState({ openDiscountSub:true, customerId, id, status, interval });
  }

  activateSubItem = (event, customerId, subItemId) => {
    event.preventDefault();
    const id = event.currentTarget.value;
    if ( id === "" ) {
      this.setState({
        error:true,
        message: "Something wrong. Please try again. If it fails again, raise a ticket on the help desk.",
      });
      return
    }
    let quantity = 0;
    let interval = '';
    const { subItemList } = this.state;
    subItemList.forEach( item => {
      if ( item.sub_id === id ) {
        quantity += item.quantity;
        interval = item.interval;
      }
    });
    this.setState({ openActivateSubItem:true, customerId, id, subItemId, quantity, interval });
  }

  onClose = (reload) => {
    this.setState({
      openCancelSub: false,
      openCancelSubItem: false,
      openEndTrialNow: false,
      openDiscountSub: false,
      openActivateSubItem: false,
    });
    if ( reload === true ) {
      this.setState({ loading:true, subItemList:[] });
      this.loadSub(this.state.email);
    }
  }

  render() {
    const {
      subItemList, error, message, loading,
      openCancelSub, openCancelSubItem, openEndTrialNow, openDiscountSub, openActivateSubItem,
      email, customerId, id, subItemId, status, priceNickname, quantity, interval, cancelTrial,
      periodEnd,
    } = this.state;
    return (
      <div className="pd-list-div">
        <Loading loading={loading}/>
        <CancelSubDialog
          keepMounted
          open={openCancelSub}
          onClose={this.onClose}
          email={email}
          id={id}
          cancelTrial={cancelTrial}
          periodEnd={periodEnd}
        />
        <CancelSubItemDialog
          keepMounted
          open={openCancelSubItem}
          onClose={this.onClose}
          email={email}
          customerId={customerId}
          id={id}
          subItemId={subItemId}
          nickname={priceNickname}
        />
        <EndTrialNowDialog
          keepMounted
          open={openEndTrialNow}
          onClose={this.onClose}
          email={email}
          id={id}
        />
        <DiscountSubDialog
          keepMounted
          open={openDiscountSub}
          onClose={this.onClose}
          email={email}
          customerId={customerId}
          id={id}
          status={status}
          interval={interval}
        />
        <ActivateSubItemDialog
          keepMounted
          open={openActivateSubItem}
          onClose={this.onClose}
          email={email}
          customerId={customerId}
          id={id}
          subItemId={subItemId}
          quantity={quantity}
          interval={interval}
        />
        <Typography variant="h6" component="div">My Subscription</Typography>
        <TableContainer component={Paper} elevation={1}>
          <Table className="pd-list-tb" aria-label="sub table">
            <TableHead>
              <TableRow>
                <TableCell className="col-sm"></TableCell>
                <TableCell align="left" className="col-sm">Status</TableCell>
                <TableCell align="left" className="col-sm">Type</TableCell>
                <TableCell align="left" className="col-sm">Connector Quantity</TableCell>
                <TableCell align="left" className="col-sm">Interval Count</TableCell>
                <TableCell align="left" className="col-sm">Current Period</TableCell>
                <TableCell align="left" className="col-sm">Previous Period</TableCell>
                <TableCell align="center" className="col-sm">Invoices</TableCell>
                <TableCell align="center" className="col-sm">Actions*</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subItemList.map(item => (
                <TableRow key={ item.hasOwnProperty("sub_item_id") ? item.sub_item_id : item.sub_id }>
                  <TableCell component="th" scope="row">{item.price_nickname}</TableCell>
                  <TableCell align="left">
                    {item.status_formatted}
                    { item.hasOwnProperty("cancel_at_formatted") &&
                      item.cancel_at_formatted !== "" &&
                      <Fragment>
                        <br/>
                        (Available until <span className="txt-error txt-bold">{item.cancel_at_formatted}</span>)
                      </Fragment>
                    }
                  </TableCell>
                  <TableCell align="left">
                    { !item.price_nickname.startsWith(API.PRICE_NICKNAME_NCT) &&
                      <span>{item.interval_formatted}</span>
                    }
                  </TableCell>
                  <TableCell align="left">{item.quantity}</TableCell>
                  <TableCell align="left">
                    { !item.price_nickname.startsWith(API.PRICE_NICKNAME_NCT) &&
                      <span>{item.interval_count}</span>
                    }
                  </TableCell>
                  <TableCell align="left">{item.current_period_start_formatted} - {item.current_period_end_formatted}</TableCell>
                  <TableCell align="left">{item.previous_period_start_formatted} - {item.previous_period_end_formatted}</TableCell>
                  <TableCell align="center">
                    { !!item.hosted_invoice_url &&
                      <ActionButton value={item.sub_id} target="_blank" rel="noopener" href={item.hosted_invoice_url} text="Latest Invoice"/>
                    }
                    { !item.price_nickname.startsWith(API.PRICE_NICKNAME_NCT) &&
                      <ActionButton value={item.sub_id} component={ Link } to={ROUTES.MY_INVOICES} text="All Invoices"/>
                    }
                  </TableCell>
                  <TableCell align="center">
                    { !item.price_nickname.startsWith(API.PRICE_NICKNAME_NCT) &&
                      !(item.hasOwnProperty("cancel_at_formatted") && item.cancel_at_formatted !== "") &&
                      <Fragment>
                        { item.status === API.SUB_STATUS_TRIALING &&
                          <Fragment>
                            <ActionButton value={item.sub_id} onClick={this.endTrialNow} variant="contained" text="Go Premium" textclassname="txt-bold txt-white"/>
                            <ActionButton value={item.sub_id} component={Link} to={ROUTES.PAYMENT_UPDATE} text="Update My Card"/>
                            <ActionButton value={item.sub_id} component={ Link } to={ROUTES.ACCOUNT} text="Update Billing Details"/>
                            <ActionButton value={item.sub_id} onClick={this.cancelSub} text="Cancel My Trial" textclassname="txt-normal"/>
                          </Fragment>
                        }
                        { item.status === API.SUB_STATUS_ACTIVE &&
                          <Fragment>
                            <ActionButton value={item.sub_id} onClick={(e) => this.updateSub(e, item.sub_item_id, item.price_nickname, item.quantity, item.interval)} text="Update Subscription"/>
                            <ActionButton value={item.sub_id} onClick={(e) => this.discountSub(e, item.customer_id, item.status, item.interval)} text="Enter Your Promotion Code"/>
                          </Fragment>
                        }
                        { item.status === API.SUB_STATUS_PAST_DUE &&
                          <ActionButton value={item.sub_id} target="_blank" rel="noopener" href={item.hosted_invoice_url} text="Pay Invoice"/>
                        }
                        { item.status === API.SUB_STATUS_UNPAID &&
                          <ActionButton value={item.sub_id} target="_blank" rel="noopener" href={ROUTES.SUPPORT} text="Contact Us to Re-Activate Subscription"/>
                        }
                        { item.status !== API.SUB_STATUS_CANCELED && item.status !== API.SUB_STATUS_TRIALING &&
                          <Fragment>
                            <ActionButton value={item.sub_id} component={ Link } to={ROUTES.PAYMENT_UPDATE} text="Update My Card"/>
                            <ActionButton value={item.sub_id} component={ Link } to={ROUTES.ACCOUNT} text="Update Billing Details"/>
                            <ActionButton value={item.sub_id} onClick={this.cancelSub} text="Cancel Subscription" textclassname="txt-normal"/>
                          </Fragment>
                        }
                      </Fragment>
                    }
                    { item.price_nickname.startsWith(API.PRICE_NICKNAME_NCT) && item.status !== API.SUB_STATUS_UNPAID &&
                      !(item.hasOwnProperty("cancel_at_formatted") && item.cancel_at_formatted !== "") &&
                      <Fragment>
                        <ActionButton value={item.sub_id} onClick={(e) => this.activateSubItem(e, item.customer_id, item.sub_item_id)} text="Activate Connector" />
                        <ActionButton value={item.sub_id} onClick={(e) => this.cancelSubItem(e, item.customer_id, item.sub_item_id, item.price_nickname)} text="Cancel Trial" textclassname="txt-normal"/>
                      </Fragment>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="pd-list-div-note">
          <Typography variant="caption" component="span" className="txt-note">
            *Note: It might take a little while whilst we are updating your subscription. Please wait for a moment and refresh the page.
          </Typography>
          <br/>
          <Typography variant="caption" component="span" className="txt-note">
            *Note: If you would like to re-activate your subscription, please pay the latest open invoice&nbsp;
            <MuiLink href={ROUTES.MY_INVOICES} variant="caption" color="inherit" underline="always">
              here
            </MuiLink> or contact us&nbsp;
            <MuiLink href={ROUTES.SUPPORT} target="_blank" rel="noopener" variant="caption" color="inherit" underline="always">
              here
            </MuiLink>.
          </Typography>
          <br/>
          <Typography variant="caption" component="span" className="txt-note">
            **Note: For more tips and tricks, check out our Youtube channel&nbsp;
            <MuiLink href={ROUTES.YOUTUBE_CHANNEL} target="_blank" rel="noopener" variant="caption" color="inherit" underline="always">
              Data Studio Tips & Tricks
            </MuiLink>.
          </Typography>
          <br/>
          {error === true && <Typography variant="body1" component="span" color="error"><br/>{message}</Typography>}
        </div>
      </div>
    );
  }
}

export default withRouter(SubList);
