import React, { Component, Fragment } from 'react';
import { AuthUserContext, withAuthorization } from '../Session';
import SubList from '../SubList';
import { PurchaseButton } from '../Purchase';
import { FreeTrialButton } from '../FreeTrial';
import * as API from '../../constants/api';
import { Grid, Typography } from '@mui/material';
import '../../styles/main.css';


class MySubPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPurchaseButton: false,
      showFreeTrialButton: false,
      message: '',
    }
    this.onShowPurchaseButton = this.onShowPurchaseButton.bind(this);
  }

  onShowPurchaseButton(showPurchaseButton, showFreeTrialButton) {
    if ( showPurchaseButton === true ) {
      if ( showFreeTrialButton === true && API.FREE_TRIAL === API.FREE_TRIAL_ON ) {
        this.setState({
          showPurchaseButton: true,
          showFreeTrialButton: true,
          message: `You currently have no subscription. Please click one of the buttons below and get started today.`,
        });
      } else {
        this.setState({
          showPurchaseButton: true,
          showFreeTrialButton: false,
          message: "You currently have no subscription. Please click the button below and get started today.",
        });
      }
    } else {
      this.setState({ showPurchaseButton: false, showFreeTrialButton: false });
    }
  }

  render () {
    const { showPurchaseButton, showFreeTrialButton, message } = this.state;
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <Grid container justifyContent="center">
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <h1 className="grid-row one lg-ft">My Subscription</h1>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className="grid-row two">
                <SubList authUser={authUser} onShowPurchaseButton={this.onShowPurchaseButton}/>
                { showPurchaseButton === true &&
                  <Fragment>
                    <div className="ck-fm-div">
                      <Typography variant="body1" component="span" color="error"><br/>{message}</Typography>
                    </div>
                    { showFreeTrialButton === true &&
                      <Fragment>
                        <FreeTrialButton />
                        - or -
                      </Fragment>
                    }
                    <PurchaseButton />
                  </Fragment>
                }
              </div>
            </Grid>
          </Grid>
        )}
      </AuthUserContext.Consumer>
    )
  }
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(MySubPage);
