import React, { Component } from 'react';
import DisplayNameChangeForm from './DisplayNameChangeForm';
import BillingDetailsChangeForm from './BillingDetailsChangeForm';
import PasswordChangeForm from '../PasswordChange';
import { AuthUserContext, withAuthorization } from '../Session';
import { Grid, Typography } from '@mui/material';
import '../../styles/main.css';


class AccountPage extends Component {
  render () {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <Grid container justifyContent="center">
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <h1 className="grid-row one">Manage My Account</h1>
            </Grid>
            <Grid item xl={2} lg={1} md={12} sm={12} xs={12}>
            </Grid>
            <Grid item xl={4} lg={5} md={6} sm={12} xs={12}>
              <div className="grid-row two">
                <div className="acct-form">
                  <Typography variant="h6" component="div" className="acct-h6">Update Profile</Typography>
                  <DisplayNameChangeForm />
                </div>
                <div className="acct-form">
                  <Typography variant="h6" component="div" className="acct-h6">Change Password</Typography>
                  <PasswordChangeForm />
                </div>
              </div>
            </Grid>
            <Grid item xl={4} lg={5} md={6} sm={12} xs={12}>
              <div className="grid-row two">
                <BillingDetailsChangeForm />
              </div>
            </Grid>
            <Grid item xl={2} lg={1} md={12} sm={12} xs={12}>
            </Grid>
          </Grid>
        )}
      </AuthUserContext.Consumer>
    )
  }
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(AccountPage);
