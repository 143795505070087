import React, { Component } from 'react';
import { AuthUserContext, withAuthorization } from '../Session';
import ConfigActiveCampaign from './ConfigActiveCampaign';
import ConfigFacebookAds from './ConfigFacebookAds';
import ConfigStripe from './ConfigStripe';
import ConfigFacebookPages from './ConfigFacebookPages';
import * as API from '../../constants/api';
import { Grid } from '@mui/material';
import '../../styles/main.css';


class ConfigPage extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const { connector } = this.props.router.params;
    const connectorId = this.props.router.location.state ? this.props.router.location.state.connector_id : '';
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <Grid container justifyContent="center">
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <h1 className="grid-row one">Configure My Connector</h1>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className="grid-row two">
                { connector === API.ACTIVECAMPAIGN &&
                  <ConfigActiveCampaign authUserEmail={authUser.email} connectorId={connectorId}/>
                }
                { connector === API.FACEBOOK_ADS &&
                  <ConfigFacebookAds authUserEmail={authUser.email} connectorId={connectorId}/>
                }
                { connector === API.STRIPE &&
                  <ConfigStripe authUserEmail={authUser.email} connectorId={connectorId}/>
                }
                { connector === API.FACEBOOK_PAGES &&
                  <ConfigFacebookPages authUserEmail={authUser.email} connectorId={connectorId}/>
                }
                { !( connector === API.ACTIVECAMPAIGN ||
                  connector === API.FACEBOOK_ADS ||
                  connector === API.STRIPE ||
                  connector === API.FACEBOOK_PAGES ) &&
                  <h2> Please select a Connector to configure </h2>
                }
              </div>
            </Grid>
          </Grid>
        )}
      </AuthUserContext.Consumer>
    )
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(ConfigPage);
