import React, { Component, Fragment } from 'react';
import axios from 'axios';
import * as API from '../../constants/api';
import * as TAXID from '../../constants/taxid';
import { processError } from '../Util';
import { withFirebase } from '../Firebase';
import { Loading } from '../Loading';
import { Button, TextField, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import '../../styles/main.css';

const INITIAL_STATE = {
  showForm: false,
  email: '',
  customerId: '',
  name: '',
  nameOld: '',
  addressLine1: '',
  addressLine1Old: '',
  addressLine2: '',
  addressLine2Old: '',
  addressCity: '',
  addressCityOld: '',
  addressState: '',
  addressStateOld: '',
  addressCountry: '',
  addressCountryOld: '',
  addressPostalCode: '',
  addressPostalCodeOld: '',
  phone: '',
  phoneOld: '',
  taxIdType: '',
  taxIdValue: '',
  taxIdValueOld: '',
  taxIdExample: '',
  error: false,
  message: '',
  errorTaxId: false,
  messageTaxId: '',
  loading: true,
};

class BillingDetailsChangeForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  async componentDidMount() {
    if ( this.props.firebase.auth.currentUser.email ) {
      const { email } = this.props.firebase.auth.currentUser;
      await axios
      .post(`${API.FMI_API}/customer/billingdetails/`, {
        email,
      }, API.HEADERS)
      .then( response => {
        const { data } = response;
        if ( data.message !== API.MESSAGE_SUCCESS || !data.hasOwnProperty("email") ) {
          this.setState({
            error: true,
            message: "Failed to fetch your billing details. Please refresh the page.",
            errorTaxId: false,
            messageTaxId: "Failed to fetch your tax id. Please refresh the page.",
            loading:false,
          });
          return
        }
        if ( data.email !== email ) {
          this.setState({
            error: true,
            message: "Unexpected error. Please refresh the page or contact our help desk.",
            errorTaxId: false,
            messageTaxId: '',
            loading:false,
          });
          return
        }
        if ( data.hasOwnProperty("address") ) {
          this.setState({
            showForm: true,
            email: email || '',
            customerId: data.hasOwnProperty("customer_id")?data.customer_id:"",
            nameOld: data.hasOwnProperty("name")?data.name:"",
            addressLine1Old: data.address.hasOwnProperty("line1")?data.address.line1:"",
            addressLine2Old: data.address.hasOwnProperty("line2")?data.address.line2:"",
            addressCityOld: data.address.hasOwnProperty("city")?data.address.city:"",
            addressStateOld: data.address.hasOwnProperty("state")?data.address.state:"",
            addressCountryOld: data.address.hasOwnProperty("country")?data.address.country:"",
            addressPostalCodeOld: data.address.hasOwnProperty("postal_code")?data.address.postal_code:"",
            phoneOld: data.hasOwnProperty("phone")?data.phone:"",
            taxIdValueOld: data.hasOwnProperty("tax_id_value")?data.tax_id_value:"",
            error: false,
            message: '',
            errorTaxId: false,
            messageTaxId: '',
            loading: false,
          });
        } else {
          this.setState({
            showForm: true,
            email: email || '',
            customerId: data.hasOwnProperty("customer_id")?data.customer_id:"",
            nameOld: data.hasOwnProperty("name")?data.name:"",
            phoneOld: data.hasOwnProperty("phone")?data.phone:"",
            taxIdValueOld: data.hasOwnProperty("tax_id_value")?data.tax_id_value:"",
            error: false,
            message: '',
            errorTaxId: false,
            messageTaxId: '',
            loading: false,
          });
        }
      })
      .catch( error => {
        this.setState({ showForm: false, loading: false });
      });
    } else {
      this.setState({ showForm: false, loading: false });
    }
  }

  onSubmit = async event => {
    event.preventDefault();
    this.setState({ loading:true });
    const { email, customerId, name, addressLine1, addressLine2, addressCity, addressState, addressCountry, addressPostalCode, phone } = this.state;
    await axios
    .post(`${API.FMI_API}/customer/updatebillingdetails/`, {
      email,
      customer_id: customerId,
      name: name.trim(),
      address: {
        line1: addressLine1.trim(),
        line2: addressLine2.trim(),
        city: addressCity.trim(),
        state: addressState.trim(),
        country: addressCountry.trim(),
        postal_code: addressPostalCode.trim(),
      },
      phone: phone.trim(),
    }, API.HEADERS)
    .then( response => {
      const { data } = response;
      if ( data.hasOwnProperty("email") && email === data.email && data.hasOwnProperty("customer_id") && customerId === data.customer_id ) {
        this.setState({
          name: '',
          nameOld: name.trim(),
          addressLine1: '',
          addressLine1Old: addressLine1.trim(),
          addressLine2: '',
          addressLine2Old: addressLine2.trim(),
          addressCity: '',
          addressCityOld: addressCity.trim(),
          addressState: '',
          addressStateOld: addressState.trim(),
          addressCountry: '',
          addressCountryOld: addressCountry.trim(),
          addressPostalCode: '',
          addressPostalCodeOld: addressPostalCode.trim(),
          phone: '',
          phoneOld: phone.trim(),
          error: false,
          message:"Your billing details hava been updated successfully. They will be on the upcoming invoices.",
          loading: false,
        });
      } else {
        this.setState({ error:true, loading:false, message:"Failed to update your billing details. Please refresh the page and try again." });
      }
    })
    .catch( error => {
      const { message } = processError(error);
      this.setState({ error:true, message, loading:false });
    });
  };

  onSubmitTaxId = async event => {
    event.preventDefault();
    this.setState({ loading:true });
    const { email, customerId, taxIdType, taxIdValue } = this.state;
    await axios
    .post(`${API.FMI_API}/customer/updatetaxid/`, {
      email,
      customer_id: customerId,
      tax_id_type: taxIdType.trim(),
      tax_id_value: taxIdValue.trim(),
    }, API.HEADERS)
    .then( response => {
      const { data } = response;
      if ( data.hasOwnProperty("email") && email === data.email && data.hasOwnProperty("customer_id") && customerId === data.customer_id ) {
        this.setState({
          taxIdValue: '',
          taxIdValueOld: taxIdValue.trim(),
          errorTaxId: false,
          messageTaxId:"Your tax id has been updated successfully. It will be on the upcoming invoices.",
          loading: false,
        });
      } else {
        this.setState({ loading:false, errorTaxId:true, messageTaxId:"Failed to update your tax id. Please refresh the page and try again." });
      }
    })
    .catch( error => {
      const { message } = processError(error);
      this.setState({ loading:false, errorTaxId:true, messageTaxId:message });
    });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value.substring(0, 100), error: false, message: '', errorTaxId: false, messageTaxId: '' });
  };

  onChangeTaxIdType = event => {
    const { value } = event.target;
    const valueSplit = value.split(";");
    if ( valueSplit.length === 2 ) {
      this.setState({ taxIdType: valueSplit[0], taxIdExample: valueSplit[1], errorTaxId: false, messageTaxId: '' });
    } else {
      this.setState({ taxIdType: "", taxIdExample: "", errorTaxId: false, messageTaxId: '' });
    }
  };

  render() {
    const {
      showForm, email, error, message, loading,
      name, addressLine1, addressLine2, addressCity, addressState, addressCountry, addressPostalCode, phone,
      nameOld, addressLine1Old, addressLine2Old, addressCityOld, addressStateOld, addressCountryOld, addressPostalCodeOld, phoneOld,
      taxIdType, taxIdValue, taxIdValueOld, taxIdExample, errorTaxId, messageTaxId,
    } = this.state;
    const isInvalid = name.trim() + addressLine1.trim() + addressLine2.trim() + addressCity.trim() + addressState.trim() + addressCountry.trim() + addressPostalCode.trim() + phone.trim() === "";
    const isInvalidTaxId = taxIdType==="" || taxIdValue==="";

    return (
      <Fragment>
        <Loading loading={loading}/>
        { showForm === false &&
        <div className="acct-form">
          <Typography variant="h6" component="div" className="acct-h6">Update Billing Details</Typography>
          <div className="txt-input-btn">
            <Button variant="contained" disabled={true} component="button" color="primary" size="large" type="submit" fullWidth={true}>
              <Typography variant="h6" component="span" className="txt-white">Update</Typography>
            </Button>
          </div>
          <br/>
          <Typography variant="h6" component="div" className="acct-h6">Update Tax ID</Typography>
          <div className="txt-input-btn">
            <Button variant="contained" disabled={true} component="button" color="primary" size="large" type="submit" fullWidth={true}>
              <Typography variant="h6" component="span" className="txt-white">Update</Typography>
            </Button>
          </div>
          <br/>
          <Typography variant="body1" component="span" color="primary">You will be able to update billing details and tax ID after starting a subscription.</Typography>
        </div>
        }
        { showForm === true &&
        <div className="acct-form">
          <Typography variant="h6" component="div" className="acct-h6">Update Billing Details</Typography>
          <form onSubmit={this.onSubmit}>
            <div className="txt-input txt-left">
              <Typography variant="body1" component="div">{email}</Typography>
              <Typography variant="body1" component="div">{nameOld}</Typography>
              <Typography variant="body1" component="div">{`${addressLine1Old} ${addressLine2Old} ${addressCityOld} ${addressStateOld} ${addressCountryOld} ${addressPostalCodeOld}`}</Typography>
              <Typography variant="body1" component="div">{phoneOld}</Typography>
            </div>
            <div className="txt-input">
              <TextField
                label="Full Name or Business Name"
                type="text"
                variant="outlined"
                name="name"
                autoComplete="organization"
                value={name}
                onChange={this.onChange}
                fullWidth={true}
              />
            </div>
            <div className="txt-input">
              <TextField
                label="Address line 1 (e.g., street, PO Box)"
                type="text"
                variant="outlined"
                name="addressLine1"
                autoComplete="address-line1"
                value={addressLine1}
                onChange={this.onChange}
                fullWidth={true}
              />
            </div>
            <div className="txt-input">
              <TextField
                label="Address line 2 (e.g., apartment, suite)"
                type="text"
                variant="outlined"
                name="addressLine2"
                autoComplete="address-line2"
                value={addressLine2}
                onChange={this.onChange}
                fullWidth={true}
              />
            </div>
            <div className="txt-input">
              <TextField
                label="City"
                type="text"
                variant="outlined"
                name="addressCity"
                autoComplete="address-level2"
                value={addressCity}
                onChange={this.onChange}
                fullWidth={true}
              />
            </div>
            <div className="txt-input">
              <TextField
                label="State, county, province, or region"
                type="text"
                variant="outlined"
                name="addressState"
                autoComplete="address-level1"
                value={addressState}
                onChange={this.onChange}
                fullWidth={true}
              />
            </div>
            <div className="txt-input">
              <TextField
                label="Country (e.g., NZ)"
                type="text"
                variant="outlined"
                name="addressCountry"
                value={addressCountry}
                onChange={this.onChange}
                fullWidth={true}
              />
            </div>
            <div className="txt-input">
              <TextField
                label="Postal code"
                type="text"
                variant="outlined"
                name="addressPostalCode"
                autoComplete="postal-code"
                value={addressPostalCode}
                onChange={this.onChange}
                fullWidth={true}
              />
            </div>
            <div className="txt-input">
              <TextField
                label="Phone Number"
                type="text"
                variant="outlined"
                name="phone"
                autoComplete="tel"
                value={phone}
                onChange={this.onChange}
                fullWidth={true}
              />
            </div>
            <div className="txt-input-btn">
              <Button variant="contained" disabled={isInvalid===true} component="button" color="primary" size="large" type="submit" fullWidth={true}>
                <Typography variant="h6" component="span" className="txt-white">Update</Typography>
              </Button>
            </div>
            { error === true ? <Typography variant="body1" component="span" color="error">{message}</Typography>
              : <Typography variant="body1" component="span" color="primary">{message}</Typography>}
          </form>
          <br/>
          <Typography variant="h6" component="div" className="acct-h6">Update Tax ID</Typography>
          <form onSubmit={this.onSubmitTaxId}>
            { taxIdValueOld !== "" &&
              <div className="txt-input txt-left">
                <Typography variant="body1" component="div">Tax ID: {taxIdValueOld}</Typography>
              </div>
            }
            <FormControl className="txt-input" fullWidth={true}>
              <InputLabel>
                <Typography variant="body1" component="span">Select Type</Typography>
              </InputLabel>
              <Select defaultValue="" onChange={this.onChangeTaxIdType}
                label="Select Type">
                <MenuItem value=""></MenuItem>
                { Object.keys(TAXID.TAX_IDS).map( key => (
                  <MenuItem value={`${TAXID.TAX_IDS[key]["t"]};${TAXID.TAX_IDS[key]["e"]}`} key={key}>{TAXID.TAX_IDS[key]["d"]}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <br/>
            <br/>
            <div className="txt-input">
              <TextField
                label={`Tax ID ${taxIdExample && ` (e.g. ${taxIdExample})`}`}
                type="text"
                variant="outlined"
                name="taxIdValue"
                value={taxIdValue}
                placeholder={taxIdExample}
                onChange={this.onChange}
                fullWidth={true}
              />
            </div>
            <div className="txt-input-btn">
              <Button variant="contained" disabled={isInvalidTaxId===true} component="button" color="primary" size="large" type="submit" fullWidth={true}>
                <Typography variant="h6" component="span" className="txt-white">Update</Typography>
              </Button>
            </div>
            { errorTaxId === true ? <Typography variant="body1" component="span" color="error">{messageTaxId}</Typography>
              : <Typography variant="body1" component="span" color="primary">{messageTaxId}</Typography>}
          </form>
        </div>
        }
      </Fragment>
    );
  }
}

export default withFirebase(BillingDetailsChangeForm);
