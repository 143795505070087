import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import SignUpForm from './SignUpForm';
import { SignInLink } from '../SignIn';
import * as API from '../../constants/api';
import * as ROUTES from '../../constants/routes';
import { Button, Typography, Grid, Box } from '@mui/material';
import '../../styles/main.css';

class SignUpPage extends Component {
  render () {
    return (
      <Grid container justifyContent="center">
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <h1 className="grid-row one">Welcome to Find My Insight</h1>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className="grid-row two">
            <div className="txt-input">
              <Typography variant="body1" component="span">
                { API.FREE_TRIAL === API.FREE_TRIAL_ON ?
                  <Fragment>
                    Start your&nbsp;
                    <Typography variant="inherit" component="span" color="primary">
                      <Box fontWeight="fontWeightBold" component="span">
                        {API.FREE_TRIAL_DAYS} day FREE trial
                      </Box>
                    </Typography>
                    &nbsp;today
                  </Fragment>
                  : "Sign up to gain access to our Google Data Studio Connectors"
                }
              </Typography>
            </div>
            <SignUpForm />
            <div className="txt-input">
              <SignInLink />
            </div>
          </div>
        </Grid>
      </Grid>
    )
  }
}

const SignUpLink = () => (
  <span className="txt-vt">
    <Typography variant="body1" component="span">
      Don't have an account?
    </Typography>
    <Button component={ Link } to={ROUTES.SIGN_UP} variant="text" color="inherit">
      Sign Up
    </Button>
  </span>
);

export default SignUpPage;

export { SignUpLink };
