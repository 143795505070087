import React, { Component } from 'react';
import * as API from '../../constants/api';
import * as ROUTES from '../../constants/routes';
import * as VIDEOS from '../../constants/videos';
import { Grid, Typography, List, ListItem, ListItemText } from '@mui/material';
import '../../styles/main.css';

function ListItemLink(props) {
  return <ListItem button component="a" target="_blank" rel="noopener" variant="subtitle1" color="primary" {...props} />;
}

class Videos extends Component {
  render () {
    return (
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <div className="grid-row four">
          <Typography variant="h6" component="h6">
            Demo Videos
          </Typography>
          <div className="video-left">
            <List dense component="nav" aria-label="secondary mailbox folders">
              <ListItemLink href={ROUTES.YOUTUBE_CHANNEL}>
                <ListItemText primary="Youtube Channel" />
              </ListItemLink>
              <ListItemLink href={VIDEOS.VIDEO_ACTIVECAMPAIGN_CONFIG}>
                <ListItemText primary={`How to configure ${API.ACTIVECAMPAIGN_FORMATTED} Connector`} />
              </ListItemLink>
              <ListItemLink href={VIDEOS.VIDEO_ACTIVECAMPAIGN_PARAMETERS}>
                <ListItemText primary={`How to use parameters in ${API.ACTIVECAMPAIGN_FORMATTED} Connector`} />
              </ListItemLink>
              <ListItemLink href={VIDEOS.VIDEO_ACTIVECAMPAIGN_TOTAL_COUNT}>
                <ListItemText primary={`How to get total count in ${API.ACTIVECAMPAIGN_FORMATTED} Connector`} />
              </ListItemLink>
              <ListItemLink href={VIDEOS.VIDEO_ACTIVECAMPAIGN_CONTACT_CUSTOM_FIELDS}>
                <ListItemText primary={`How to use Contact Custom Fields in ${API.ACTIVECAMPAIGN_FORMATTED} Connector`} />
              </ListItemLink>
              <ListItemLink href={VIDEOS.VIDEO_MULTIPLE_ACCOUNTS}>
                <ListItemText primary="How to add multiple accounts" />
              </ListItemLink>
              <ListItemLink href={VIDEOS.VIDEO_DATETIME_FIELD}>
                <ListItemText primary="How to use Datetime field in Data Studio" />
              </ListItemLink>
            </List>
          </div>
        </div>
      </Grid>
    )
  }
};

export default Videos;
