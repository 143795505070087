import React, { Component } from 'react';
import { PricingTable } from '../Pricing';
import * as API from '../../constants/api';
import { calculateTotalAmount, isValidQuantity } from '../Util';
import { Button, Typography, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import '../../styles/main.css';


class OrderForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: false,
      message: '',
      interval: '',
      quantity: 0,
      totalAmount: 0,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeInterval = this.onChangeInterval.bind(this);
    this.onChangeQuantity = this.onChangeQuantity.bind(this);
  };

  componentDidMount() {
    const email = this.props.authUserEmail;
    this.setState({ email });
  }

  onSubmit(event) {
    event.preventDefault();
    const { interval, quantity, totalAmount } = this.state;
    if ( interval !== "" && quantity > 0 && totalAmount > 0 ) {
      this.props.onSelected( interval, quantity, totalAmount );
    } else {
      this.setState({ error:true, message:"Please select subscription type and quantity of connectors..." });
    }
  }

  onChangeInterval(event) {
    const interval = event.target.value;
    if ( interval === API.SUB_INTERVAL_YEAR || interval === API.SUB_INTERVAL_MONTH ) {
      this.setState({ interval });
      const { quantity } = this.state;
      if ( quantity > 0) {
        this.setState({ totalAmount: calculateTotalAmount(interval, quantity) });
      }
    } else {
      this.setState({ interval: '', totalAmount:0, error:false });
    }
  }

  onChangeQuantity(event) {
    const quantity = Number(event.target.value);
    if ( isValidQuantity(quantity) ) {
      this.setState({ quantity });
      const { interval } = this.state;
      if ( interval === API.SUB_INTERVAL_YEAR || interval === API.SUB_INTERVAL_MONTH ) {
        this.setState({ totalAmount: calculateTotalAmount(interval, quantity) });
      }
    } else {
      this.setState({ quantity: 0, totalAmount:0, error:false });
    }
  }

  render() {
    const { interval, quantity, totalAmount, error, message } = this.state;
    const valid = interval !== '' && isValidQuantity(quantity);
    return (
      <div className="od-fm-div">
        <span className="od-fm-control">
          <FormControl>
            <InputLabel>
              <Typography variant="body1" component="span">Subscription Type</Typography>
            </InputLabel>
            <Select defaultValue="" onChange={this.onChangeInterval} className="od-fm-select"
              label="Subscription Type">
              <MenuItem value={API.SUB_INTERVAL_MONTH}>{API.SUB_INTERVAL_MONTH_TEXT}</MenuItem>
              <MenuItem value={API.SUB_INTERVAL_YEAR}>{API.SUB_INTERVAL_YEAR_TEXT}</MenuItem>
            </Select>
          </FormControl>
        </span>
        <span className="od-fm-control">
          <FormControl>
            <TextField
              id="connector-quantity"
              label="Quantity of Connectors"
              type="number"
              onChange={this.onChangeQuantity}
              value={quantity}
            />
          </FormControl>
        </span>
        <Typography variant="body1" component="div" className="or-fm-total">Total Amount: ${totalAmount} </Typography>
        <div className="od-btn-div">
          { valid === true ? (
            <Button onClick={this.onSubmit} variant="contained" style={{backgroundColor:'#E43B2C'}} size="large" fullWidth={true}>
              <span className="pch-btn-txt">Checkout</span>
            </Button>
          ) : (
            <Button onClick={this.onSubmit} variant="contained" style={{backgroundColor:'#e0e0e0'}} size="large" fullWidth={true} disabled>
              <span className="pch-btn-txt">Checkout</span>
            </Button>
          )}
        </div>
        {error === true && <Typography variant="body1" component="span" color="error">{message}</Typography>}
        <br/>
        <PricingTable />
      </div>
    )
  }
};

export default OrderForm;
