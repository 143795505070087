import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navigation from '../Navigation';
import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import MySubPage from '../MySub';
import MyProductsPage from '../MyProducts';
import MyInvoicesPage from '../MyInvoices';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import PurchasePage from '../Purchase';
import FreeTrialPage from '../FreeTrial';
import CancelPage from '../Cancel';
import ConfigPage from '../Config';
import PricingPage from '../Pricing';
import SubUpdatePage from '../SubUpdate';
import PaymentUpdatePage from '../PaymentUpdate';
import Footer from '../Footer';
import * as PATHS from '../../constants/routes';
import { withAuthentication } from '../Session';
import '../../styles/main.css';

const App = () => (
  <div id="app">
    <BrowserRouter>
      <div id="router">
        <Navigation />
        <div id="content">
          <Routes>
            <Route path={PATHS.LANDING} element={<LandingPage />} />
            <Route path={PATHS.SIGN_UP} element={<SignUpPage />} />
            <Route path={PATHS.SIGN_IN} element={<SignInPage />} />
            <Route path={PATHS.PASSWORD_FORGET} element={<PasswordForgetPage />} />
            <Route path={PATHS.MY_SUB} element={<MySubPage />} />
            <Route path={PATHS.MY_PRODUCTS} element={<MyProductsPage />} />
            <Route path={PATHS.MY_INVOICES} element={<MyInvoicesPage />} />
            <Route path={PATHS.ACCOUNT} element={<AccountPage />} />
            <Route path={PATHS.ADMIN} element={<AdminPage />} />
            <Route path={PATHS.PURCHASE} element={<PurchasePage />} />
            <Route path={PATHS.FREE_TRIAL} element={<FreeTrialPage />} />
            <Route path={PATHS.CANCEL} element={<CancelPage />} />
            <Route path={`${PATHS.CONFIG}/:connector`} element={<ConfigPage />} />
            <Route path={PATHS.PRICING} element={<PricingPage />} />
            <Route path={PATHS.SUB_UPDATE} element={<SubUpdatePage />} />
            <Route path={PATHS.PAYMENT_UPDATE} element={<PaymentUpdatePage />} />
            <Route path="*" element={<h1>Page not found</h1>} />
          </Routes>
        </div>
        <Footer/>
      </div>
    </BrowserRouter>
  </div>
);

export default withAuthentication(App);
