import React from "react";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";
import InjectedPaymentUpdateForm from './InjectedPaymentUpdateForm';
import * as API from '../../constants/api';
import '../../styles/main.css';

const stripePromise = loadStripe(API.STRIPE_KEY);

const PaymentUpdate = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <InjectedPaymentUpdateForm  {...props}/>
    </Elements>
  );
}

export default PaymentUpdate;
