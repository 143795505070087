import React, { Component, Fragment } from "react";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";
import InjectedCheckoutForm from './InjectedCheckoutForm';
import * as API from '../../constants/api';
import { Typography } from '@mui/material';
import '../../styles/main.css';

const INITIAL_STATE = {
  email: '',
  interval: '',
  quantity: 0,
  totalAmount: 0,
  error: false,
  message: '',
};

const stripePromise = loadStripe(API.STRIPE_KEY);

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount(){
    if ( this.props.authUserEmail && this.props.authUserEmail !== '' ) {
      if ( this.props.interval && this.props.interval !== '' &&
        this.props.quantity && this.props.quantity > 0 &&
        this.props.totalAmount && this.props.totalAmount > 0 ) {
        this.setState({
          email: this.props.authUserEmail,
          interval: this.props.interval,
          quantity: this.props.quantity,
          totalAmount: this.props.totalAmount,
        });
      }
    }
  }

  render() {
    const { email, interval, quantity, totalAmount, error, message } = this.state;
    return (
      <Fragment>
        <Elements stripe={stripePromise}>
          <InjectedCheckoutForm
            authUserEmail={email}
            interval={interval}
            quantity={quantity}
            totalAmount={totalAmount}
          />
        </Elements>
        {error === true && <Typography variant="body1" component="span" color="error">{message}</Typography>}
      </Fragment>
    );
  }
}

export default Checkout;
