import React, { Component } from 'react';
import { AuthUserContext, withAuthorization } from '../Session';
import PaymentUpdate from './PaymentUpdate';
import { Grid } from '@mui/material';
import '../../styles/main.css';


class PaymentUpdatePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flagReplaceOrUpdate: null,
    };
  }

  render() {
    const { flagReplaceOrUpdate } = this.state;
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <Grid container justifyContent="center">
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <h1 className="grid-row one lg-ft">Update My Card</h1>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className="ck-fm-div">
                <PaymentUpdate authUserEmail={authUser.email} flagReplaceOrUpdate={flagReplaceOrUpdate}/>
              </div>
            </Grid>
          </Grid>
        )}
      </AuthUserContext.Consumer>
    )
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(PaymentUpdatePage);
