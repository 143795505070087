import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';
import { AppBar, Tabs, Tab, Link as MuiLink } from '@mui/material';
import '../../styles/main.css';
import navBarLogo from'../../logo/FMI_600_150px.png';

class Navigation extends Component {
  render() {
    return (
      <Fragment>
        <AuthUserContext.Consumer>
          { authUser => (
            authUser ? <NavigationAuth /> : <NavigationNonAuth />
          )}
        </AuthUserContext.Consumer>
      </Fragment>
    )
  }
}

class NavigationAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 1,
    };
  }
  onChange = (event, value) => {
    this.setState({ value });
  }
  render() {
    const { value } = this.state;
    return (
      <AppBar position="static" color="inherit" elevation={0}>
        <Tabs
          value={value}
          indicatorColor="primary"
          centered
          onChange={this.onChange}
        >
          <Tab label="" icon={<MainSiteIcon/>} />
          <Tab label={<span className="navbar-txt">My Subscription</span>} to={ROUTES.MY_SUB} component={Link} />
          <Tab label={<span className="navbar-txt">My Connectors</span>} to={ROUTES.MY_PRODUCTS} component={Link} />
          <Tab label={<span className="navbar-txt">My Account</span>} to={ROUTES.ACCOUNT} component={Link} />
          <Tab label={<span className="navbar-txt">My Invoices</span>} to={ROUTES.MY_INVOICES} component={Link} />
          <Tab label={<span className="navbar-txt">Pricing</span>} to={ROUTES.PRICING} component={Link} />
          <Tab label={<SupportLink/>} />
          <Tab label={<YoutubeLink/>} />
          <Tab label={<SignOutButton />} />
        </Tabs>
      </AppBar>
    )
  }
}

class NavigationNonAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 1,
    };
  }
  onChange = (event, value) => {
    this.setState({ value });
  }
  render() {
    const { value } = this.state;
    return (
      <AppBar position="static" color="inherit" elevation={0}>
        <Tabs
          value={value}
          indicatorColor="primary"
          centered
          onChange={this.onChange}
        >
          <Tab label="" icon={<MainSiteIcon/>} />
          <Tab label={<span className="navbar-txt">Pricing</span>} to={ROUTES.PRICING} component={Link} />
          <Tab label={<SupportLink/>} />
          <Tab label={<YoutubeLink/>} />
          <Tab label={<span className="navbar-txt">Sign In</span>} to={ROUTES.SIGN_IN} component={Link} />
        </Tabs>
      </AppBar>
    )
  }
}

function MainSiteIcon(props) {
  return (
    <MuiLink target="_blank" rel="noopener" href={ROUTES.MAIN_SITE} variant="body2" color="inherit" underline="none" className="navbar-logo-link">
      <img src={navBarLogo} alt="logo" className="navbar-logo" />
    </MuiLink>
  );
}

function SupportLink(props) {
  return (
    <MuiLink target="_blank" rel="noopener" href={ROUTES.SUPPORT} variant="body2" color="primary" underline="none" className="navbar-logo-link">
      Support
    </MuiLink>
  );
}

function YoutubeLink(props) {
  return (
    <MuiLink target="_blank" rel="noopener" href={ROUTES.YOUTUBE_CHANNEL} variant="body2" color="primary" underline="none" className="navbar-logo-link">
      Youtube
    </MuiLink>
  );
}

export default Navigation;
