import React, { Component } from 'react';
import axios from 'axios';
import * as API from '../../constants/api';
import * as ROUTES from '../../constants/routes';
import { formatTime, processError, ActionButton } from '../Util';
import { Loading } from '../Loading';
import { Paper, TableContainer, TablePagination, Table, TableCell, TableRow, TableHead, TableBody, Typography, Link as MuiLink } from '@mui/material';
import '../../styles/main.css';


const INITIAL_STATE = {
  email: '',
  invoiceList: [],
  page: 0,
  rowsPerPage: 10,
  loading: false,
  error: false,
  message: '',
};

class InvoiceList extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    const email = this.props.authUserEmail;
    this.setState({ email, loading:true });
    this.loadInvoiceList(email);
  }

  loadInvoiceList = async (email) => {
    await axios
    .post(`${API.FMI_API}/invoice/list/`, {
      email,
    }, API.HEADERS)
    .then( response => {
      if ( response.data.message !== API.MESSAGE_SUCCESS || !response.data.invoices ) {
        this.setState({ error:false, loading:false, message: "You have no invoice" });
        return
      }
      const invoiceList = this.formatList(response.data.invoices);
      this.setState({ invoiceList, loading:false });
    })
    .catch( error => {
      const { message } = processError(error);
      this.setState({ error:true, message, loading:false });
    });
  }

  formatList = (list) => {
    list.forEach( item => {
      item.created_at_formatted = formatTime(item.created_at);
      item.status_formatted = item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase().replace("_", " ");
      item.amount_due_formatted = item.amount_due / 100;
      item.amount_paid_formatted = item.amount_paid / 100;
      item.amount_remaining_formatted = item.amount_remaining / 100;
      item.billing_reason_formatted = item.billing_reason.charAt(0).toUpperCase() + item.billing_reason.slice(1).toLowerCase().replace("_", " ");
      item.period_start_formatted = formatTime(item.period_start);
      item.period_end_formatted = formatTime(item.period_end);
    });
    return list
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page:0, rowsPerPage:parseInt(event.target.value, 10) });
  };

  render() {
    const { invoiceList, page, rowsPerPage, error, message, loading } = this.state;
    return (
      <div className="pd-list-div">
        <Loading loading={loading}/>
        <Typography variant="h6" component="div">My Invoices</Typography>
        <TableContainer component={Paper} elevation={1}>
          <Table className="" aria-label="invoice table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Amount*</TableCell>
                <TableCell align="left">Amount Paid*</TableCell>
                <TableCell align="left">Amount Remaining*</TableCell>
                <TableCell align="left">Reason</TableCell>
                <TableCell align="left">Period</TableCell>
                <TableCell align="center">Actions**</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => (
                <TableRow key={item.invoice_id}>
                  <TableCell component="th" scope="row">{item.created_at_formatted}</TableCell>
                  <TableCell align="left">{item.status_formatted}</TableCell>
                  <TableCell align="left">{item.amount_due_formatted}</TableCell>
                  <TableCell align="left">{item.amount_paid_formatted}</TableCell>
                  <TableCell align="left">{item.amount_remaining_formatted}</TableCell>
                  <TableCell align="left">{item.billing_reason_formatted}</TableCell>
                  <TableCell align="left">{item.period_start_formatted} - {item.period_end_formatted}</TableCell>
                  <TableCell align="center">
                    { item.status === API.INVOICE_STATUS_PAID &&
                      <ActionButton value={item.invoice_id} target="_blank" rel="noopener" href={item.hosted_invoice_url} text="Download"/>
                    }
                    { item.status === API.INVOICE_STATUS_OPEN &&
                      <ActionButton value={item.invoice_id} target="_blank" rel="noopener" href={item.hosted_invoice_url} text="Pay"/>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={invoiceList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
        <div className="pd-list-div-note">
          <Typography variant="caption" component="span" className="txt-note">
            *Note: in USD.
          </Typography>
          <br/>
          <Typography variant="caption" component="span" className="txt-note">
            **Note: Draft invoices will be finalized and charged one hour after new billing period begins if your subscription is active but won't be finalized if your subscription is past due or unpaid.
          </Typography>
          <br/>
          <Typography variant="caption" component="span" className="txt-note">
            ***Note: If you couldn't find your invoices, please raise a ticket&nbsp;
            <MuiLink href={ROUTES.SUPPORT} target="_blank" rel="noopener" variant="caption" color="inherit" underline="always">
              here
            </MuiLink>.
          </Typography>
          <br/>
          {error === true && <Typography variant="body1" component="span" color="error">{message}</Typography>}
        </div>
      </div>
    );
  }
}

export default InvoiceList;
