import React, { Component } from 'react';
import { AuthUserContext, withAuthorization } from '../Session';
import ProductList from '../ProductList';
import AddProductForm from '../AddProductForm';
import Videos from '../Videos';
import { Grid } from '@mui/material';
import '../../styles/main.css';


class MyProductsPage extends Component {
  render () {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <Grid container justifyContent="center">
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <h1 className="grid-row one lg-ft">My Connectors</h1>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className="grid-row two">
                <ProductList authUserEmail={authUser.email} />
                <AddProductForm authUserEmail={authUser.email} />
              </div>
            </Grid>
            <Videos/>
          </Grid>
        )}
      </AuthUserContext.Consumer>
    )
  }
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(MyProductsPage);
