import { createTheme } from '@mui/material/styles';

// Blue, green
const THEME = createTheme({
  palette: {
    primary: {
      main: '#00abfb',
    },
    secondary: {
      main: '#bfd730',
    },
    error: {
      main: '#e4262c',
    },
  },
  typography: {
    fontFamily: "'Montserrat'",
    button: {
      textTransform: 'none'
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
  overrides: {
    MuiDialogContent: {
      root: {
        'padding-bottom': '24px',
      }
    },
    MuiTab: {
      root: {
        '@media (min-width: 600px)': {
          minWidth: '100px',
        }
      }
    }
  },
});

export default THEME;
