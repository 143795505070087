import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes';
import { Link, Typography } from '@mui/material';
import '../../styles/main.css';

class Footer extends Component {
  render() {
    return (
      <div id="footer">
        <Typography variant="body2" component="span">
          &copy; Find My Insight. All Rights Reserved. | &nbsp;
          <Link target="_blank" rel="noopener" href={ROUTES.TERMS_AND_CONDITIONS} variant="body2" color="inherit">
            Terms of Service
          </Link>
          &nbsp; | &nbsp;
          <Link target="_blank" rel="noopener" href={ROUTES.PRIVACY_POLICY} variant="body2" color="inherit">
            Privacy Policy
          </Link>
          &nbsp; | &nbsp;
          <Link target="_blank" rel="noopener" href={ROUTES.SUPPORT} variant="body2" color="inherit">
            Support
          </Link>
          &nbsp; | &nbsp;
          <Link target="_blank" rel="noopener" href={ROUTES.YOUTUBE_CHANNEL} variant="body2" color="inherit">
            Youtube
          </Link>
          &nbsp; | &nbsp;
          <Link target="_blank" rel="noopener" href={ROUTES.MAIN_SITE} variant="body2" color="inherit">
            Main Site
          </Link>
        </Typography>
      </div>
    )
  }
}

export default Footer;
