import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import axios from 'axios';
import * as API from '../../constants/api';
import { processError, DialogError, DialogSuccess } from '../Util';
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../../styles/main.css';


const INITIAL_STATE = {
  email: '',
  customerId: '',
  id: '',
  subItemId: '',
  nickname: '',
  confirmed: false,
  error: false,
  message: '',
  loading: false,
};

class CancelSubItemDialog extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  static propTypes = {
    open: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    customerId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    subItemId: PropTypes.string.isRequired,
    nickname: PropTypes.string.isRequired,
  };

  componentDidUpdate(prevProps) {
    if ( this.props.open !== prevProps.open && this.props.open === true ) {
      if ( this.props.email !== "" && this.props.customerId !== "" && this.props.id !== "" && this.props.subItemId !== "" && this.props.nickname !== "" ) {
        this.setState({
          email: this.props.email,
          customerId: this.props.customerId,
          id: this.props.id,
          subItemId: this.props.subItemId,
          nickname: this.props.nickname,
          confirmed: false,
          message: '',
          error: false,
          loading: false,
        });
      } else {
        this.props.onClose(false);
      }
    }
  }

  handleClose = () => {
    this.setState({
      confirmed: false,
    });
    this.props.onClose(true);
  };

  handleCancel = () => {
    this.setState({
      confirmed: false,
    });
    this.props.onClose(false);
  };

  handleCancelSubItem = async () => {
    this.setState({ loading:true });
    const { email, customerId, id, subItemId } = this.state;
    if ( email !== "" && id !== "" ) {
      await axios
      .post(`${API.FMI_API}/sub/cancelitem/`, {
        email,
        customer_id: customerId,
        sub_id: id,
        sub_item_id: subItemId,
      }, API.HEADERS)
      .then( response => {
        this.setState({ confirmed:true, error:false, message:"", loading:false });
      })
      .catch( error => {
        const { message } = processError(error);
        this.setState({ error:true, message, loading:false });
      });
    }
  };

  renderQuestion() {
    const { loading, nickname } = this.state;
    return (
      <Fragment>
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>
              { nickname.startsWith(API.PRICE_NICKNAME_NCT) ?
                "End your trial"
                : "Cancel item"
              }
            </Grid>
            <Grid item>
              <IconButton onClick={this.handleCancel} size="small">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          { nickname.startsWith(API.PRICE_NICKNAME_NCT) ?
            `Would you like to cancel your ${API.PRICE_NICKNAME_NCT}?`
            : "Would you like to cancel this item?"
          }
          <br/>
          This action <span className="txt-error txt-bold">CANNOT</span> be undone.
        </DialogContent>
        <DialogActions>
          { loading === true ?
            <CircularProgress />
            :
            <Grid container justifyContent="space-between">
              <Grid item>
                <span className="dialog-btn">
                  <Button onClick={this.handleCancelSubItem} color="primary">
                    Yes, I'd like to cancel
                  </Button>
                </span>
              </Grid>
              <Grid item>
                <span className="dialog-btn">
                  <Button autoFocus onClick={this.handleCancel} color="primary" variant="contained">
                    <span className={"txt-white txt-bold"}>No</span>
                  </Button>
                </span>
              </Grid>
            </Grid>
          }
        </DialogActions>
      </Fragment>
    )
  }

  render() {
    const { confirmed, nickname, error, message } = this.state;
    return (
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        aria-labelledby="confirm-cancel-subitem"
        open={this.props.open}
        className="sb-dialog"
      >
        { error === false && confirmed === false && this.renderQuestion() }
        { error === false && confirmed === true &&
          <DialogSuccess
            onClick={this.handleClose}
            title={ nickname.startsWith(API.PRICE_NICKNAME_NCT) ? "Your trial has finished." : "The item has been cancelled"}
            content={"Please wait while we update your subscription."}
          />
        }
        { error === true && <DialogError message={message} onClick={this.handleClose}/> }
      </Dialog>
    );
  }
}

export default CancelSubItemDialog;
