export const TAX_IDS = {
  // { type description example }
  0: {t: "au_abn", d: "Australia-Australian Business Number (AU ABN)", e: "12345678912"},
  1: {t: "au_arn", d: "Australia-Australian Taxation Office Reference Number", e: "123456789123"},
  2: {t: "eu_vat", d: "Austria-European VAT number", e: "ATU12345678"},
  3: {t: "eu_vat", d: "Belgium-European VAT number", e: "BE0123456789"},
  4: {t: "br_cnpj", d: "Brazil-Brazilian CNPJ number", e: "01.234.456/5432-10"},
  5: {t: "br_cpf", d: "Brazil-Brazilian CPF number", e: "123.456.789-87"},
  6: {t: "eu_vat", d: "Bulgaria-European VAT number", e: "BG0123456789"},
  7: {t: "ca_bn", d: "Canada-Canadian BN", e: "123456789"},
  8: {t: "ca_gst_hst", d: "Canada-Canadian GST/HST number", e: "123456789RT0002"},
  9: {t: "ca_pst_bc", d: "Canada-Canadian PST number (British Columbia)", e: "PST-1234-5678"},
  10: {t: "ca_pst_mb", d: "Canada-Canadian PST number (Manitoba)", e: "123456-7"},
  11: {t: "ca_pst_sk", d: "Canada-Canadian PST number (Saskatchewan)", e: "1234567"},
  12: {t: "ca_qst", d: "Canada-Canadian QST number (Québec)", e: "1234567890TQ1234"},
  13: {t: "cl_tin", d: "Chile-Chilean TIN", e: "12.345.678-K"},
  14: {t: "eu_vat", d: "Croatia-European VAT number", e: "HR12345678912"},
  15: {t: "eu_vat", d: "Cyprus-European VAT number", e: "CY12345678Z"},
  16: {t: "eu_vat", d: "Czech Republic-European VAT number", e: "CZ1234567890"},
  17: {t: "eu_vat", d: "Denmark-European VAT number", e: "DK12345678"},
  18: {t: "eu_vat", d: "Estonia-European VAT number", e: "EE123456789"},
  19: {t: "eu_vat", d: "Finland-European VAT number", e: "FI12345678"},
  20: {t: "eu_vat", d: "France-European VAT number", e: "FRAB123456789"},
  21: {t: "eu_vat", d: "Germany-European VAT number", e: "DE123456789"},
  22: {t: "eu_vat", d: "Greece-European VAT number", e: "EL123456789"},
  23: {t: "hk_br", d: "Hong Kong-Hong Kong BR number", e: "12345678"},
  24: {t: "eu_vat", d: "Hungary-European VAT number", e: "HU12345678912"},
  25: {t: "in_gst", d: "India-Indian GST number", e: "12ABCDE3456FGZH"},
  26: {t: "id_npwp", d: "Indonesia-Indonesian NPWP number", e: "12.345.678.9-012.345"},
  27: {t: "eu_vat", d: "Ireland-European VAT number", e: "IE1234567AB"},
  28: {t: "il_vat", d: "Israel-Israel VAT", e: "000012345"},
  29: {t: "eu_vat", d: "Italy-European VAT number", e: "IT12345678912"},
  30: {t: "jp_cn", d: "Japan-Japanese Corporate Number", e: "1234567891234"},
  31: {t: "jp_rn", d: "Japan-Japanese Registered Foreign Businesses Registration Number", e: "12345"},
  32: {t: "kr_brn", d: "Korea-Korean BRN", e: "123-45-67890"},
  33: {t: "eu_vat", d: "Latvia-European VAT number", e: "LV12345678912"},
  34: {t: "li_uid", d: "Liechtenstein-Liechtensteinian UID number", e: "CHE123456789"},
  35: {t: "eu_vat", d: "Lithuania-European VAT number", e: "LT123456789123"},
  36: {t: "eu_vat", d: "Luxembourg-European VAT number", e: "LU12345678"},
  37: {t: "my_frp", d: "Malaysia-Malaysian FRP number", e: "12345678"},
  38: {t: "my_itn", d: "Malaysia-Malaysian ITN", e: "C 1234567890"},
  39: {t: "my_sst", d: "Malaysia-Malaysian SST number", e: "A12-3456-78912345"},
  40: {t: "eu_vat", d: "Malta-European VAT number", e: "MT12345678"},
  41: {t: "mx_rfc", d: "Mexico-Mexican RFC number", e: "ABC010203AB9"},
  42: {t: "eu_vat", d: "Netherlands-European VAT number", e: "NL123456789B12"},
  43: {t: "nz_gst", d: "New Zealand-New Zealand GST number", e: "123456789"},
  44: {t: "no_vat", d: "Norway-Norwegian VAT number", e: "123456789MVA"},
  45: {t: "eu_vat", d: "Poland-European VAT number", e: "PL1234567890"},
  46: {t: "eu_vat", d: "Portugal-European VAT number", e: "PT123456789"},
  47: {t: "eu_vat", d: "Romania-European VAT number", e: "RO1234567891"},
  48: {t: "ru_inn", d: "Russian-Russian INN", e: "1234567891"},
  49: {t: "ru_kpp", d: "Russian-Russian KPP", e: "123456789"},
  50: {t: "sa_vat", d: "Saudi Arabia-Saudi Arabia VAT", e: "123456789012345"},
  51: {t: "sg_gst", d: "Singapore-Singaporean GST", e: "M12345678X"},
  52: {t: "sg_uen", d: "Singapore-Singaporean UEN", e: "123456789F"},
  53: {t: "eu_vat", d: "Slovakia-European VAT number", e: "SK1234567891"},
  54: {t: "eu_vat", d: "Slovenia-European VAT number", e: "SI12345678"},
  55: {t: "za_vat", d: "South Africa-South African VAT number", e: "4123456789"},
  56: {t: "es_cif", d: "Spain-Spanish CIF number", e: "A12345678"},
  57: {t: "eu_vat", d: "Spain-European VAT number", e: "ESA1234567Z"},
  58: {t: "eu_vat", d: "Sweden-European VAT number", e: "SE123456789123"},
  59: {t: "ch_vat", d: "Switzerland-Switzerland VAT number", e: "CHE-123.456.789 MWST"},
  60: {t: "tw_vat", d: "Taiwan-Taiwan VAT", e: "12345678"},
  61: {t: "th_vat", d: "Thailand-Thai VAT", e: "1234567891234"},
  62: {t: "ae_trn", d: "United Arab Emirates-United Arab Emirates TRN", e: "123456789012345"},
  63: {t: "gb_vat", d: "United Kingdom-United Kingdom VAT number", e: "GB123456789"},
  64: {t: "eu_vat", d: "United Kingdom-Northern Ireland VAT number", e: "XI123456789"},
  65: {t: "us_ein", d: "United States-United States EIN", e: "12-3456789"},
};
