import React, { Component } from 'react';
import { Loading } from '../Loading';
import axios from 'axios';
import * as API from '../../constants/api';
import * as ROUTES from '../../constants/routes';
import { formatConnector, processError, withRouter, ActionButton } from '../Util';
import { Alert, Paper, TableContainer, TablePagination, Table, TableCell, TableRow, TableHead, TableBody, Typography, Popper, Link as MuiLink } from '@mui/material';
import CancelConnectorDialog from './CancelConnectorDialog';
import '../../styles/main.css';


const INITIAL_STATE = {
  email: '',
  connector: '',
  connectorId: '',
  connectorList: [],
  connectorIdList: {},
  confList: {},
  buttonConfList: {},
  tokList: {},
  buttonTokList: {},
  error: false,
  message: '',
  open: false,
  anchorEl: null,
  openAlert: false,
  page: 0,
  rowsPerPage: 10,
};

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.loadConnectorList = this.loadConnectorList.bind(this);
    this.showConf = this.showConf.bind(this);
    this.hideConf = this.hideConf.bind(this);
    this.showToken = this.showToken.bind(this);
    this.hideToken = this.hideToken.bind(this);
    this.copyToken = this.copyToken.bind(this);
    this.updateConfig = this.updateConfig.bind(this);
    this.formatList = this.formatList.bind(this);
    this.cancelConnector = this.cancelConnector.bind(this);
    this.onClose = this.onClose.bind(this);
    this.anchorElAlert = React.createRef();
  }

  componentDidMount() {
    const email = this.props.authUserEmail;
    this.setState({ email, loading:true });
    this.loadConnectorList(email);
  }

  async loadConnectorList(email) {
    await axios
    .post(`${API.FMI_API}/connector/list/`, {
      email
    }, API.HEADERS)
    .then( response => {
      if ( response.data.message !== API.MESSAGE_SUCCESS || !response.data.connectors ) {
        this.setState({ error:false, loading:false, message: "" });
        return
      }
      const connectorList = this.formatList(response.data.connectors);
      const connectorIdList = {};
      const buttonConfList = {};
      const buttonTokList = {};
      connectorList.map( item => ( connectorIdList[item.connectorId] = item.connector_type ) );
      connectorList.map( item => ( buttonConfList[item.connectorId] = true ) );
      connectorList.map( item => ( buttonTokList[item.connectorId] = true ) );
      this.setState({ connectorList, connectorIdList, buttonConfList, buttonTokList, loading:false });
    })
    .catch( error => {
      const { message } = processError(error);
      this.setState({ error:true, loading:false, message });
    });
  }

  formatList(list) {
    list.forEach( item => {
      item.connector_formatted = formatConnector(item.connector_type);
      item.connectorId = item.connector_id;
    });
    return list
  }

  async showConf(event) {
    event.preventDefault();
    this.setState({ error:false, message: '', loading:true, anchorEl: null });
    const connectorId = event.currentTarget.value;
    const { email, confList, buttonConfList } = this.state;
    await axios
    .post(`${API.FMI_API}/tok/`, {
      email,
      connector_id: connectorId,
      source: API.SOURCE,
    }, API.HEADERS)
    .then( response => {
      const tok = response.data;
      if ( tok.token !== "" && tok.account_token !== "" ) {
        confList[tok.connector_id] = `${tok.account_name === "" ? "" : `account: ${tok.account_name}, `}key: ${tok.account_token}${tok.expires_at === "" ? "" : `, expires at: ${tok.expires_at.slice(0, 10)}`}`;
        buttonConfList[tok.connector_id] = false;
        this.setState({ confList, buttonConfList, loading:false });
      } else {
        this.setState({ error: true, message: "Please make sure you have completed the configuration.", loading: false });
      }
    })
    .catch( error => {
      const { message } = processError(error);
      this.setState({ error: true, message, loading:false });
    });
  }

  hideConf(event) {
    event.preventDefault();
    const connectorId = event.currentTarget.value;
    const { buttonConfList } = this.state;
    buttonConfList[connectorId] = true;
    this.setState({ buttonConfList, anchorEl: null, error: false, message: "" });
  }

  async showToken(event) {
    event.preventDefault();
    this.setState({ error:false, message: '', loading:true, anchorEl: null });
    const connectorId = event.currentTarget.value;
    const { email, tokList, buttonTokList } = this.state;
    await axios
    .post(`${API.FMI_API}/tok/`, {
      email,
      connector_id: connectorId,
      source: API.SOURCE,
    }, API.HEADERS)
    .then( response => {
      const tok = response.data;
      if ( tok.token !== "" && tok.account_token !== "" ) {
        tokList[tok.connector_id] = tok.token;
        buttonTokList[tok.connector_id] = false;
        this.setState({ tokList, buttonTokList, loading:false, openAlert: true });
      } else {
        this.setState({ error: true, message: "Please make sure you have completed the configuration.", loading: false, openAlert: false });
      }
    })
    .catch( error => {
      const { message } = processError(error);
      this.setState({ error: true, message, loading:false, openAlert: false });
    });
  }

  hideToken(event) {
    event.preventDefault();
    const connectorId = event.currentTarget.value;
    const { buttonTokList } = this.state;
    buttonTokList[connectorId] = true;
    this.setState({ buttonTokList, anchorEl: null, error: false, message: "", openAlert: false });
  }

  copyToken(event) {
    event.preventDefault();
    const connectorId = event.currentTarget.value;
    navigator.clipboard.writeText(this.state.tokList[connectorId]);
    this.setState({ anchorEl: event.currentTarget, error: false, message: "" });
  }

  updateConfig(event) {
    event.preventDefault();
    const connectorId = event.currentTarget.value;
    const connector = this.state.connectorIdList[connectorId];
    this.setState({ ...INITIAL_STATE });
    this.props.router.navigate(`${ROUTES.CONFIG}/${connector}`, { state: {
      connector_id: connectorId,
    }});
  }

  cancelConnector(event) {
    event.preventDefault();
    const connectorId = event.currentTarget.value;
    if (connectorId !== "" ) {
      const connector = this.state.connectorIdList[connectorId];
      this.setState({ open:true, connector, connectorId, anchorEl: null, error: false, message: "" });
    } else {
      this.setState({ error:true, message: "Something wrong. Please refresh the page and try again.", anchorEl: null });
    }
  }

  onClose(reload) {
    this.setState({ open:false, anchorEl: null, error: false, message: "" });
    if (reload===true) {
      this.setState({ loading:true, connectorList:[] });
      this.loadConnectorList(this.state.email);
    }
  }

  onCloseAlert = () => {
    this.setState({ openAlert: false });
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page:0, rowsPerPage:parseInt(event.target.value, 10) });
  };

  render() {
    const {
      connectorList,
      confList,
      buttonConfList,
      tokList,
      buttonTokList,
      error,
      message,
      loading,
      open,
      email,
      connector,
      connectorId,
      anchorEl,
      openAlert,
      page,
      rowsPerPage,
    } = this.state;
    return (
      <div className="pd-list-div">
        <Loading loading={loading}/>
        <CancelConnectorDialog
          keepMounted
          open={open}
          onClose={this.onClose}
          email={email}
          connector={connector}
          connectorId={connectorId}
        />
        <Typography variant="h6" component="div">My Connectors</Typography>
        <TableContainer component={Paper} elevation={1}>
          <Table className="pd-list-tb" aria-label="product table">
            <TableHead>
              <TableRow>
                <TableCell className="col-sm">Connector</TableCell>
                <TableCell align="left" className="col-sm">Nickname</TableCell>
                <TableCell align="left" className="col-sm">Configuration**</TableCell>
                <TableCell align="left" className="col-sm"></TableCell>
                <TableCell align="left" className="col-sm" ref={this.anchorElAlert}>Master Token*</TableCell>
                <TableCell align="left" className="col-sm"></TableCell>
                <TableCell align="center" className="col-sm">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {connectorList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => (
                <TableRow key={item.connectorId}>
                  <TableCell component="th" scope="row">
                    <div className="word-wrap">{item.connector_formatted}</div>
                  </TableCell>
                  <TableCell align="left">
                    <div className="word-wrap">{item.nickname}</div>
                  </TableCell>
                  <TableCell align="left">
                    <ActionButton
                      value={item.connectorId}
                      onClick={this.updateConfig}
                      text="Configure"
                      disabled={!(item.status === API.SUB_STATUS_TRIALING || item.status === API.SUB_STATUS_ACTIVE || item.status === API.SUB_STATUS_PAST_DUE)}
                    />
                    { buttonConfList[item.connectorId] === true ?
                      <ActionButton value={item.connectorId} onClick={this.showConf} text="Show"/>
                      : <ActionButton value={item.connectorId} onClick={this.hideConf} text="Hide"/>
                    }
                  </TableCell>
                  <TableCell align="left">
                    { buttonConfList[item.connectorId] === false && <div className="word-wrap">{confList[item.connectorId]}</div> }
                  </TableCell>
                  <TableCell align="left">
                    { buttonTokList[item.connectorId] === true ?
                      <ActionButton value={item.connectorId} onClick={this.showToken} text="Show"/>
                      : <ActionButton value={item.connectorId} onClick={this.hideToken} text="Hide"/>
                    }
                    { buttonTokList[item.connectorId] === false &&
                      <ActionButton value={item.connectorId} onClick={this.copyToken} text="Copy"/>
                    }
                  </TableCell>
                  <TableCell align="left">
                    { buttonTokList[item.connectorId] === false && <div className="word-wrap">{tokList[item.connectorId]}</div> }
                  </TableCell>
                  <TableCell align="center">
                    <ActionButton
                      value={item.connectorId}
                      onClick={this.cancelConnector}
                      text="Cancel"
                      textclassname="txt-normal"
                      disabled={!(item.status === API.SUB_STATUS_TRIALING || item.status === API.SUB_STATUS_ACTIVE || item.status === API.SUB_STATUS_PAST_DUE)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={connectorList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
        <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement={'bottom'}>
          Copied!
        </Popper>
        <Popper open={openAlert} anchorEl={this.anchorElAlert.current} placement={'right'}>
          <Alert severity="success" onClose={this.onCloseAlert}>
            Tip: Your email is the username used in Data Studio with the master token.
          </Alert>
        </Popper>
        <div className="pd-list-div-note">
          <Typography variant="caption" component="span" className="txt-note">
            *Note: Use your email (the one you signed up here) and the master token to create data source in Data Studio.
          </Typography>
          <br/>
          <Typography variant="caption" component="span" className="txt-note">
            *Note: Keep the master token safe so that your data is safe.
          </Typography>
          <br/>
          <Typography variant="caption" component="span" className="txt-note">
            *Note: Share the email and master token with other people so that they can access the same data and build their own reports in Data Studio.
            It doesn't matter what email that they log into Data Studio with.
          </Typography>
          <br/>
          <Typography variant="caption" component="span" className="txt-note">
            **Note: Facebook requires user to re-authenticate after 60 days. Click&nbsp;
            <MuiLink href={API.FACEBOOK_TOKEN_DOC_URL} target="_blank" rel="noopener" variant="caption" color="inherit" underline="always">
              here
            </MuiLink>&nbsp;for more details.
          </Typography>
          <br/>
          <Typography variant="caption" component="span" className="txt-note">
            *Note: For more tips and tricks, check out our Youtube channel&nbsp;
            <MuiLink href={ROUTES.YOUTUBE_CHANNEL} target="_blank" rel="noopener" variant="caption" color="inherit" underline="always">
              Data Studio Tips & Tricks
            </MuiLink>.
          </Typography>
          <br/>
          {error === true && <Alert severity="error" className="card-error">{message}</Alert>}
        </div>
      </div>
    );
  }
}

export default withRouter(ProductList);
