import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { Loading } from '../Loading';
import * as ROUTES from '../../constants/routes';
import { Button, Grid, TextField, Typography } from '@mui/material';

class PasswordForgetPage extends Component {
  render() {
    return (
      <Grid container justifyContent="center">
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <h1 className="grid-row one">Reset My Password</h1>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className="grid-row two">
            <PasswordForgetForm />
          </div>
        </Grid>
      </Grid>
    )
  }
}

const INITIAL_STATE = {
  email: '',
  error: null,
  message: '',
  loading: false,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    this.setState({ loading:true });
    const { email } = this.state;
    this.props.firebase
    .doPasswordReset(email)
    .then(() => {
      this.setState({ email: '', error:false, message:"You will receive an email to reset the password.", loading:false });
    })
    .catch(error => {
      this.setState({ error:true, message:error.message || "Unknown error", loading:false });
    });
    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value, error:false, message:'' });
  };

  render() {
    const { email, error, message, loading } = this.state;
    const isInvalid = email === '';

    return (
      <Fragment>
        <Loading loading={loading}/>
        <form onSubmit={this.onSubmit}>
          <div className="txt-input">
            <TextField
              required
              label="Email"
              variant="outlined"
              name="email"
              autoComplete="email"
              value={email}
              onChange={this.onChange}
              fullWidth={true}
            />
          </div>
          <div className="txt-input-btn">
            <Button variant="contained" disabled={isInvalid===true} component="button" color="secondary" size="large" type="submit" fullWidth={true}>
              <Typography variant="h6" component="span" className="txt-white">Reset Password</Typography>
            </Button>
          </div>
          { error === true ?
            <Typography variant="body1" component="span" color="error">{message}</Typography>
            : <Typography variant="body1" component="span" color="primary">{message}</Typography>
          }
        </form>
      </Fragment>
    );
  }
}

const PasswordForgetLink = () => (
  <Button component={ Link } to={ROUTES.PASSWORD_FORGET} variant="text" color="inherit">
    Forgot Password?
  </Button>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
