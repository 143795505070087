import React from "react";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import PaymentUpdateForm from './PaymentUpdateForm';

const InjectedPaymentUpdateForm = (props) => {
  return (
    <ElementsConsumer>
      {({elements, stripe}) => (
        <PaymentUpdateForm elements={elements} stripe={stripe} {...props}/>
      )}
    </ElementsConsumer>
  );
};

export default InjectedPaymentUpdateForm;
