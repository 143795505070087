import React, { Component, Fragment } from 'react';
import { withRouter } from '../Util';
import { withFirebase } from '../Firebase';
import { Loading } from '../Loading';
import * as ROUTES from '../../constants/routes';
import * as MEMBERS from '../../constants/members';
import { Button, TextField, Typography } from '@mui/material';
import '../../styles/main.css';


const INITIAL_STATE = {
  email: '',
  password: '',
  error: false,
  message: '',
  loading: false,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    event.preventDefault();
    this.setState({ loading:true });
    const { email, password } = this.state;
    this.props.firebase
    .doSignInWithEmailAndPassword(email, password)
    .then(() => {
      this.setState({ ...INITIAL_STATE });
      this.props.router.navigate(ROUTES.MY_SUB);
    })
    .catch(error => {
      this.setState({ password:'', error:true, message: error.message || "Unknown error", loading:false });
    });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value, error:false, message:'' });
  };

  render() {
    const { email, password, error, message, loading } = this.state;
    const isInvalid = password === '' || email === '' || password.length < MEMBERS.PASSWORD_LENGTH;

    return (
      <Fragment>
        <Loading loading={loading}/>
        <form onSubmit={this.onSubmit}>
          <div className="txt-input">
            <TextField
              required
              label="Email"
              id="email-input"
              variant="outlined"
              name="email"
              autoComplete="email"
              value={email}
              onChange={this.onChange}
              fullWidth={true}
            />
          </div>
          <div className="txt-input">
            <TextField
              required
              id="password-input"
              label="Password"
              type="password"
              variant="outlined"
              name="password"
              autoComplete="current-password"
              value={password}
              onChange={this.onChange}
              fullWidth={true}
            />
          </div>
          <div className="txt-input-btn">
            <Button variant="contained" disabled={isInvalid===true} component="button" color="secondary" size="large" type="submit" fullWidth={true}>
              <Typography variant="h6" component="span" className="txt-white">Sign In</Typography>
            </Button>
          </div>
          {error === true && <Typography variant="body1" component="span" color="error">{message}</Typography>}
        </form>
      </Fragment>
    );
  }
}

const SignInForm = withFirebase(withRouter(SignInFormBase));

export default SignInForm;
