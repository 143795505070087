import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import SignInForm from './SignInForm';
import * as ROUTES from '../../constants/routes';
import { Button, Grid, Typography } from '@mui/material';
import '../../styles/main.css';

class SignInPage extends Component {
  render () {
    return (
      <Grid container justifyContent="center">
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <h1 className="grid-row one">Sign In</h1>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className="grid-row two">
            <SignInForm />
            - or -
            <div className="txt-input">
              <SignUpLink />
            </div>
            - or -
            <div className="txt-input">
              <PasswordForgetLink />
            </div>
          </div>
        </Grid>
      </Grid>
    )
  }
}

const SignInLink = () => (
  <span className="txt-vt">
    <Typography variant="body1" component="span">
      Already have an account?
    </Typography>
    <Button component={ Link } to={ROUTES.SIGN_IN} variant="text" color="inherit">
      Sign In
    </Button>
  </span>
);

export default SignInPage;

export { SignInLink };
