import React, { Component } from "react";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";
import InjectedCheckoutFreeTrialForm from './InjectedCheckoutFreeTrialForm';
import * as API from '../../constants/api';
import '../../styles/main.css';

const stripePromise = loadStripe(API.STRIPE_KEY);

class CheckoutFreeTrial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      freeTrial: '',
    };
  }

  componentDidMount(){
    if ( this.props.authUserEmail && this.props.authUserEmail !== '' &&
      this.props.freeTrial === API.FREE_TRIAL_ON ) {
      this.setState({
        email: this.props.authUserEmail,
        freeTrial: this.props.freeTrial,
      });
    }
  }

  render() {
    const { email, freeTrial } = this.state;
    return (
      <Elements stripe={stripePromise}>
        <InjectedCheckoutFreeTrialForm
          authUserEmail={email}
          freeTrial={freeTrial}
        />
      </Elements>
    );
  }
}

export default CheckoutFreeTrial;
