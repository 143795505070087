import React, { Component, Fragment } from 'react';
import { Loading } from '../Loading';
import qs from 'qs';
import axios from 'axios';
import * as API from '../../constants/api';
import * as ROUTES from '../../constants/routes';
import { withRouter, processError, ConfigSuccess, formatConnector } from '../Util';
import { Alert, Button, Typography, TextField, Link as MuiLink, Card, CardHeader, CardMedia, CardContent } from '@mui/material';
import '../../styles/main.css';
import faHelpImage from'../../logo/fa_ads_account_id.png';


const INITIAL_STATE = {
  email: '',
  connector: '',
  connectorFormatted: '',
  error: false,
  message: '',
  showAuthButton: true,
  showForm: false,
  showSuccess: false,
  loading: false,
  oauthUrl: '',
  stateString: '',
  code: '',
  grantedScopes: '',
  deniedScopes: '',
  adsAccountId: '',
  nickname: '',
};

class ConfigFacebookAds extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    const redirectUrl = `${ROUTES.BASE_URL}${ROUTES.CONFIG}/${API.FACEBOOK_ADS}/`;
    const oauthUrl = `${API.FACEBOOK_OAUTH_URL}?client_id=${API.FACEBOOK_CLIENT_ID}&redirect_uri=${redirectUrl}&state=${this.props.connectorId}&response_type=code,granted_scopes&scope=${API.FACEBOOK_API_SCOPE}`;
    this.setState({
      email: this.props.authUserEmail,
      connector: API.FACEBOOK_ADS,
      connectorId: this.props.connectorId,
      connectorFormatted: formatConnector(API.FACEBOOK_ADS),
      oauthUrl,
      stateString: this.props.connectorId,
    });
    if ( this.props.router.location && this.props.router.location.search ) {
      const urlParam = qs.parse(this.props.router.location.search, { ignoreQueryPrefix: true });
      if ( urlParam && urlParam.hasOwnProperty("code") && urlParam.hasOwnProperty("state") ) {
        const { code, state } = urlParam;
        if ( urlParam.hasOwnProperty("granted_scopes") ) {
          const grantedScopes = decodeURI(urlParam.granted_scopes);
          if ( grantedScopes === "" ) {
            this.setState({
              error: true,
              message: "Please grant access to the required information so that you could proceed to use the Connector.",
            });
          }
          const grantedScopesList = grantedScopes.split(",");
          if ( grantedScopesList.indexOf(API.FACEBOOK_API_SCOPE) === -1 ) {
            this.setState({
              error: true,
              message: "Please grant access to the required information so that you could proceed to use the Connector.",
            });
          } else {
            this.setState({
              code,
              grantedScopes: API.FACEBOOK_API_SCOPE,
              connectorId: state,
              loading: true,
              error: false,
              message:"",
            });
            this.onAuthorize(this.props.authUserEmail, API.FACEBOOK_ADS, state, code, API.FACEBOOK_API_SCOPE);
          }
        }
      }
    }
  }

  onAuthorize = async (email, connectorType, connectorId, code, scope) => {
    await axios
    .post(`${API.FMI_API}/config/update/${API.FACEBOOK_ADS}/`, {
      email,
      connector_type: connectorType,
      connector_id: connectorId,
      code,
      scope,
    }, API.HEADERS)
    .then( response => {
      if ( response.data.message !== API.MESSAGE_SUCCESS ) {
        this.setState({
          error:true,
          message:'Failed to update configuration. Please refresh the page and try again.',
          showAuthButton:true,
          showForm:false,
          showSuccess:false,
          loading:false,
        });
        return
      }
      this.setState({
        error:false,
        message:'Configuration (authorization) updated.',
        showAuthButton:false,
        showForm:true,
        showSuccess:false,
        loading:false,
      });
    })
    .catch( error => {
      const { message } = processError(error);
      this.setState({
        error:true,
        message,
        showAuthButton:true,
        showForm:false,
        showSuccess:false,
        loading:false,
      });
    });
  }

  onSkip = (event) => {
    this.setState({
      error:false,
      message:'',
      showAuthButton:false,
      showForm:true,
      showSuccess:false,
    });
  }

  onSkipEnter = (event) => {
    this.setState({
      error:false,
      message:'',
      showAuthButton:false,
      showForm:false,
      showSuccess:true,
    });
  }

  onChangeAdsAccountId = (event) => {
    const adsAccountId = event.target.value.replace(/ /g, "");
    let message = "";
    if (!/^\d+$/.test(adsAccountId)) {
      message = "Ads Account Id is digits only";
    }
    this.setState({ [event.target.name]: adsAccountId, error:message!=="", message });
  }

  onChangeNickname = (event) => {
    const nickname = event.target.value.replace(/ /g, "");
    let message = "";
    if (!/^[a-zA-Z]+$/.test(nickname)) {
      message = "Please only enter letters for nickname";
    }
    this.setState({ [event.target.name]: nickname, error:message!=="", message });
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.saveConfig();
  }

  saveConfig = async () => {
    await axios
    .post(`${API.FMI_API}/config/update/${API.FACEBOOK_ADS}/`, {
      email: this.state.email,
      connector_type: this.state.connector,
      connector_id: this.state.connectorId,
      nickname: this.state.nickname,
      ads_account_id: this.state.adsAccountId,
    }, API.HEADERS)
    .then( response => {
      if ( response.data.message !== API.MESSAGE_SUCCESS ) {
        this.setState({
          error:true,
          message:'Failed to update configuration. Please refresh the page and try again.',
          showAuthButton:false,
          showForm:true,
          showSuccess:false,
          loading:false,
        });
        return
      }
      this.setState({
        error:false,
        message:'Configuration (ads account id) updated.',
        showAuthButton:false,
        showForm:false,
        showSuccess:true,
        loading:false,
      });
    })
    .catch( error => {
      const { message } = processError(error);
      this.setState({
        error:true,
        message,
        showAuthButton:false,
        showForm:true,
        showSuccess:false,
        loading:false,
      });
    });
  }

  renderAuthButton() {
    const {
      connectorFormatted,
      oauthUrl,
    } = this.state;
    return (
      <Fragment>
        <Typography variant="h6" component="h6">
          Please authorize access to your {connectorFormatted} data for your Connector.
        </Typography>
        <br/>
        <div className="txt-input-btn">
          <Button variant="contained" component="a" color="secondary" size="large" fullWidth={true} href={oauthUrl}>
            <Typography variant="h6" component="span" className="txt-white">Authorize</Typography>
          </Button>
        </div>
        <br/>
        <br/>
        <Typography variant="body1" component="div">
          - or -
        </Typography>
        <br/>
        <Typography variant="body1" component="div">
          Skip if you've authorized before and only want to update the account id.
        </Typography>
        <br/>
        <div className="txt-input-btn-sm">
          <Button variant="contained" component="button" color="secondary" size="small" fullWidth={true} onClick={this.onSkip}>
            <Typography variant="body1" component="span" className="txt-white">Skip</Typography>
          </Button>
        </div>
      </Fragment>
    )
  }

  renderForm() {
    const { connectorFormatted, adsAccountId, nickname } = this.state;
    const isInvalid = adsAccountId === '' || adsAccountId.length <= 7 || !(/^\d+$/.test(adsAccountId)) || nickname === '' || !(/^[a-zA-Z]+$/.test(nickname));
    return (
      <Fragment>
        <Typography variant="h6" component="h6">
          Please enter Ads account id for your {connectorFormatted} Connector.
        </Typography>
        <br/>
        <form onSubmit={this.onSubmit}>
          <div className="txt-input">
            <TextField
              required
              label="Ads Account ID"
              id="ads-account-id-input"
              variant="outlined"
              name="adsAccountId"
              value={adsAccountId}
              onChange={this.onChangeAdsAccountId}
              fullWidth={true}
            />
          </div>
          <div className="txt-input">
            <Typography variant="subtitle2" component="div" align="left">
              Note: <Typography variant="subtitle2" color="error" component="span">digits only</Typography>. Open&nbsp;
              <MuiLink href="https://business.facebook.com/adsmanager/manage" target="_blank" rel="noopener" variant="subtitle2" color="primary">
                ads manager
              </MuiLink>
              &nbsp;to find it as illustrated below.&nbsp;
              <MuiLink href="https://www.facebook.com/business/help/1492627900875762" target="_blank" rel="noopener" variant="subtitle2" color="primary">
                How to
              </MuiLink>&nbsp;find it.
            </Typography>
          </div>
          <br/>
          <div className="txt-input">
            <TextField
              required
              label="Nickname"
              id="nickname-input"
              variant="outlined"
              name="nickname"
              value={nickname}
              onChange={this.onChangeNickname}
              fullWidth={true}
            />
          </div>
          <div className="txt-input">
            <Typography variant="subtitle2" component="div" align="left">
              Note: <Typography variant="subtitle2" color="error" component="span">letters only</Typography>, useful when you have more than one Facebook Ads account.
            </Typography>
          </div>
          <div className="txt-input-btn">
            <Button variant="contained" disabled={isInvalid===true} component="button" color="secondary" size="large" type="submit" fullWidth={true}>
              <Typography variant="h6" component="span" className="txt-white">Save</Typography>
            </Button>
          </div>
        </form>
        <br/>
        <Typography variant="body1" component="div">
          - or -
        </Typography>
        <br/>
        <Typography variant="body1" component="div">
          Skip if you've entered before.
        </Typography>
        <br/>
        <div className="txt-input-btn-sm">
          <Button variant="contained" component="button" color="secondary" size="small" fullWidth={true} onClick={this.onSkipEnter}>
            <Typography variant="body1" component="span" className="txt-white">Skip</Typography>
          </Button>
        </div>
      </Fragment>
    )
  }

  render() {
    const {
      connectorFormatted,
      showAuthButton,
      showForm,
      showSuccess,
      loading,
      error,
      message,
    } = this.state;
    return (
      <Fragment>
        <Loading loading={loading}/>
        { showAuthButton === true && this.renderAuthButton() }
        { showForm === true && this.renderForm() }
        { showSuccess === true && <ConfigSuccess connectorFormatted={connectorFormatted}/> }
        { error === true && <Alert severity="error" className="card-error">{message}</Alert> }
        { showForm === true &&
          <Card className="card-fa">
            <CardContent>
              <CardHeader title="Where to find the ads account id?" titleTypographyProps={{variant: "subtitle2"}}/>
              <CardMedia
                style={{height: '100%', paddingTop: '50%'}}
                image={faHelpImage}
                title="FacebookAds"
              />
            </CardContent>
          </Card>
        }
      </Fragment>
    )
  }
}

export default withRouter(ConfigFacebookAds);
