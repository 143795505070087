import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import axios from 'axios';
import * as API from '../../constants/api';
import { formatConnector, processError, DialogError, DialogSuccess } from '../Util';
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../../styles/main.css';


const INITIAL_STATE = {
  email: '',
  connector: '',
  connectorFormatted: '',
  connectorId: '',
  canceled: false,
  error: false,
  loading: false,
};

class CancelSubDialog extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.handleClose = this.handleClose.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  static propTypes = {
    open: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    connector: PropTypes.string.isRequired,
    connectorId: PropTypes.string.isRequired,
  };

  componentDidUpdate(prevProps) {
    if ( this.props.open !== prevProps.open && this.props.open === true ) {
      if ( this.props.email !== "" && this.props.connector !== "" && this.props.connectorId !== "" ) {
        this.setState({
          email: this.props.email,
          connector: this.props.connector,
          connectorFormatted: formatConnector(this.props.connector),
          connectorId: this.props.connectorId,
          canceled:false,
          error:false,
          loading:false,
        });
      } else {
        this.props.onClose(false);
      }
    }
  }

  handleClose() {
    this.props.onClose(true);
  };

  handleCancel() {
    this.props.onClose(false);
  };

  async handleConfirm() {
    const { email, connector, connectorId } = this.state;
    if ( email !== "" && connector !== "" && connectorId !== "" ) {
      this.setState({ loading:true });
      await axios
      .post(`${API.FMI_API}/connector/cancel/`, {
        email,
        connector,
        connector_id: connectorId,
      }, API.HEADERS)
      .then( response => {
        if ( response.data.message !== API.MESSAGE_SUCCESS ) {
          this.setState({ canceled:false, error:true, loading:false, message: "Failed to cancel the Connector. Please refresh the page and try again." });
          return
        }
        this.setState({ canceled:true, error:false, loading:false });
      })
      .catch( error => {
        const { message } = processError(error);
        this.setState({ canceled:false, error:true, message, loading:false });
      });
    }
  };

  renderQuestion() {
    const { loading, connectorFormatted } = this.state;
    return (
      <Fragment>
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>
              Cancel Connector
            </Grid>
            <Grid item>
              <IconButton onClick={this.handleCancel} size="small">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          Would you like to cancel your {connectorFormatted} Connector?
          <br/>
          It will be unavailable immediately, as well as all existing data sources and reports at Data Studio built with this Connector. This action <span className="txt-error txt-bold">CANNOT</span> be undone.
        </DialogContent>
        <DialogActions>
          { loading === true ?
            <CircularProgress />
            :
            <Grid container justifyContent="space-between">
              <Grid item>
                <span className="dialog-btn">
                  <Button onClick={this.handleConfirm} color="primary">
                    Yes, I'd like to cancel
                  </Button>
                </span>
              </Grid>
              <Grid item>
                <span className="dialog-btn">
                  <Button autoFocus onClick={this.handleCancel} color="primary" variant="contained">
                    <span className={"txt-white txt-bold"}>No</span>
                  </Button>
                </span>
              </Grid>
            </Grid>
          }
        </DialogActions>
      </Fragment>
    )
  }

  render() {
    const { canceled, error } = this.state;
    return (
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        aria-labelledby="confirm-cancel-sub"
        open={this.props.open}
        className="sb-dialog"
      >
        { error === false && canceled === false && this.renderQuestion() }
        { error === false && canceled === true &&
          <DialogSuccess
            onClick={this.handleClose}
            title="Your Connector has been cancelled."
            content="Thank you."
          />
        }
        { error === true && <DialogError onClick={this.handleClose}/> }
      </Dialog>
    );
  }
}

export default CancelSubDialog;
