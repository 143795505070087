export const BASE_URL = process.env.REACT_APP_URL;
export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const MY_SUB = '/mysub';
export const MY_PRODUCTS = '/myconnectors';
export const MY_INVOICES = '/myinvoices';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PRICING = '/pricing';
export const PASSWORD_FORGET = '/password';
export const PURCHASE = '/purchase';
export const FREE_TRIAL = '/trial';
export const CANCEL = '/cancel';
export const SUCCESS = '/success';
export const CONFIG = '/config';
export const SUB_UPDATE = '/subupdate';
export const PAYMENT_UPDATE = '/paymentupdate';
export const PRIVACY_POLICY = 'https://success.datagems.co/portal/en/kb/articles/privacypolicy';
export const TERMS_AND_CONDITIONS = 'https://success.datagems.co/portal/en/kb/articles/termsofservice';
export const SUPPORT = 'https://success.datagems.co';
export const MAIN_SITE = 'https://www.findmyinsight.biz';
export const YOUTUBE_CHANNEL = "https://www.youtube.com/channel/UCN9PZqad2GPWeF38oMCcJdQ";
