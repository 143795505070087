import React from 'react';
import { Link } from 'react-router-dom';
import { AuthUserContext, withAuthorization } from '../Session';
import * as ROUTES from '../../constants/routes';
import * as API from '../../constants/api';
import CheckoutFreeTrial from '../CheckoutFreeTrial';
import { Button, Grid } from '@mui/material';
import '../../styles/main.css';

function FreeTrialPage() {
  return (
    <AuthUserContext.Consumer>
      { authUser => (
        <Grid container justifyContent="center">
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <h1 className="grid-row one">Start Your {API.FREE_TRIAL_DAYS} Day Trial</h1>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className="grid-row two">
              <CheckoutFreeTrial
                authUserEmail={authUser.email}
                freeTrial={API.FREE_TRIAL_ON}
              />
            </div>
          </Grid>
        </Grid>
      )}
    </AuthUserContext.Consumer>
  )
};

const FreeTrialButton = () => (
  <div className="pch-btn-div">
    <Button variant="contained" style={{backgroundColor: '#E43B2C'}} size="large" fullWidth={true}
      component={ Link } to={ROUTES.FREE_TRIAL}>
      <span className="pch-btn-txt">{API.FREE_TRIAL_DAYS} Day Trial</span>
    </Button>
  </div>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(FreeTrialPage);

export { FreeTrialButton };
