import React, { Component, Fragment } from 'react';
import { withFirebase } from '../Firebase';
import { Loading } from '../Loading';
import { Button, TextField, Typography } from '@mui/material';
import '../../styles/main.css';

const INITIAL_STATE = {
  firstName: '',
  firstNameOld: '',
  lastName: '',
  lastNameOld: '',
  orgName: '',
  orgNameOld: '',
  email: '',
  error: false,
  message: '',
  loading: false,
};

class DisplayNameChangeForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    if ( this.props.firebase.auth.currentUser.displayName ) {
      const displayName = this.props.firebase.auth.currentUser.displayName || '';
      const splited = displayName.split(",");
      this.setState({
        firstNameOld: splited[0] || '',
        lastNameOld: splited[1] || '',
        orgNameOld: splited[2] || '',
        email: this.props.firebase.auth.currentUser.email || '',
      });
    } else {
      this.setState({
        email: this.props.firebase.auth.currentUser.email || '',
      });
    }
  }

  onSubmit = event => {
    event.preventDefault();
    this.setState({ loading:true });
    const { firstName, lastName, orgName } = this.state;
    const displayName = `${firstName.trim()},${lastName.trim()},${orgName.trim()}`;
    this.props.firebase
    .doProfileUpdate({ displayName })
    .then(() => {
      this.setState({
        firstName: '',
        firstNameOld: firstName.trim(),
        lastName: '',
        lastNameOld: lastName.trim(),
        orgName: '',
        orgNameOld: orgName.trim(),
        error: false,
        message:"Your profile has been updated successfully.",
        loading: false,
      });
    })
    .catch(error => {
      const { firstNameOld, lastNameOld, orgNameOld } = this.state;
      this.setState({
        firstName: firstNameOld,
        lastName: lastNameOld,
        orgName: orgNameOld,
        error:true,
        message:error.message || "Unknown error",
        loading:false,
      });
    });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value, error: false, message: '' });
  };

  render() {
    const { firstNameOld, lastNameOld, orgNameOld, firstName, lastName, orgName, email, error, message, loading } = this.state;
    const isInvalid =
      firstName === '' || firstName.indexOf(',') > -1 ||
      lastName === '' || lastName.indexOf(',') > -1 ||
      orgName === '' || orgName.indexOf(',') > -1;

    return (
      <Fragment>
        <Loading loading={loading}/>
        <form onSubmit={this.onSubmit}>
          <div className="txt-input txt-left">
            <Typography variant="body1" component="div">{email}</Typography>
            <Typography variant="body1" component="div">{`${firstNameOld} ${lastNameOld}`}</Typography>
            <Typography variant="body1" component="div">{orgNameOld}</Typography>
          </div>
          <div className="txt-input">
            <TextField
              required
              label="First Name"
              type="text"
              variant="outlined"
              name="firstName"
              autoComplete="given-name"
              value={firstName}
              onChange={this.onChange}
              fullWidth={true}
            />
          </div>
          <div className="txt-input">
            <TextField
              required
              label="Last Name"
              type="text"
              variant="outlined"
              name="lastName"
              autoComplete="family-name"
              value={lastName}
              onChange={this.onChange}
              fullWidth={true}
            />
          </div>
          <div className="txt-input">
            <TextField
              required
              label="Company Name"
              type="text"
              id="org-name-input"
              variant="outlined"
              name="orgName"
              autoComplete="organization"
              value={orgName}
              onChange={this.onChange}
              fullWidth={true}
            />
          </div>
          <div className="txt-input-btn">
            <Button variant="contained" disabled={isInvalid===true} component="button" color="primary" size="large" type="submit" fullWidth={true}>
              <Typography variant="h6" component="span" className="txt-white">Update</Typography>
            </Button>
          </div>
          { error === true ? <Typography variant="body1" component="span" color="error">{message}</Typography>
            : <Typography variant="body1" component="span" color="primary">{message}</Typography>}
        </form>
      </Fragment>
    );
  }
}

export default withFirebase(DisplayNameChangeForm);
