import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { Grid, Button } from '@mui/material';
import '../../styles/main.css';

class Welcome extends Component {
  render() {
    return (
      <Grid container justifyContent="center">
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <h1 className="grid-row one">Welcome to Find My Insight member portal</h1>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className="grid-row two">
            <div className="cfg-btn-div">
              <Button variant="contained" color="secondary" size="medium" fullWidth={true}
                component={ Link } to={ROUTES.MY_SUB}>
                <span className="pch-btn-txt">Take me to My Subscription</span>
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    )
  }
}

export default Welcome;
