import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { Link } from '@mui/material';

class SignOutButton extends Component {
  onClick = event => {
    event.preventDefault();
    this.props.firebase.doSignOut();
  }

  render() {
    return (
      <Link onClick={this.onClick} underline="none">
        Sign Out
      </Link>
    )
  }
}

export default withFirebase(SignOutButton);
