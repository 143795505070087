import React from "react";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import CheckoutFreeTrialForm from './CheckoutFreeTrialForm';

const InjectedCheckoutFreeTrialForm = (props) => {
  return (
    <ElementsConsumer>
      {({elements, stripe}) => (
        <CheckoutFreeTrialForm elements={elements} stripe={stripe} {...props}/>
      )}
    </ElementsConsumer>
  );
};

export default InjectedCheckoutFreeTrialForm;
