import React from 'react';
import { CircularProgress, Backdrop } from '@mui/material';
import '../../styles/main.css';

const Loading = (props) => (
  <Backdrop open={props.loading ? props.loading : false} style={{zIndex: '100'}}>
    <CircularProgress size={70}/>
  </Backdrop>
);

export { Loading };
