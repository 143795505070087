import React from 'react';
import { withRouter } from '../Util';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import AuthUserContext from './context';

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      this.authListener = this.props.firebase.auth.onAuthStateChanged( authUser => {
        if (!condition(authUser)) {
          this.props.router.navigate(ROUTES.SIGN_IN);
        }
      });
    }

    componentWillUnmount() {
      this.authListener();
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          { authUser => (
            condition(authUser) ? <Component {...this.props} /> : null
          )}
        </AuthUserContext.Consumer>
      )
    }
  }

  return withFirebase(withRouter(WithAuthorization));
};

export default withAuthorization;
