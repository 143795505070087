import React, { Fragment } from 'react';
import { Grid, Button, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../../styles/main.css';

const DialogSuccess = (props) => (
  <Fragment>
    <DialogTitle>
      <Grid container justifyContent='space-between'>
        <Grid item>
          { ( props.hasOwnProperty('title') && props.title !== '' ) ?
            props.title : 'Success'
          }
        </Grid>
        <Grid item>
          <IconButton onClick={props.onClick} size='small'>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    </DialogTitle>
    <DialogContent>
      { ( props.hasOwnProperty('content') && props.content !== '' ) ?
        props.content :
        `Thank you. Please wait for a moment and refresh the page if the change hasn't been shown.`
      }
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={props.onClick} color='primary'>
        Close
      </Button>
    </DialogActions>
  </Fragment>
)

export default DialogSuccess;
