import React, { Component } from 'react';
import { AuthUserContext, withAuthorization } from '../Session';
import * as ROUTES from '../../constants/routes';
import * as API from '../../constants/api';
import axios from 'axios';
import SubUpdateForm from './SubUpdateForm';
import { Grid } from '@mui/material';
import '../../styles/main.css';


const INITIAL_STATE = {
  email: '',
  id: '',
  quantity: 0,
  interval: '',
  hasNCT: false,
  endTrialNow: '',
  quantityActive: 0,
};

class SubUpdatePage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  async componentDidMount() {
    if (!this.props.router.location.state) {
      this.props.router.navigate(ROUTES.MY_SUB);
    } else {
      const { email } = this.props.router.location.state;
      this.setState({
        email
      });
      await axios
      .post(`${API.FMI_API}/connector/summary/`, {
        email
      }, API.HEADERS)
      .then( response => {
        this.setState({
          id: this.props.router.location.state.id,
          quantity: this.props.router.location.state.quantity,
          interval: this.props.router.location.state.interval,
          hasNCT: this.props.router.location.state.hasNCT,
          endTrialNow: this.props.router.location.state.endTrialNow,
          quantityActive: response.data.quantity_active,
        });
      })
      .catch( error => {
        this.props.router.navigate(ROUTES.MY_SUB);
      });
    }
  }

  render() {
    const { email, id, quantity, interval, hasNCT, endTrialNow, quantityActive } = this.state;
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <Grid container justifyContent="center">
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <h1 className="grid-row one lg-ft">
                { endTrialNow===API.FREE_TRIAL_END_NOW ? "Go Premium and Update My Subscription" : "Update My Subscription"}
              </h1>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <SubUpdateForm
                authUserEmail={email}
                id={id}
                quantity={quantity}
                interval={interval}
                hasNCT={hasNCT}
                endTrialNow={endTrialNow}
                quantityActive={quantityActive}
              />
            </Grid>
          </Grid>
        )}
      </AuthUserContext.Consumer>
    )
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(SubUpdatePage);
