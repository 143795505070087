import React, { Component } from 'react';
import { Loading } from '../Loading';
import * as API from '../../constants/api';
import * as ROUTES from '../../constants/routes';
import { isValidQuantity, withRouter } from '../Util';
import UpdateSubDialog from './UpdateSubDialog';
import { Button, Typography, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import '../../styles/main.css';


const INITIAL_STATE = {
  email: '',
  id: '',
  quantity: 0,
  interval: '',
  hasNCT: false,
  endTrialNow: '',
  quantityActive: 0,
  quantityNew: 0,
  intervalNew: '',
  intervalFormatted: '',
  totalAmount: 0,
  open: false,
  showForm: true,
  loading: false,
  message: '',
  title: '',
};

class SubUpdateForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidUpdate(prevProps) {
    if (( this.props.authUserEmail !== prevProps.authUserEmail && this.props.authUserEmail !== '' ) ||
      ( this.props.id !== prevProps.id && this.props.id !== '') ||
      ( this.props.quantity !== prevProps.quantity && this.props.quantity >= 0 ) ||
      ( this.props.interval !== prevProps.interval && this.props.interval !== '') ||
      ( this.props.hasNCT !== prevProps.hasNCT && this.props.hasNCT !== '' ) ||
      ( this.props.endTrialNow !== prevProps.endTrialNow && this.props.endTrialNow !== '') ||
      ( this.props.quantityActive !== prevProps.quantityActive && this.props.quantityActive >= 0 )
    ) {
      let intervalFormatted = "";
      if ( this.props.interval===API.SUB_INTERVAL_MONTH ) {
        intervalFormatted = API.SUB_INTERVAL_MONTH_TEXT_LOWERCASE
      } else if ( this.props.interval===API.SUB_INTERVAL_YEAR ) {
        intervalFormatted = API.SUB_INTERVAL_YEAR_TEXT_LOWERCASE
      }
      const endTrialNow = this.props.endTrialNow === API.FREE_TRIAL_END_NOW ? API.FREE_TRIAL_END_NOW : "";
      this.setState({
        email: this.props.authUserEmail,
        id: this.props.id,
        quantity: this.props.quantity,
        interval: this.props.interval,
        hasNCT: this.props.hasNCT,
        endTrialNow,
        quantityActive: this.props.quantityActive,
        quantityNew: 0,
        intervalNew: '',
        intervalFormatted,
      });
    }
  }

  onChangeInterval = (event) => {
    const intervalNew = event.target.value;
    if ( intervalNew === API.SUB_INTERVAL_YEAR || intervalNew === API.SUB_INTERVAL_MONTH ) {
      this.setState({ intervalNew });
    } else {
      this.setState({ intervalNew: '', error:false });
    }
  }


  onChangeQuantity = (event) => {
    const quantityNew = Number(event.target.value);
    if ( isValidQuantity(quantityNew) ) {
      this.setState({ quantityNew });
    } else {
      this.setState({ quantityNew: 0, error:false });
    }
  }

  onSubmit = () => {
    if ( this.state.quantityNew >= this.state.quantityActive ) {
      this.setState({ open:true });
    } else {
      this.setState({ error:true, message: "Something wrong. Please try again. If it fails, raise a ticket on the help desk." });
    }
  }

  onClose = (redirect) => {
    this.setState({ open:false });
    if ( redirect === true ) {
      this.props.router.navigate(ROUTES.MY_SUB);
    }
  }

  render() {
    const {
      email, id, quantity, interval,
      hasNCT, endTrialNow, quantityActive,
      quantityNew, intervalNew, intervalFormatted,
      open, message, loading, error,
    } = this.state;
    const same = intervalNew !== '' && quantityNew > 0 && interval === intervalNew && quantity === quantityNew;
    const noMoreThanActive = quantityNew >= quantityActive;
    const valid = intervalNew !== '' && isValidQuantity(quantityNew) && ( hasNCT || !same ) && noMoreThanActive;
    return (
      <div className="od-fm-div">
        <Loading loading={loading}/>
        <UpdateSubDialog
          keepMounted
          open={open}
          onClose={this.onClose}
          email={email}
          id={id}
          interval={ valid ? intervalNew : '' }
          quantity={ valid ? quantityNew : 0 }
          endTrialNow={endTrialNow}
        />
        <Typography variant="h6" component="h6">
          { endTrialNow === API.FREE_TRIAL_END_NOW ? "Default after trial ends " : "You currently has " }
          { intervalFormatted } subscription with {quantity} Connector{ quantity > 1 && "s" }
          { hasNCT === true ? ` + ${API.PRICE_NICKNAME_NCT}` : "" }
        </Typography>
        <span className="od-fm-control">
          <FormControl>
            <InputLabel>
              <Typography variant="body1" component="span">Subscription Type</Typography>
            </InputLabel>
            <Select defaultValue="" onChange={this.onChangeInterval} className="od-fm-select"
              label="Subscription Type">
              <MenuItem value={API.SUB_INTERVAL_MONTH}>{API.SUB_INTERVAL_MONTH_TEXT}</MenuItem>
              <MenuItem value={API.SUB_INTERVAL_YEAR}>{API.SUB_INTERVAL_YEAR_TEXT}</MenuItem>
            </Select>
          </FormControl>
        </span>
        <span className="od-fm-control">
          <FormControl>
            <TextField
              id="connector-quantity"
              label="Quantity of Connectors"
              type="number"
              onChange={this.onChangeQuantity}
              value={quantityNew}
            />
          </FormControl>
        </span>
        <div className="od-btn-div">
          { valid === true ?
            <Button onClick={this.onSubmit} variant="contained" style={{backgroundColor:'#E43B2C'}} size="large" fullWidth={true}>
              <span className="pch-btn-txt">Update Now</span>
            </Button>
            :
            <Button onClick={this.onSubmit} variant="contained" size="large" fullWidth={true} disabled>
              <span className="pch-btn-txt">Update Now</span>
            </Button>
          }
        </div>
        <div className="sb-up-fm">
          { error === true && <Typography variant="body1" component="div" color="error">{message}</Typography> }
          { same === true && hasNCT === false && <Typography variant="body1" component="div" color="error">Same as your current subscription</Typography> }
          { quantityNew > 0 && noMoreThanActive === false &&
            <Typography variant="body1" component="div" color="error">
              You will need to cancel {quantityActive - quantityNew} Connectors first. The quantity of Connectors in use must not be greater than the desired quantity.
            </Typography>
          }
          <br/>
          { hasNCT === true &&
            <Typography variant="body1" component="div" className="txt-note">
              If you are using the {API.PRICE_NICKNAME_NCT}, it will be cancelled immediately when you update your subscription here.
            </Typography>
          }
        </div>
      </div>
    )
  }
}

export default withRouter(SubUpdateForm);
