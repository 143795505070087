import React, { useState } from 'react';
import * as API from '../../constants/api';
import { calculateTotalAmount, isValidQuantity } from '../Util';
import { Typography, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import '../../styles/main.css';


const PricingCalculator = () => {
  const [interval, setInt] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const onChangeInterval = (event) => {
    const intervalNew = event.target.value;
    if ( intervalNew === API.SUB_INTERVAL_YEAR || intervalNew === API.SUB_INTERVAL_MONTH ) {
      setInt(intervalNew);
      if ( quantity > 0) {
        setTotalAmount(calculateTotalAmount(intervalNew, quantity));
      }
    } else {
      setInt('');
      setTotalAmount(0);
    }
  }

  const onChangeQuantity = (event) => {
    const quantityNew = Number(event.target.value);
    if ( isValidQuantity(quantityNew) ) {
      setQuantity(quantityNew);
      if ( interval === API.SUB_INTERVAL_YEAR || interval === API.SUB_INTERVAL_MONTH ) {
        setTotalAmount(calculateTotalAmount(interval, quantityNew));
      }
    } else {
      setQuantity(0);
      setTotalAmount(0);
    }
  }

  return (
    <div className="pc-cal">
      <Typography variant="h6" component="h6">Calculator</Typography>
      <span className="od-fm-control">
        <FormControl>
          <InputLabel>
            <Typography variant="body1" component="span">Subscription Type</Typography>
          </InputLabel>
          <Select defaultValue="" onChange={onChangeInterval} className="od-fm-select"
            label="Subscription Type">
            <MenuItem value={API.SUB_INTERVAL_MONTH}>{API.SUB_INTERVAL_MONTH_TEXT}</MenuItem>
            <MenuItem value={API.SUB_INTERVAL_YEAR}>{API.SUB_INTERVAL_YEAR_TEXT}</MenuItem>
          </Select>
        </FormControl>
      </span>
      <span className="od-fm-control">
        <FormControl>
          <TextField
            id="connector-quantity"
            label="Quantity of Connectors"
            type="number"
            onChange={onChangeQuantity}
            value={quantity}
          />
        </FormControl>
      </span>
      <Typography variant="body1" component="div" className="or-fm-total">Total Amount: ${totalAmount}</Typography>
    </div>
  )
};

export default PricingCalculator;
