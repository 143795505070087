import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AuthUserContext, withAuthorization } from '../Session';
import * as ROUTES from '../../constants/routes';
import OrderForm from '../OrderForm';
import Checkout from '../Checkout';
import { Button, Grid } from '@mui/material';
import '../../styles/main.css';


class PurchasePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      interval: '',
      quantity: 0,
      totalAmount: 0,
    };
    this.onSelected = this.onSelected.bind(this);
  };

  onSelected(interval, quantity, totalAmount) {
    if ( interval && interval !== "" && quantity > 0 && totalAmount > 0 ) {
      // Pass to child component CheckoutForm
      this.setState({ selected:true, interval, quantity, totalAmount });
    }
  }

  render () {
    const { selected, interval, quantity, totalAmount } = this.state;
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <Grid container justifyContent="center">
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <h1 className="grid-row one">Purchase Your Connectors</h1>
            </Grid>
            { selected === true ? (
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className="grid-row two">
                  <Checkout
                    authUserEmail={authUser.email}
                    interval={interval}
                    quantity={quantity}
                    totalAmount={totalAmount}
                  />
                </div>
              </Grid>
            ) : (
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <OrderForm authUserEmail={authUser.email} onSelected={this.onSelected}/>
              </Grid>
            )}
          </Grid>
        )}
      </AuthUserContext.Consumer>
    )
  }
};

const PurchaseButton = () => (
  <div className="pch-btn-div">
    <Button variant="contained" style={{backgroundColor: '#E43B2C'}} size="large" fullWidth={true}
      component={ Link } to={ROUTES.PURCHASE}>
      <span className="pch-btn-txt">Buy Now</span>
    </Button>
  </div>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(PurchasePage);

export { PurchaseButton };
