import React from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import App from './components/App';
import Firebase, { FirebaseContext } from './components/Firebase';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import THEME from './constants/theme';
import * as GTM from './constants/gtm';
import TagManager from 'react-gtm-module';
import './index.css';
require('./styles/main.css');

const tagManagerArgs = {
  gtmId: GTM.GTM_ID,
}

TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <ThemeProvider theme={THEME}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </FirebaseContext.Provider>
);

serviceWorker.unregister();
