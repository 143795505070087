import React, { Fragment } from 'react';
import * as ROUTES from '../../constants/routes';
import { Grid, Button, DialogTitle, DialogContent, DialogActions, IconButton, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../../styles/main.css';

const DialogError = (props) => (
  <Fragment>
    <DialogTitle>
      <Grid container justifyContent='space-between'>
        <Grid item>
          { ( props.hasOwnProperty('message') && props.message !== '' ) ?
            props.message : 'Something wrong' }
        </Grid>
        <Grid item>
          <IconButton onClick={props.onClick} size='small'>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    </DialogTitle>
    <DialogContent>
      { ( props.hasOwnProperty('content') && props.content !== '' ) ?
        props.content :
        <Fragment>
          Please refresh the page and try again. If it persists, raise a ticket&nbsp;
          <Link href={ROUTES.SUPPORT} target='_blank' rel='noopener' variant='body2' color='inherit' underline='always'>
            here
          </Link>.
        </Fragment>
      }
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={props.onClick} color='primary'>
        Close
      </Button>
    </DialogActions>
  </Fragment>
)

export default DialogError;
