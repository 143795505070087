export const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: "'Montserrat'",
      fontSize: '18px',
      fontWeight: '800',
      color: '#424770',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#e4262c',
    },
  },
};
