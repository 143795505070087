import React, { Component } from "react";
import { CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import axios from 'axios';
import * as API from '../../constants/api';
import * as ROUTES from '../../constants/routes';
import * as STRIPE from '../../constants/stripe';
import { processError, FormSuccess, ConsentCheckbox, calculateTotalAmount, handleStripeElementChange } from '../Util';
import { Loading } from '../Loading';
import { Paper, Button, Typography, Box, Link } from '@mui/material';
import '../../styles/main.css';


const INITIAL_STATE = {
  email: '',
  freeTrial: '',
  totalAmount: 0,
  dueDate: '',
  title: '',
  message: '',
  clientSecret: null,
  showForm: true,
  success: false,
  loading: false,
  numValid: false,
  expValid: false,
  cvcValid: false,
  checked: false,
  enableBtn: true,
};

class CheckoutFreeTrialForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidUpdate(prevProps) {
    if ( prevProps.authUserEmail !== this.props.authUserEmail &&
      this.props.authUserEmail && this.props.authUserEmail !== '' &&
      prevProps.freeTrial !== this.props.freeTrial &&
      this.props.freeTrial === API.FREE_TRIAL_ON ) {
      const today = new Date();
      const dueDate = new Date();
      dueDate.setDate(today.getDate() + API.FREE_TRIAL_DAYS);
      this.setState({
        email: this.props.authUserEmail,
        freeTrial: this.props.freeTrial,
        totalAmount: calculateTotalAmount(API.FREE_TRIAL_INTERVAL, API.FREE_TRIAL_CONNECTOR_QUANTITY),
        dueDate: dueDate.toLocaleDateString(),
      });
    }
  }

  handleChangeCheckbox = (event) => {
    if (event.target.checked) {
      this.setState({ checked:true });
    } else {
      this.setState({ checked:false });
    }
  }

  handleChange = (event) => {
    handleStripeElementChange(this.setState.bind(this), event.complete, event.elementType, event.error, event.empty);
  }

  handleSubmit = async (event) => {
    this.setState({ loading:true, enableBtn: false, message:"" });
    event.preventDefault();
    const { stripe, elements } = this.props;
    if ( !stripe || !elements ) {
      this.setState({ showForm:true, success:false, loading:false, enableBtn:true, message:"Failed to load Stripe. Please try to refresh the page." });
      return
    }
    const cardElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email: this.state.email
      },
    });
    if (paymentMethod) {
      this.createCustomerSetupIntent(paymentMethod.id);
    } else if (error && error.message) {
      this.setState({ showForm:true, success:false, loading:false, enableBtn: true, message:error.message });
    } else {
      this.setState({ showForm:true, success:false, loading:false, enableBtn: true, message:"Unexpected error processing this payment method. Please try again." });
    }
  }

  createCustomerSetupIntent = async (paymentMethodId) => {
    const { email, freeTrial } = this.state;
    await axios
    .post(`${API.FMI_API}/customer/createcustomerandsetupintent/`, {
      email,
      payment_method_id: paymentMethodId,
      free_trial_on: freeTrial,
    }, API.HEADERS)
    .then( result => {
      if ( result.data.message !== API.MESSAGE_SUCCESS || !result.data.setup_intent_status ) {
        this.setState({
          showForm:true,
          success:false,
          loading:false,
          enableBtn: true,
          message:"Unknown error. Please try again.",
        });
      }
      if (result.data.setup_intent_status === API.SETUPINTENT_STATUS_SUCCEEDED && result.data.status === API.SUB_STATUS_TRIALING) {
        this.setState({
          showForm:false,
          success:true,
          loading:false,
          enableBtn: false,
          message:"",
          title: `Your trial has started with ${API.FREE_TRIAL_CONNECTOR_QUANTITY} Connector${API.FREE_TRIAL_CONNECTOR_QUANTITY>1?"s":""} and will end in ${API.FREE_TRIAL_DAYS} days. Please go to "My Connectors" page and start selecting Connector or go to "My Subscription" page to review details. You will receive reminder emails a few days before the end of trial. Your ${API.FREE_TRIAL_INTERVAL_TEXT_LOWERCASE} subscription with ${API.FREE_TRIAL_CONNECTOR_QUANTITY} Connector${API.FREE_TRIAL_CONNECTOR_QUANTITY>1?"s":""} will start when the trial ends.`,
        });
      } else if (result.data.setup_intent_status === API.SETUPINTENT_STATUS_REQUIRES_ACTION) {
        this.confirmSetupIntent(result.data.customer_id, paymentMethodId, result.data.client_secret);
      } else {
        this.setState({
          showForm:true,
          success:false,
          loading:false,
          enableBtn: true,
          message:"Unexpected error. Please try again.",
        });
      }
    }).catch( error => {
      const { message } = processError(error);
      this.setState({
        showForm:true,
        success:false,
        loading:false,
        enableBtn: true,
        message,
      });
    });
  }

  confirmSetupIntent = (customerId, paymentMethodId, clientSecret) => {
    const { email, freeTrial } = this.state;
    const { stripe } = this.props;
    if ( !stripe ) {
      this.setState({
        showForm:true,
        success:false,
        loading:false,
        enableBtn:true,
        message:"Failed to load Stripe. Please try to refresh the page.",
      });
      return
    }
    stripe.confirmCardSetup(clientSecret)
    .then( res => {
      if ( res.setupIntent ) {
        axios
        .post(`${API.FMI_API}/customer/startfreetrial/`, {
          email,
          customer_id: customerId,
          payment_method_id: paymentMethodId,
          free_trial_on: freeTrial,
        }, API.HEADERS)
        .then( result => {
          if (result.data && result.data.status) {
            if (result.data.status === API.SUB_STATUS_TRIALING) {
              this.setState({ showForm:false, success:true, loading:false, enableBtn: false, message:"", title: `Your trial has started with ${API.FREE_TRIAL_CONNECTOR_QUANTITY} Connector${API.FREE_TRIAL_CONNECTOR_QUANTITY>1?"s":""} and will end in ${API.FREE_TRIAL_DAYS} days. Please go to "My Connectors" page and start selecting Connector or go to "My Subscription" page to review details. You will receive reminder emails a few days before the end of trial. Your ${API.FREE_TRIAL_INTERVAL_TEXT_LOWERCASE} subscription with ${API.FREE_TRIAL_CONNECTOR_QUANTITY} Connector${API.FREE_TRIAL_CONNECTOR_QUANTITY>1?"s":""} will start when the trial ends.`});
            } else {
              this.setState({ showForm:true, success:false, loading:false, enableBtn: true, message:"Unexpected subscription status. Please return to 'My Subscription' page and try again." });
            }
          } else {
            this.setState({ showForm:true, success:false, loading:false, enableBtn: true, message:"Unknown error. Please return to 'My Subscription' page and try again." });
          }
        }).catch( error => {
          const { message } = processError(error);
          this.setState({
            showForm: true,
            success: false,
            loading: false,
            enableBtn: true,
            message,
          });
        });
      } else if ( res.error && res.error.message ) {
        this.setState({
          showForm: true,
          success: false,
          loading: false,
          enableBtn: true,
          message: res.error.message,
        });
      } else {
        this.setState({
          showForm: true,
          success: false,
          loading: false,
          enableBtn: true,
          message:`Unexpected error. Please return to "My Subscription" page and try again.`,
        });
      }
    }).catch( error => {
      this.setState({
        showForm: true,
        success: false,
        loading: false,
        enableBtn: true,
        message: "Failed to confirm the card setup. Please try again.",
      });
    });
  }

  renderForm() {
    const { dueDate, totalAmount, numValid, expValid, cvcValid, checked, enableBtn } = this.state;
    const valid = numValid === true && expValid === true && cvcValid === true && checked === true && enableBtn === true;
    const style = valid === false ? {backgroundColor: '#e0e0e0'} : {backgroundColor: '#e4262c'};
    return (
      <div className="ck-fm-div">
        <Typography variant="subtitle1">
          You won't be charged until your {API.FREE_TRIAL_DAYS} day trial ends. You will receive reminder emails 7 days, 3 days and 1 day before the end of your trial. You can cancel any time at&nbsp;
          <Link href={ROUTES.MY_SUB} variant="inherit" color="primary">
            My Subscription
          </Link>&nbsp;.
        </Typography>
        <Paper variant="outlined" elevation={0}>
          <div className="ck-fm-label">
            <Typography variant="body2" component="span">Premium Subscription:
              <Typography variant="inherit" component="span" color="primary">
                <Box fontWeight="fontWeightBold" component="span">
                  &nbsp;{API.FREE_TRIAL_INTERVAL_TEXT} with {API.FREE_TRIAL_CONNECTOR_QUANTITY} Connector{API.FREE_TRIAL_CONNECTOR_QUANTITY>1?"s":""}
                </Box>
              </Typography>
            </Typography>
          </div>
          <div className="ck-fm-label">
            <Typography variant="body2" component="span">First payment due:
              <Typography variant="inherit" component="span" color="primary">
                <Box fontWeight="fontWeightBold" component="span">
                  &nbsp;{dueDate}
                </Box>
              </Typography>
            </Typography>
          </div>
          <div className="ck-fm-label">
            <Typography variant="body2" component="span">First payment:
              <Typography variant="inherit" component="span" color="primary">
                <Box fontWeight="fontWeightBold" component="span">
                  &nbsp;{totalAmount} USD
                </Box>
              </Typography>
            </Typography>
          </div>
        </Paper>
        <br/>
        <Typography variant="subtitle1">
          Please provide your credit card details below to proceed.
        </Typography>
        <Paper variant="outlined" elevation={0} className="ck-fm-paper">
          <div className="ck-fm-label">
            <Typography variant="body1" component="span">Total Amount: 0 USD</Typography>
          </div>
          <div className="ck-fm-label">
            <Typography variant="body1" component="span">Card Number</Typography>
          </div>
          <div className="ck-fm-card">
            <CardNumberElement className="ck-fm-card-element" onChange={this.handleChange} options={STRIPE.ELEMENT_OPTIONS}/>
          </div>
          <div className="ck-fm-label">
            <Typography variant="body1" component="span">Expiration Date</Typography>
          </div>
          <div className="ck-fm-card">
            <CardExpiryElement className="ck-fm-card-element" onChange={this.handleChange} options={STRIPE.ELEMENT_OPTIONS}/>
          </div>
          <div className="ck-fm-label">
            <Typography variant="body1" component="span">CVC</Typography>
          </div>
          <div className="ck-fm-card">
            <CardCvcElement className="ck-fm-card-element" onChange={this.handleChange} options={STRIPE.ELEMENT_OPTIONS}/>
          </div>
        </Paper>
        <Typography variant="caption" component="div">Payments handled by Stripe. We will not save your card details. Ever</Typography>
        <br/>
        <ConsentCheckbox checked={checked} onChange={this.handleChangeCheckbox}/>
        <div className="od-btn-div">
          <Button onClick={this.handleSubmit} variant="contained" style={style} size="large" fullWidth={true} disabled={valid === false}>
            <span className="pch-btn-txt">Confirm</span>
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { showForm, success, message, loading, title } = this.state;
    return (
      <div className="checkout-form">
        <Loading loading={loading}/>
        {showForm === true && this.renderForm()}
        {success === true && <FormSuccess title={title}/>}
        <Typography variant="subtitle1" component="div" align="center" color="error">{message}</Typography>
      </div>
    );
  }
}

export default CheckoutFreeTrialForm;
