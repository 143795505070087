const handleStripeElementChange = (setState, complete, elementType, error, empty) => {
  if (complete === true) {
    switch (elementType) {
    case "cardNumber":
      setState({ numValid:true, message:"" });
      break;
    case "cardExpiry":
      setState({ expValid:true, message:"" });
      break;
    case "cardCvc":
      setState({ cvcValid:true, message:"" });
      break;
    default:
      setState({ message:"Wrong element type." });
    }
  } else if (error) {
    switch (elementType) {
    case "cardNumber":
      setState({ numValid:false, message:error.message || "Unknown error" });
      break;
    case "cardExpiry":
      setState({ expValid:false, message:error.message || "Unknown error" });
      break;
    case "cardCvc":
      setState({ cvcValid:false, message:error.message || "Unknown error" });
      break;
    default:
      setState({ message:"Wrong element type." });
    }
  } else if (empty === true) {
    switch (elementType) {
    case "cardNumber":
      setState({ numValid:false, message:"Card number is required." });
      break;
    case "cardExpiry":
      setState({ expValid:false, message:"Expiration is required." });
      break;
    case "cardCvc":
      setState({ cvcValid:false, message:"CVC is required" });
      break;
    default:
      setState({ message:"Wrong element type." });
    }
  }
}

export default handleStripeElementChange;
