import React, { Component, Fragment } from 'react';
import { withFirebase } from '../Firebase';
import { Loading } from '../Loading';
import * as ROUTES from '../../constants/routes';
import * as MEMBERS from '../../constants/members';
import { withRouter, ConsentCheckbox } from '../Util';
import { Button, Typography, TextField } from '@mui/material';
import '../../styles/main.css';

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  orgName: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  checked: false,
  error: false,
  message: '',
  loading: false,
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  onSubmit = event => {
    this.setState({ loading:true });
    const { firstName, lastName, orgName, email, passwordOne } = this.state;
    const displayName = `${firstName.trim()},${lastName.trim()},${orgName.trim()}`;
    this.props.firebase
    .doCreateUserWithEmailAndPassword(email, passwordOne)
    .then(authUser => {
      this.props.firebase.doProfileUpdate({
        displayName,
      })
      .finally(() => {
        this.props.router.navigate(ROUTES.MY_SUB);
      });
    })
    .catch(error => {
      this.setState({ passwordOne:'', passwordTwo:'', error:true, message: error.message || "Unknown error", loading:false });
    });
    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value, error:false, message:'' });
  };

  handleChangeCheckbox = (event) => {
    if (event.target.checked) {
      this.setState({ checked:true });
    } else {
      this.setState({ checked:false });
    }
  }

  render() {
    const { firstName, lastName, orgName, email, passwordOne, passwordTwo, checked, error, message, loading } = this.state;
    const notMatch = passwordOne !== passwordTwo && passwordOne !== '' && passwordTwo !== '';
    const isInvalid =
      firstName === '' || firstName.indexOf(',') > -1 ||
      lastName === '' || lastName.indexOf(',') > -1 ||
      orgName === '' || orgName.indexOf(',') > -1 ||
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      passwordOne.length < MEMBERS.PASSWORD_LENGTH ||
      passwordTwo.length < MEMBERS.PASSWORD_LENGTH ||
      checked === false ||
      email === '';
    return (
      <Fragment>
        <Loading loading={loading}/>
        <form onSubmit={this.onSubmit}>
          <div className="txt-input">
            <TextField
              required
              label="Email"
              type="email"
              variant="outlined"
              name="email"
              autoComplete="email"
              value={email}
              onChange={this.onChange}
              fullWidth={true}
              helperText="IMPORTANT: Email can NOT be changed after sign-up."
            />
          </div>
          <div className="txt-input">
            <TextField
              required
              label="First Name"
              type="text"
              id="first-name-input"
              variant="outlined"
              name="firstName"
              autoComplete="given-name"
              value={firstName}
              onChange={this.onChange}
              fullWidth={true}
            />
          </div>
          <div className="txt-input">
            <TextField
              required
              label="Last Name"
              type="text"
              id="last-name-input"
              variant="outlined"
              name="lastName"
              autoComplete="family-name"
              value={lastName}
              onChange={this.onChange}
              fullWidth={true}
            />
          </div>
          <div className="txt-input">
            <TextField
              required
              label="Company Name"
              type="text"
              id="org-name-input"
              variant="outlined"
              name="orgName"
              autoComplete="organization"
              value={orgName}
              onChange={this.onChange}
              fullWidth={true}
            />
          </div>
          <div className="txt-input">
            <TextField
              required
              label="Password"
              type="password"
              variant="outlined"
              name="passwordOne"
              autoComplete="new-password"
              value={passwordOne}
              onChange={this.onChange}
              fullWidth={true}
            />
          </div>
          <div className="txt-input">
            <TextField
              required
              label="Confirm Password"
              type="password"
              variant="outlined"
              name="passwordTwo"
              autoComplete="new-password"
              value={passwordTwo}
              onChange={this.onChange}
              fullWidth={true}
            />
          </div>
          <div className="txt-input">
            <ConsentCheckbox checked={checked} onChange={this.handleChangeCheckbox}/>
          </div>
          <div className="txt-input-btn">
            <Button variant="contained" disabled={isInvalid===true} component="button" color="secondary" size="large" type="submit" fullWidth={true}>
              <Typography variant="h6" component="span" className="txt-white">Sign Up</Typography>
            </Button>
          </div>
          {notMatch === true && <Typography variant="body1" component="div" color="error">Password doesn't match</Typography>}
          {error === true && <Typography variant="body1" component="span" color="error">{message}</Typography>}
        </form>
      </Fragment>
    );
  }
};

const SignUpForm = withFirebase(withRouter(SignUpFormBase));

export default SignUpForm;
