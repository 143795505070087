import React, { Component } from 'react';
import { Loading } from '../Loading';
import * as ROUTES from '../../constants/routes';
import axios from 'axios';
import * as API from '../../constants/api';
import { processError, withRouter } from '../Util';
import { Button, Typography, FormControl, InputLabel, Select, MenuItem, ListSubheader, Link as MuiLink } from '@mui/material';
import '../../styles/main.css';

const INITIAL_STATE = {
  email: '',
  status: '',
  connector: '',
  total: 0,
  activeCount: 0,
  addCount: 0,
  loading: false,
};

class AddProductForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  };

  async componentDidMount() {
    const email = this.props.authUserEmail;
    this.setState({ email, loading:true });
    await axios
    .post(`${API.FMI_API}/connector/summary/`, {
      email
    }, API.HEADERS)
    .then( response => {
      if ( response.data.message !== API.MESSAGE_SUCCESS ) {
        this.setState({ error:false, loading:false, message: "" });
        return
      }
      this.setState({
        status: response.data.status,
        total: response.data.quantity,
        activeCount: response.data.quantity_active,
        addCount: response.data.quantity - response.data.quantity_active,
        loading: false,
      });
    })
    .catch( error => {
      const { message } = processError(error);
      this.setState({ error: true, message, loading:false });
    });
  }

  onChange(event) {
    if ( event.target.value !== "" ) {
      this.setState({ connector: event.target.value, error:false, message:'' });
    } else {
      this.setState({ connector: "", error:false, message:'' });
    }
  }

  async onSubmit(event) {
    event.preventDefault();
    this.setState({ loading:true });
    const { email, connector } = this.state;
    if ( email !== "" && connector !== "" ) {
      await axios
      .post(`${API.FMI_API}/connector/add/`, {
        email,
        connector_type: connector
      }, API.HEADERS)
      .then( response => {
        if ( response.data.message !== API.MESSAGE_SUCCESS || !response.data.hasOwnProperty("connector_id") ) {
          this.setState({ error: false, message: "Please refresh the page and try again.", loading:false });
        }
        this.setState({ ...INITIAL_STATE });
        if ( response.hasOwnProperty("data") && response.data.hasOwnProperty("connector_id") ) {
          const connectorId = response.data.connector_id;
          this.props.router.navigate(`${ROUTES.CONFIG}/${connector}`, { state: {
            connector_id: connectorId,
          }});
        }
      })
      .catch( error => {
        const { message } = processError(error);
        this.setState({ error:true, message, loading:false });
      });
    } else {
      this.setState({ error: false, message: "Please select Connector.", loading:false });
    }
  }

  render() {
    const { status, connector, total, addCount, error, message, loading } = this.state;
    const valid = ( status === API.SUB_STATUS_ACTIVE || status === API.SUB_STATUS_TRIALING ) && addCount > 0 && connector !== '';
    return (
      <div className="od-fm-div">
        <Loading loading={loading}/>
        <Typography variant="h6" component="h6">
          You have {
            ( status===API.SUB_STATUS_PAST_DUE||status===API.SUB_STATUS_UNPAID ) ?
              <Typography variant="h6" component="span" className="txt-bold txt-error">
                {status.replace("_", " ")}
              </Typography>
              :
              <Typography variant="h6" component="span">
                {status.replace("_", " ")}
              </Typography>
          } subscription for {total} Connector{total>1&&"s"}{status===API.SUB_STATUS_ACTIVE||status===API.SUB_STATUS_TRIALING?` and can add ${addCount} more.`:(status===API.SUB_STATUS_PAST_DUE||status===API.SUB_STATUS_UNPAID?" and cannot add any more.":".")}
        </Typography>
        <span className="od-fm-control">
          <FormControl>
            <InputLabel>
              <Typography variant="body1" component="span">Select Connector</Typography>
            </InputLabel>
            <Select defaultValue=""  onChange={this.onChange} className="od-fm-select"
              label="Select Connector">
              <MenuItem value=""></MenuItem>
              <MenuItem value={API.ACTIVECAMPAIGN}>{API.ACTIVECAMPAIGN_FORMATTED}</MenuItem>
              <MenuItem value={API.FACEBOOK_ADS}>{API.FACEBOOK_ADS_FORMATTED}</MenuItem>
              <MenuItem value={API.STRIPE}>{API.STRIPE_FORMATTED}</MenuItem>
              <MenuItem value={API.FACEBOOK_PAGES}>{API.FACEBOOK_PAGES_FORMATTED}</MenuItem>
              <ListSubheader> -- Coming Soon --</ListSubheader>
              <MenuItem value={"xero"} disabled>Xero</MenuItem>
              <MenuItem value={"salesforce"} disabled>Salesforce</MenuItem>
              <MenuItem value={"shopify"} disabled>Shopify</MenuItem>
              <MenuItem value={"vend"} disabled>Vend</MenuItem>
            </Select>
          </FormControl>
        </span>
        <div className="add-btn-div">
          <Button onClick={this.onSubmit} variant="contained" color="secondary" size="large" fullWidth={true} disabled={valid===false}>
            <span className="pch-btn-txt">Add Connector</span>
          </Button>
        </div>
        {error === true && <Typography variant="body1" component="span" color="error">{message}</Typography>}
        <br/>
        <div className="od-fm-nt">
          <Typography variant="caption" component="span" className="txt-note">
            Note: If you are interested in any other Connectors or have custom requirements, please talk with us&nbsp;
            <MuiLink href={ROUTES.SUPPORT} target="_blank" rel="noopener" variant="body2" color="inherit" underline="always">
              here
            </MuiLink>.
          </Typography>
        </div>
      </div>
    )
  }
};

export default withRouter(AddProductForm);
