import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const INITIAL_STATE = {
  authUser: null,
}

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
      this.authListener = this.props.firebase.auth.onAuthStateChanged(authUser => {
        if ( !!authUser ) {
          authUser.customerId = "";
          this.setState({
            authUser,
          })
        } else {
          this.setState({ ...INITIAL_STATE });
        }
      });
    }

    componentWillUnmount() {
      this.authListener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }
  return withFirebase(WithAuthentication);
};

export default withAuthentication;
