import React, { Component, Fragment } from "react";
import { CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import axios from 'axios';
import * as API from '../../constants/api';
import * as STRIPE from '../../constants/stripe';
import { processError, FormSuccess, handleStripeElementChange } from '../Util';
import { Loading } from '../Loading';
import { Paper, Button, Typography } from '@mui/material';
import '../../styles/main.css';


const INITIAL_STATE = {
  email: '',
  title: '',
  message: '',
  clientSecret: null,
  showForm: true,
  success: false,
  loading: false,
  numValid: false,
  expValid: false,
  cvcValid: false,
  enableBtn: true,
};

class PaymentUpdateForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    if ( this.props.authUserEmail && this.props.authUserEmail !== '' ) {
      this.setState({
        email: this.props.authUserEmail,
        title: "Please enter card details below. This will be the default method for future payments. Existing card will be removed.",
      });
    }
  }

  createSetupIntent = async (paymentMethodId) => {
    await axios
    .post(`${API.FMI_API}/customer/createsetupintent/`, {
      email: this.state.email,
      payment_method_id: paymentMethodId,
    }, API.HEADERS)
    .then( response => {
      if ( response.data.message === API.MESSAGE_SUCCESS && response.data.setup_intent_status === API.SETUPINTENT_STATUS_SUCCEEDED ) {
        this.setState({
          showForm: false,
          success: true,
          loading: false,
          enableBtn: false,
          title: "Your new card has been set up as the default method for future payments successfully.",
        });
      } else if ( response.data.message === API.MESSAGE_SUCCESS && response.data.setup_intent_status === API.SETUPINTENT_STATUS_REQUIRES_ACTION ) {
        this.confirmSetupIntent(paymentMethodId, response.data.client_secret);
      } else {
        this.setState({
          showForm: true,
          success: false,
          loading: false,
          enableBtn: true,
          message: "Unexpected network error. Please refresh the page and try again.",
        });
      }
    }).catch( error => {
      const { message } = processError(error);
      this.setState({ showForm:true, success:false, loading:false, enableBtn: true, message });
    });
  }

  confirmSetupIntent = (paymentMethodId, clientSecret) => {
    const { stripe } = this.props;
    if ( !stripe ) {
      this.setState({
        showForm:true,
        success:false,
        loading:false,
        enableBtn:true,
        message:"Failed to load Stripe. Please try to refresh the page.",
      });
      return
    }
    stripe.confirmCardSetup(clientSecret)
    .then( res => {
      if ( res.setupIntent ) {
        axios
        .post(`${API.FMI_API}/customer/setdefaultpaymentmethod/`, {
          email: this.state.email,
          payment_method_id: paymentMethodId,
        }, API.HEADERS)
        .then( response => {
          if (response.data && response.data.message && response.data.message === API.MESSAGE_SUCCESS) {
            this.setState({
              showForm: false,
              success: true,
              loading: false,
              enableBtn: false,
              title: "Your new card has been set up as the default method for future payments successfully.",
            });
          } else {
            this.setState({
              showForm: false,
              success: true,
              loading: false,
              enableBtn: false,
              title: "Your new card has been set up as the default method for future payments successfully.",
            });
          }
        }).catch( error => {
          const { message } = processError(error);
          this.setState({
            showForm: true,
            success: false,
            loading: false,
            enableBtn: true,
            message,
          });
        });
      } else if ( res.error ) {
        this.setState({
          showForm: true,
          success: false,
          loading: false,
          enableBtn: true,
          message: "Please take required actions to authenticate.",
        });
      } else {
        this.setState({
          showForm: true,
          success: false,
          loading: false,
          enableBtn: true,
          message: "Unexpected error. Please refresh the page and try again.",
        });
      }
    }).catch( error => {
      this.setState({
        showForm: true,
        success: false,
        loading: false,
        enableBtn: true,
        message: "Failed to confirm the card setup. Please try again.",
      });
    });
  }

  handleChange = (event) => {
    handleStripeElementChange(this.setState.bind(this), event.complete, event.elementType, event.error, event.empty);
  }

  handleSubmit = async (event) => {
    this.setState({ loading:true, enableBtn: false, message:"" });
    event.preventDefault();
    const { stripe, elements } = this.props;
    if ( !stripe || !elements ) {
      this.setState({
        showForm:true,
        success:false,
        loading:false,
        enableBtn:true,
        message:"Failed to load Stripe. Please try to refresh the page.",
      });
      return
    }
    const cardElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email: this.state.email,
      },
    });
    if (paymentMethod) {
      this.createSetupIntent(paymentMethod.id);
    } else if (error && error.message) {
      this.setState({
        showForm:true,
        success:false,
        loading:false,
        enableBtn: true,
        message:error.message,
      });
    } else {
      this.setState({
        showForm:true,
        success:false,
        loading:false,
        enableBtn: true,
        message:"Unexpected error. Please refresh the page and try again.",
      });
    }
  }

  renderForm() {
    const { title, numValid, expValid, cvcValid, enableBtn } = this.state;
    const valid = numValid === true && expValid === true && cvcValid === true && enableBtn === true;
    const style = valid === false ? {backgroundColor: '#e0e0e0'} : {backgroundColor: '#e4262c'};
    return (
      <div className="ck-fm-div">
        <Typography variant="subtitle1">
          {title}
        </Typography>
        <Paper variant="outlined" elevation={0} className="ck-fm-paper">
          <div className="ck-fm-label">
            <Typography variant="body1" component="span">Card Number</Typography>
          </div>
          <div className="ck-fm-card">
            <CardNumberElement className="ck-fm-card-element" onChange={this.handleChange} options={STRIPE.ELEMENT_OPTIONS}/>
          </div>
          <div className="ck-fm-label">
            <Typography variant="body1" component="span">Expiration Date</Typography>
          </div>
          <div className="ck-fm-card">
            <CardExpiryElement className="ck-fm-card-element" onChange={this.handleChange} options={STRIPE.ELEMENT_OPTIONS}/>
          </div>
          <div className="ck-fm-label">
            <Typography variant="body1" component="span">CVC</Typography>
          </div>
          <div className="ck-fm-card">
            <CardCvcElement className="ck-fm-card-element" onChange={this.handleChange} options={STRIPE.ELEMENT_OPTIONS}/>
          </div>
        </Paper>
        <Typography variant="caption" component="div">Payments handled by Stripe. We will not save your card details. Ever</Typography>
        <div className="od-btn-div">
          <Button onClick={this.handleSubmit} variant="contained" style={style} size="large" fullWidth={true} disabled={valid === false}>
            <span className="pch-btn-txt">Confirm</span>
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { showForm, success, message, loading, title } = this.state;
    return (
      <Fragment>
        <Loading loading={loading}/>
        {showForm === true && this.renderForm()}
        {success === true && <FormSuccess title={title}/>}
        <Typography variant="subtitle1" component="div" align="center" color="error">{message}</Typography>
      </Fragment>
    );
  }
}

export default PaymentUpdateForm;
