import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import axios from 'axios';
import * as API from '../../constants/api';
import { processError, DialogError, DialogSuccess } from '../Util';
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, IconButton, FormControlLabel, FormControl, FormLabel, RadioGroup, Radio, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../../styles/main.css';


const INITIAL_STATE = {
  email: '',
  id: '',
  cancelTrial: false,
  periodEnd: '',
  confirmed: false,
  reasoned: false,
  reason: '',
  reasonTextInput: '',
  error: false,
  message: '',
  loading: false,
};

const CHANGE_TEXT_MAX_LENGTH = 1024;

class CancelSubDialog extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  static propTypes = {
    open: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    cancelTrial: PropTypes.bool.isRequired,
  };

  componentDidUpdate(prevProps) {
    if ( this.props.open !== prevProps.open && this.props.open === true ) {
      if ( this.props.id !== "" && this.props.email !== "" ) {
        this.setState({
          email: this.props.email,
          id: this.props.id,
          cancelTrial: this.props.cancelTrial,
          periodEnd: this.props.periodEnd,
          confirmed: false,
          reasoned: false,
          reason: '',
          reasonTextInput: '',
          error: false,
          loading: false,
        });
      } else {
        this.props.onClose(false);
      }
    }
  }

  handleClose = () => {
    this.setState({
      confirmed: false,
      reasoned: false,
      reason: '',
      reasonTextInput: '',
    });
    this.props.onClose(true);
  };

  handleCancel = () => {
    this.setState({
      confirmed: false,
      reasoned: false,
      reason: '',
      reasonTextInput: '',
    });
    this.props.onClose(false);
  };

  handleConfirm = () => {
    this.setState({ confirmed:true });
  };

  handleCancelSub = async () => {
    this.setState({ loading:true });
    const { email, id, reason, reasonTextInput } = this.state;
    if ( email !== "" && id !== "" ) {
      this.setState({ loading:true });
      await axios
      .post(`${API.FMI_API}/sub/cancel/`, {
        email,
        sub_id: id,
        cancel_reason: reason === "Other" ? reasonTextInput : reason,
      }, API.HEADERS)
      .then( response => {
        this.setState({ reasoned:true, error:false, loading:false });
      })
      .catch( error => {
        const { message } = processError(error);
        this.setState({ reasoned:true, error:true, message, loading:false });
      });
    }
  };

  handleChange = (event) => {
    this.setState({ reason:event.target.value });
  };

  handleChangeTextInput = (event) => {
    this.setState({ reasonTextInput:event.target.value.substring(0, CHANGE_TEXT_MAX_LENGTH) });
  };

  renderQuestion() {
    const { loading, cancelTrial, periodEnd } = this.state;
    return (
      <Fragment>
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>
              Cancel {cancelTrial===true?`${API.FREE_TRIAL_DAYS} Day Trial`:"subscription at the end of your current period?"}
            </Grid>
            <Grid item>
              <IconButton onClick={this.handleCancel} size="small">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          { cancelTrial === true ?
            <Fragment>
              Would you like to cancel your {API.FREE_TRIAL_DAYS} day trial?
              <br/>
              <br/>
              All your Connectors will be unavailable immediately. All of your existing data sources will no longer work on your Google Data Studio reports. This action <span className="txt-error txt-bold">CANNOT</span> be undone.
            </Fragment>
            :
            <Fragment>
              Would you like to cancel your Premium subscription at the end of current period?
              <br/>
              <br/>
              All of your Find My Insight Connectors will be available until the end of your current subscription <span className="txt-error txt-bold">{periodEnd}</span>. At the end of your subscription period all of your existing Find My Insight data sources will no longer work. If you change your mind before the end of your subscription period on <span className="txt-error txt-bold">{periodEnd}</span> please contact our Helpdesk to assist you.
            </Fragment>
          }
        </DialogContent>
        <DialogActions>
          { loading === true ?
            <CircularProgress />
            :
            <Grid container justifyContent="space-between">
              <Grid item>
                <span className="dialog-btn">
                  <Button onClick={this.handleConfirm} color="primary">
                    Yes, I'd like to cancel{cancelTrial===true?"":" at the end of current period"}
                  </Button>
                </span>
              </Grid>
              <Grid item>
                <span className="dialog-btn">
                  <Button autoFocus onClick={this.handleCancel} color="primary" variant="contained">
                    <span className={"txt-white txt-bold"}>No</span>
                  </Button>
                </span>
              </Grid>
            </Grid>
          }
        </DialogActions>
      </Fragment>
    )
  }

  renderReason() {
    const { reason, reasonTextInput, loading } = this.state;
    return (
      <Fragment>
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>
              Why are you leaving us?
            </Grid>
            <Grid item>
              <IconButton onClick={this.handleCancel} size="small">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormLabel component="legend"></FormLabel>
            <RadioGroup aria-label="cancel-reason" name="reason" value={reason} onChange={this.handleChange}>
              { API.CANCEL_REASONS.map(item => (
                <FormControlLabel control={<Radio />} value={item} label={item} key={item} />
              )) }
              <FormControlLabel control={<Radio />}
                value="Other"
                label="Other (please specify)" />
            </RadioGroup>
            { reason==="Other" &&
              <TextField
                label="Please enter here"
                variant="outlined"
                multiline
                value={reasonTextInput}
                onChange={this.handleChangeTextInput}
              />
            }
          </FormControl>
        </DialogContent>
        <DialogActions>
          { loading === true ?
            <CircularProgress />
            :
            <Grid container justifyContent="flex-end">
              <Grid item>
                <span className="dialog-btn">
                  <Button onClick={this.handleCancelSub} color="primary" variant="outlined" disabled={reason===""}>
                    OK
                  </Button>
                </span>
              </Grid>
            </Grid>
          }
        </DialogActions>
      </Fragment>
    )
  }

  render() {
    const { confirmed, reasoned, error, cancelTrial } = this.state;
    return (
      <Dialog
        maxWidth="md"
        fullWidth={true}
        aria-labelledby="confirm-cancel-sub"
        open={this.props.open}
        className="sb-dialog"
      >
        { error === false && confirmed === false && this.renderQuestion() }
        { error === false && confirmed === true && reasoned === false && this.renderReason() }
        { error === false && confirmed === true && reasoned === true &&
          <DialogSuccess
            onClick={this.handleClose}
            title={cancelTrial===true?`Your ${API.FREE_TRIAL_DAYS} day trial has been cancelled.`:"Your subscription will be cancelled at the end of current period."}
          />
        }
        { error === true && <DialogError onClick={this.handleClose}/> }
      </Dialog>
    );
  }
}

export default CancelSubDialog;
